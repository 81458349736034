<section class="card-section termsofuse green-theme">
  <div class="comman-width">
    <span class="terms">Program Description</span>
    <div class="repeat">
      <p>EFFECTIVE DATE: November 30, 2023
      </p>
      <p>This Program Description is a part of the GlicRx <a class="u" [routerLink]="['/terms-and-conditions']"
          routerLinkActive="active">Terms and Conditions of Use</a> and must be read together with
        them. Capitalized terms used and not otherwise defined in this Program Description have the respective meanings
        set forth in such Terms of Use.
      </p>
      <p>
        <strong>By using the GlicRx Program (the GlicRx Program), including our discount card (the GlicRx Card) or our
          mobile app (the GlicRx App), you are accepting our <a class="u" [routerLink]="['/terms-and-conditions']"
            routerLinkActive="active">Terms and Conditions of Use</a>, and our <a class="u"
            [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>, and agree
          to be bound by each. If you have any questions about our GlicRx Program, would like a list of participating
          pharmacies, or a list of the prescription drugs covered by our GlicRx Program please contact us at:
        </strong>
      </p>
      <p>
        Glic Health LLC <br>
        One Marina Park Drive <br>
        Suite 1410 <br>
        Boston, MA 02210 <br>
        E-mail: <a class="u" href="mailto:info@glichealth.com">info@Glic Health.com</a>
      </p>
      <p style="text-decoration: underline;"><strong>
          <i>Description of the GlicRx Program:</i>
        </strong></p>
      <p><strong>A really important notice about what we are NOT (and what we are)… </strong></p>
      <p><strong>Let’s start with what the GlicRx Program is <span class="u">not</span>.</strong>
      </p>

      <ul>
        <li> <strong>It is NOT insurance of any kind and it is NOT a substitute for insurance.</strong></li>
        <li> <strong> The GlicRx Card is <span class="u">not</span> an insurance card, a health insurance policy or a
            substitute for
            health insurance.</strong></li>
        <li> <strong> The GlicRx App is <span class="u">not</span> an insurance app or card, a health
            insurance policy or a substitute for health insurance.</strong>
        </li>
        <li><strong>
            We do <span class="u">not</span> provide medical, dental or other professional services or advice of any
            nature whatsoever. If you
            have or suspect that you have a medical problem or condition, please contact a qualified ‎health care
            professional immediately.‎ Always seek the advice of your physician or ‎other qualified health professionals
            or providers with any questions you may have regarding a ‎medical condition or medicine. Never disregard
            professional medical advice or delay in seeking it ‎because of the GlicRx Program.
          </strong></li>
      </ul>
    </div>


    <div class="repeat">
      <h3>So, what is the GlicRx Program? How does it work? What do I need to know about pricing?</h3>
      <ul>
        <li>- As we state right at the top of this description, the GlicRx Card and the GlicRx App provide you the
          potential for
          <span class="u">discounts</span> on prescription drugs and related products from participating pharmacies,
          much
          like a coupon you
          would clip out of a newspaper or print out from online. <b>That's it. Nothing more</b>. As we said earlier in
          this
          description, we don’t provide medical, dental, or other professional advice or services. We aren’t health
          insurance and don’t offer health insurance. We don’t pay your medical expenses and we don’t pay for your
          prescriptions.
        </li>
        <li>- We cannot guaranty that any such discounts will provide the lowest price available to you and there is a
          wide range discounts that vary depending on the prescription drug or other product and the participating
          pharmacy.
        </li>
        <li>- <span class="u">You</span> are required to pay for all prescription drugs at the time of purchase.
        </li>
        <li>- We do not pay the pharmacy or your doctor or make any other payments to your health care providers.
        </li>
        <li>- Discounts through the GlicRx Program are only available at participating pharmacies.
        </li>
        <li>- In order to be eligible for a discount at one of such pharmacies, you must present the GlicRx App or the
          GlicRx
          Card
          in connection with each prescription.
        </li>
        <li>- When you use the GlicRx Program, the price you pay will be the lesser of the pharmacy’s “usual and
          customary”
          price (e.g., the retail price) or our discounted price. What that means is that if the pharmacy’s pricing is
          better than our discounted price, there is no discount via the GlicRx Program.
        </li>
        <li>- You should be aware that there may be other programs that offer you better pricing than the GlicRx Program
          (such
          as your health insurance carrier (if you have one), other programs similar to GlicRx, etc.). We do <span
            class="u">not</span> guaranty
          that our prices are the lowest available at any pharmacy.
        </li>
        <li>- The GlicRx Program could terminate or change at any time without notice to you. As there is no cost for
          you to
          use the GlicRx Program, you will not be entitled to any refund or other consideration if it does.
        </li>
        <li>- Be advised that all of our prices (and related discounts) are subject to change (and often do!) without
          notice.
        </li>
        <li>- You are responsible for any computer or mobile device through which you access the GlicRx Program and as
          well
          as
          any internet, cable or mobile phone service that provides you connectivity or related services, as well as all
          costs and expenses associated with them.
        </li>
        <li>- Through our app, we use commercially reasonable efforts to ensure we provide you with up to date data, but
          we can’t guaranty the accuracy of any data provided in the GlicRx App or on our website at any given time. You
          must call your pharmacy to confirm the most up to date information before purchasing any prescription to
          ensure you have the most up to date information.
        </li>
        <li>- We cannot guarantee that discounts on our website and the GlicRx App will be below a pharmacy’s usual and
          customary pricing for drugs or that they will be more favorable than other discounting programs available to
          you.
        </li>
        <li>- If you do NOT have health insurance, you can use the GlicRx Program for any brand name or generic
          prescriptions
          you or a household member pays for out-of-pocket unless other restrictions apply.
        </li>
        <li>- The GlicRx Program cannot be used with other prescription drug discount cards or for prescriptions paid
          through
          a health plan or pharmacy benefit plan.
        </li>
        <li>- If you HAVE health insurance or any other funded benefit such as Medicaid and Medicare, you can use the
          GlicRx
          Program for certain prescriptions that are not covered by your plan.
        </li>
        <li>- If you have questions about the use of the GlicRx Program with your health insurance, you should contact
          your
          health insurer.
        </li>
      </ul>
    </div>


    <div class="repeat">
      <h3>The GlicRx Card and the GlicRx App</h3>
      <p>
        In some cases, you may receive, print or download our GlicRx Card without registering with us or creating an
        account. You may also have received an GlicRx Card from a third-party marketing organization, with or without
        having
        provided that third-party with your name or other identifying information. If you request to have a card
        emailed, texted, or mailed to your personal device or home address, you will be required to provide us with your
        name, email address, home address, and/or telephone number. The information that you provide will be used as set
        forth in our <a class="u" [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>.
      </p>
      <p>Using the GlicRx Program via the GlicRx App requires the download of the GlicRx App from the Apple iTunes App
        Store or the
        Google Play Store. Download of the GlicRx App is subject to our <a class="u"
          [routerLink]="['/terms-and-conditions']" routerLinkActive="active">Terms and Conditions</a>.</p>
    </div>

    <div class="repeat">
      <h3>An important notice about your health information when you use the GlicRx Program:</h3>
      <p>When you use the GlicRx Program, including the GlicRx Card and/or the GlicRx App, information about your
        prescriptions that
        you purchase will be collected from our participating pharmacies. This information may include your name or the
        name of the person who was prescribed the drug, date of birth, address and zip code, prescribing doctor and
        other prescription information, among other similar information.</p>
      <p>Our uses, handling, and sharing of your information, including any health information, are detailed in our
        <a class="u" [routerLink]="['/privacy-policy']" routerLinkActive="active">Privacy Policy</a>.
      </p>
      <p>As noted elsewhere in our <a class="u" [routerLink]="['/terms-and-conditions']" routerLinkActive="active">Terms
          and Conditions</a>, we may add to, update, delete from or modify the GlicRx Program at
        any time in our sole discretion. We reserve the right, at any time and from time to time, for any reason, to
        change the GlicRx Program as described herein. We may post or display notices of changes to the GlicRx Program
        on the
        Web site or otherwise through our Services or may notify you of such changes. Once posted, these changes to the
        GlicRx Program become effective immediately. If any change that we make is not acceptable to you, promptly stop
        using the Services. Any use by you of the Services after any changes to the GlicRx Program become effective will
        signify your agreement to those changes. You should check back regularly and review this Program Description so
        that you are aware of the most current rights and obligations that apply to your agreement with us.
      </p>
      <p><strong>
          Your participation in the GlicRx Program constitutes your acceptance of our <a class="u"
            [routerLink]="['/terms-and-conditions']" routerLinkActive="active">Terms and Conditions</a>, including or
          disclaimer of warranties and limitation of liability with respect to your participation in the GlicRx Program.
        </strong></p>
      <p><strong>
          <i>Copyright © 2022 Glic Health LLC. All rights reserved. </i>
        </strong></p>

    </div>

  </div>
</section>
<!-- // banner section \\ -->
<section class="banner-section about-banner-section aboutSecOne green-theme aboutbox_header">
  <div class="comman-width">
    <div class="over">
      <h2>Since 2010</h2>
      <p>
        Our mission is to help make each community a better place to live.
      </p>
    </div>
  </div>
  <app-getcard id="daffiliate"></app-getcard>
</section>
<!-- \\ banner section // -->
<!-- <app-getcard></app-getcard> -->
<!-- // honest section \\ -->
<section class="honest-section green-theme">
  <div class="image">
    <img class="img-responsive aboutImgResponsive" src="../../../assets/images/right-column-graphic.webp"
      alt="right-column" />
  </div>
  <div class="comman-width">
    <div class="content">
      <span class="small-heading">The GlicRx Discount Card</span>
      <h2>Our solution to the rising cost of healthcare.</h2>
      <p>The cost of prescription drugs is a major concern for all Americans. No one should be forced to choose between the health of themselves or their loved ones and paying for other costs of living. At Glic Health LLC, we understand the dire need for solutions to help make healthcare more affordable. Our solution is the free GlicRx prescription discount card.</p>
      <p>The GlicRx prescription discount card helps provide relief from the high costs of prescription medication to everyone, whether they are insured, are on Medicare or do not have insurance. By using our card, patients can save over a prescription's retail price at over 35,000 pharmacies nationwide. For most, the GlicRx card can provide a crucial alternative where co-pays or deductibles are too high.* By providing an alternative retail and insurance pricing on the high cost of prescription drugs, we believe GlicRx can make a difference for all Americans.</p>
    </div>
    <div class="deduct">
      <p>* Using GlicRx does not count towards deductibles or out of pocket maximums under health insurance or Medicare. Click <a class="cool-link" href="/how-it-works/#faq" fragment="faq" (click)="slideToggle()" routerLinkActive="active">here</a> for more information.</p>
    </div>
  </div>
</section>
<!-- \\ honest section // -->

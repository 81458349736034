<!-- // easy section \\ -->
<section class="easy-section">
  <div class="easy-content">
    <h2>How does GlicRx work?</h2>
    <span class="easy-step">3 Easy Steps!</span>
    <div class="row">
      <div class="col-lg-4 col-sm-4 col-xs-12">
        <a class="step1-click" (click)="pharmacynavigate()" routerLinkActive="active">
        <img src="../assets/images/how-it-works-icon1.png" class="onweb" alt="" />
        <span>Step 1</span>
        <p>Search for prescription and find the lowest price at a pharmacy near you.</p>
        </a>
      </div>
      <div class="col-lg-4 col-sm-4 col-xs-12">
        <img src="../assets/images/how-it-works-icon2.png" class="onweb" alt="" />
        <span>Step 2</span>
        <p>Get your free discount card by downloading the mobile app or by entering your information here.</p>
      </div>
      <div class="col-lg-4 col-sm-4 col-xs-12">
        <img src="../assets/images/how-it-works-icon3.png" class="onweb" alt="" />
        <span>Step 3</span>
        <p>Present your card to the pharmacist and save upto $95 off your medication.</p>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="get-card-buttons">
      <a href="#" class="card">Get Card Now!</a>
      <a href="/how-it-works/#faq" class="card gap">Learn More</a>
    </div>
  </div>
</section>
<!-- \\ easy section // -->
<!-- // welcome section \\ -->
<section class="accept-section homePageSecTwo">
  <div class="acceptedsec">
    <div class="howitwork">
      <h2>Where does it work?</h2>
      <p class="bottom-gap">Over 35,000 pharmacies partnered directly with GlicRx to offer you discounts on prescription drugs</p>
      <p>Save up to $95 off on your prescription</p>
    </div>
    <h3 class="onmobile">
      Accepted at over
    </h3>
    <h2 class="onmobile">35,000</h2>
    <p class="onmobile">pharmacies nationwide</p>
    <img loading="lazy" src="../assets/images/accepted_mobile.webp" class="onmobile" alt="" />
    <img loading="lazy" src="../assets/images/accepted.webp" class="onweb" alt="" />
  </div>
</section>
<!-- \\ welcome section // -->

<!-- // question section \\ -->
<section id="faq" class="question-section">
  <div class="question-content">
    <h2>Got Questions?</h2>
    <div class="repeat">
      <div class="row">
        <div class="col-lg-6 col-sm-6 col-xs-12" *ngFor="let faq of faqData; let i = index">
          <h3 [innerHtml]="faq.Question"></h3>
          <p [ngClass]="{'remove-gap': faq.SubAnswer}" [innerHtml]="faq.Answer"></p>
          <ul *ngIf="faq.SubAnswer">
            <li *ngFor="let sub of faq.SubAnswer"><span [innerHtml]="sub.A"></span></li>
          </ul>
          <p *ngIf="faq.SubAnswer" innerHtml]="faq.Answer2"></p>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <div class="more">
      <span>Have More Questions?</span>
      <a routerLink="/contact" routerLinkActive="active" href="javascript:void('0')" class="card">Click here</a>
    </div>
  </div>
</section>
<!-- \\ question section // -->

<!-- // carosuel section \\ -->
<section class="carosuel-section homePageSecFour testimonial custom">
  <div class="comman-width">
    <div class="testimonial_inner">
      <h3>Testimonials</h3>
      <h2>What customers are saying.</h2>
      <div class="row">
        <div class="col-lg-12">
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
            <div ngxSlickItem *ngFor="let slide of slides" class="slide">
              <div class="shadow">

                <!-- <img src="{{ slide.img }}" alt="pharmacist" /> -->
                <h3>{{ slide.heading }}</h3>
                <p>{{ slide.text }}</p>
              </div>
            </div>
          </ngx-slick-carousel>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- \\ carosuel section // -->
<div class="clearfix"></div>
import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { map, tap } from "rxjs/operators";
import { SaveService } from "../services/save.service";
import { ApiService } from "../services/api.service";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: "app-download",
  templateUrl: "./download.component.html",
  styleUrls: ["./download.component.css"],
  providers: [ApiService]
})
export class DownloadComponent implements OnInit {
  brandName;
  secondBrandName;
  sendno: any = "";
  sendno1: any = "";
  sendmessageClass: any = "";
  sendmessageStatus: any = "";
  sendmessageClass1: any = "";
  sendmessageStatus1: any = "";

  slides = [
    {
      icon: "../assets/images/behalf-icon1.png",
      heading: "Search for the Best price"
    },
    { icon: "../assets/images/behalf-icon2.png", heading: "Get your card" },
    {
      icon: "../assets/images/behalf-icon3.png",
      heading: "Show pharmacist at time of purchase."
    }
  ];

  slides2 = [
    {
      heading: "KMPCJ - iOS app user",
      text:
        "I have been using the GlicRx card for years as we have a very high deductible that continues to get higher. I’ve used the cards for all types of prescriptions at several different pharmacies (depending on convenience) and it works as well as my insurance if not better on occasion I’m thrilled that there is now an app so I can use this on the go. Thank you GlicRx!!",
      rating: "5/5",
      enable: "5"
    },
    {
      heading: "Gerry - iOS app user",
      text:
        "Before I just used the card which I had to keep in my wallet from Ball and Buck. This app experience is way more seamless. Huge fan of the savings I’ve been getting for years now.",
      rating: "5/5",
      enable: "5"
    },
    {
      heading: "Enrico - Android app user",
      text: "Awesome app! easy to use and has saved me a ton of money",
      rating: "5/5",
      enable: "5"
    },
    {
      heading: "JDParkes - iOS app user",
      text:
        "Works much better than old app. Better prices than other discount cards",
      rating: "5/5",
      enable: "5"
    }
  ];

  slides3 = [
    {
      img: "../assets/images/thumbnail_icon1.png",
      heading: "Bill, South Berwick, ME",
      text:
        "Using my GlicRx discount card saved me more than half of my prescription costs this month. Over $65 dollars!"
    },
    {
      img: "../assets/images/thumbnail_icon2.png",
      heading: "Malinda, Brooklyn, NY",
      text:
        "I used the GlicRx mobile app and found a cheaper pharmacy in my town.  I saved $108 on my last prescription."
    },
    {
      img: "../assets/images/thumbnail_icon3.png",
      heading: "Enrico, Boston, MA",
      text: "Awesome app! easy to use and has saved me a ton of money"
    },
    {
      img: "../assets/images/thumbnail_icon1.png",
      heading: "JDParkes, Houston, TX",
      text:
        "Works much better than old app. Better prices than other discount cards"
    }
  ];
  slideConfig3 = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    dots: false,
    infinite: true,
    arrows: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          nextArrow: "<div class='nav-btn next-slide'></div>",
          prevArrow: "<div class='nav-btn prev-slide'></div>",
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false
        }
      },
      {
        breakpoint: 481,
        settings: {
          centerMode: false,
          nextArrow: "<div class='nav-btn next-slide'></div>",
          prevArrow: "<div class='nav-btn prev-slide'></div>",
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false
        }
      }
    ]
  };

  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    dots: true,
    infinite: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  slideConfig2 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    dots: true,
    infinite: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  constructor(private saveService: SaveService, private savecard: ApiService, public router: Router) { }
  ngOnInit() {
    this.brandName = this.saveService.getBrandName();
    this.secondBrandName = this.saveService.getSecondBrandName();
    console.log('');
    $(window).scroll(function () {
      if (window.location.href.indexOf("/download-mobile-app") > -1) {
        if ($(window).scrollTop() >= $(".how-works").offset().top - 100) {
          $(".getCard.green-theme ").addClass("fixed_pos_card");
        } else {
          $(".getCard.green-theme ").removeClass("fixed_pos_card");
        }
      }
    });
  }
  pharmacynavigate() {
    if (location.pathname != '/enrollmentcenter' && location.pathname != '/guidebook-and-resources') {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      this.router.navigate(["/Pharmacy"], navigationExtras);
    }
    else {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      const url = this.router.serializeUrl(this.router.createUrlTree(['/Pharmacy'], { queryParams: { 'Enrollmentcenter':"ec" } }));
      window.open(url, '_blank');
      // this.router.navigate([], navigationExtras).then(result => { window.open(`/Pharmacy`, '_blank'); });

    }
  }
  sendmsg(val, downloadbottom, event) {
    console.log(val);
    if (val != "" && val.length >= 7) {
      this.savecard
        .sendSms(val )
        .pipe(
          tap(
            data => {
              // ;
            },
            error => {
              ;
              let errorData = (this.sendmessageStatus = JSON.parse(
                error._body
              ));
              if (downloadbottom != "downloadbottom") {
                if (errorData.code == "21408") {
                  this.sendmessageStatus = "Please enter a valid country code!";
                  this.sendmessageClass = "msgerror";
                } else if (errorData.code == "21211") {
                  this.sendmessageStatus =
                    "Please enter a valid phone number";
                  this.sendmessageClass = "msgerror";
                } else {
                  this.sendmessageStatus = "Try a different number";
                  this.sendmessageClass = "msgerror";
                }
              } else {
                ;
                if (errorData.code == "21408") {
                  this.sendmessageStatus1 =
                    "Please enter a valid country code!";
                  this.sendmessageClass1 = "msgerror";
                } else if (errorData.code == "21211") {
                  this.sendmessageStatus1 =
                    "Please enter a valid phone number";
                  this.sendmessageClass1 = "msgerror";
                } else {
                  this.sendmessageStatus1 = "Try a different number";
                  this.sendmessageClass1 = "msgerror";
                }
              }
            }
          )
        )
        .subscribe(data => {
          ;
          if (downloadbottom != "downloadbottom") {
            this.sendmessageClass = "Success_sent";
            this.sendmessageStatus = "Message Sent";
          } else {
            this.sendmessageStatus1 = "Message Sent";
            this.sendmessageClass1 = "Success_sent d_bottom";
          }
          setTimeout(() => {
            this.sendmessageClass = "";
            this.sendmessageStatus = "";
            this.sendmessageClass1 = "";
            this.sendmessageStatus1 = "";
            this.sendno = "";
            this.sendno1 = "";
          }, 1000);
        });
    } else {
      if (downloadbottom != "downloadbottom") {
        this.sendmessageStatus = "Please enter a valid phone number";
        this.sendmessageClass = "msgerror";
      } else {
        this.sendmessageStatus1 = "Please enter a valid phone number";
        this.sendmessageClass1 = "msgerror";
      }
    }
  }
  removemsgerr(e) {
    this.sendmessageStatus = "";
    this.sendmessageClass = "";
    this.sendmessageStatus1 = "";
    this.sendmessageClass1 = "";
    // e = e || window.event;
    // var charCode = typeof e.which == "number" ? e.which : e.keyCode;
    // if (!charCode || charCode == 8 /* Backspace */) {
    //   return;
    // }

    // var typedChar = String.fromCharCode(charCode);

    // // Allow numeric characters
    // if (/\d/.test(typedChar)) {
    //   return;
    // }

    // // Allow the minus sign (+) if the user enters it first
    // if (typedChar == "+" && e.target.value.charAt() == "") {
    //   return;
    // }
    // return false;
    var val_old = e.target.value;
    var val = val_old.replace(/\D/g, "");
    var len = val.length;
    if (len >= 1) val = "(" + val.substring(0);
    if (len >= 3) val = val.substring(0, 4) + ")" + "-" + val.substring(4);
    if (len >= 6) val = val.substring(0, 9) + "-" + val.substring(9);
    if (val != val_old)
      $(event.target)
        .focus()
        .val("")
        .val(val);
  }
}

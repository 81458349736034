<h2>About GlicRx</h2>
<p>FAQs</p>

<div class="panel-group" id="accordion">
    <div class="panel panel-default" *ngFor="let faq of faqData; let i = index">
        <div class="panel-heading">
            <h3 class="panel-title">
                <span data-toggle="collapse" data-parent="#accordion" aria-expanded='false' id={{i}}
                    (click)="toggleCollapse(this.i)" class="removeAttr"><span>&nbsp;</span>{{faq.Question}}</span>
            </h3>
        </div>
        <div id="collapse{{i}}" class="panel-collapse collapse in">
            <div class="panel-body" [innerHTML]="faq.Answer"></div>
            <ul>
                <li *ngFor="let subAns of faq.SubAnswer" [innerHTML]="subAns.A"></li>
            </ul>
        </div>
    </div>
</div>

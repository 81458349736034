import { Component, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { SaveService } from "../services/save.service";
import { ApiService } from "../services/api.service";
import * as $ from "jquery";
import { MedicinService } from '../services/index';
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { Router } from "@angular/router";

@Component({
  selector: "app-how-works",
  templateUrl: "./how-works.component.html",
  styleUrls: ["./how-works.component.css"]
})
export class HowWorksComponent implements OnInit {
    slides = [
        {
            img: "../assets/images/thumbnail_icon1.png",
            heading: "Bill, South Berwick, ME",
            text:
                "Using my GlicRx discount card saved me more than half of my prescription costs this month. Over $65 dollars!",
        },
        {
            img: "../assets/images/thumbnail_icon2.png",
            heading: "Malinda, Brooklyn, NY",
            text:
                "I used the GlicRx mobile app and found a cheaper pharmacy in my town.  I saved $108 on my last prescription.",
        },
        {
            img: "../assets/images/thumbnail_icon3.png",
            heading: "Enrico, Boston, MA",
            text: "Awesome app! easy to use and has saved me a ton of money",
        },
        {
            img: "../assets/images/thumbnail_icon1.png",
            heading: "JDParkes, Houston, TX",
            text:
                "Works much better than old app. Better prices than other discount cards",
        },
    ];
    slideConfig = {
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: "<div class='nav-btn next-slide'></div>",
        prevArrow: "<div class='nav-btn prev-slide'></div>",
        dots: false,
        infinite: true,
        arrows: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerMode: false,
                    nextArrow: "<div class='nav-btn next-slide'></div>",
                    prevArrow: "<div class='nav-btn prev-slide'></div>",
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 481,
                settings: {
                    centerMode: false,
                    nextArrow: "<div class='nav-btn next-slide'></div>",
                    prevArrow: "<div class='nav-btn prev-slide'></div>",
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };

  public faqData: any = [];
  public collapse: any;    
    
  constructor(public ms: MedicinService,public router: Router) {}

  ngOnInit() {
      this.GetFaq();
  }
  pharmacynavigate() {
    if (location.pathname != '/enrollmentcenter' && location.pathname != '/guidebook-and-resources') {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      this.router.navigate(["/Pharmacy"], navigationExtras);
    }
    else {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      const url = this.router.serializeUrl(this.router.createUrlTree(['/Pharmacy'], { queryParams: { 'Enrollmentcenter':"ec" } }));
      window.open(url, '_blank');
      // this.router.navigate([], navigationExtras).then(result => { window.open(`/Pharmacy`, '_blank'); });

    }
  }
  GetFaq() {
    this.ms.GetFaq()
    // .pipe(map(res => res.json()))
        .subscribe(data => {
            if (data.length > 0) {
              
                let dataList = JSON.parse(data);
                
                for (let i = 0; i < dataList.length; i++) {
                  console.log(dataList[i].Question)
                    // if (dataList[i].Question == '<span>What is GlicRx?</span>' || dataList[i].Question == '<span>Where does GlicRx work?</span>' || dataList[i].Question == '<span>Who can use GlicRx?</span>' || dataList[i].Question == '<span>Can the card be used multiple times?</span>' || dataList[i].Question == '<span>Can I share the card with family and friends?</span>' || dataList[i].Question == '<span>Does the card expire?</span>' || dataList[i].Question == '<span>How much do consumers save with GlicRx?</span>' || dataList[i].Question == '<span>Is GlicRx really free? Are there any fees associated with using this card?</span>' || dataList[i].Question == '<span>If I have insurance and/or Medicare, can I still use the GlicRx card?</span>' || dataList[i].Question == '<span>Can GlicRx be used <u>together</u> with insurance and/or Medicare?</span>' || dataList[i].Question == "<span>Do GlicRx purchases count towards my insurance deductible or Medicare Out-of-Pocket Maximums or the coverage gap (\"donut hole\")?</span>" || dataList[i].Question == '<span>Can a Health Savings Account (HSA) be used to purchase prescriptions?</span>' || dataList[i].Question == '<span>Does the card work on pet prescriptions?</span>' || dataList[i].Question == '<span>Is GlicRx Insurance?</span>' || dataList[i].Question == '<span>How does GlicRx work?</span>' || dataList[i].Question == '<span>Is my privacy protected?</span>' || dataList[i].Question == '<span>Are GlicRx’s listed prices guaranteed?</span>' || dataList[i].Question == '<span>Are my prescriptions discounted with GlicRx?</span>' || dataList[i].Question == '<span>How does GlicRx set prescription medication prices?</span>' || dataList[i].Question == '<span>Are over the counter medications covered?</span>') {
                        this.faqData.push(dataList[i]);
                    // }
                }              
        } else {
        }
      });
  }
}

import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { map } from "rxjs/operators";
import { SaveService } from "../services/save.service";
import { ApiService } from "../services/api.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
  providers: [ApiService]
})
export class FooterComponent implements OnInit {
  brandName;
  logo;
  isSuccess = false;
  newserror: any = "";
  news_sucess: any = false;
  currentYear: any = '';
  googlegenericpassval: any = "";
  applegenericpassval: any = "";
  android: any = true;
  ios: any = true;
  desktop: any = true;
  showemailpopup: any = false;
  UserInputEmailcard: string = "";
  UserInputEmailcarderror: any = "";
  Sendtext: any = "Send";

  newsletter() {
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var newsLetterMail = $("input[name='email_']").val();
    // if (newsLetterMail != "" && newsLetterMail.match(mailformat)) {
    if (newsLetterMail != "") {
      this.apiService
        .addActiveContact(newsLetterMail, "", "", "newsletter")
        // .pipe(map(res => res.json()))

        .subscribe(data => {
          $("input[name='email_']").val("");
          $('.fnewss').show();
        });
    } else if (!newsLetterMail.match(mailformat) && newsLetterMail != "") {
      $("#newsvalidator")
        .css({ display: "block", color: "red" })
        .prev()
        .css("border-color", "red");
      this.newserror = "Please enter a valid email address";
    } else {
      $("#newsvalidator")
        .css({ display: "block", color: "red" })
        .prev()
        .css("border-color", "red");
      this.newserror = "Please enter a valid email address";
    }
  }
  removeerror(event) {
    $("#newsvalidator")
      .hide()
      .prev()
      .removeAttr("style");
    $('.fnewss').hide();
  }

  constructor(
    private saveService: SaveService,
    private apiService: ApiService
  ) { }

  ngOnInit() {
    let userAgent = window.navigator.userAgent;
    let devicetype = this.GetUserAgent(userAgent);
    let browsername = this.GetBrowserName(userAgent);
    let currentURL = location.href;
    if (currentURL.includes("_branch_match_id")) {
      setTimeout(() => {
        let group = window.localStorage.getItem('UserGroup')
        this.googlegeneric('023518', 'ARX', '100-4542', group)
        this.applegeneric('023518', 'ARX', '100-4542', group)
      }, 2000);
    }
    else {
      setTimeout(() => {
        let group = window.localStorage.getItem('UserGroup')
        if(group == null) {
          group = 'GHRX334'
        }
        this.googlegeneric('023518', 'ARX', '100-4542', group)
        this.applegeneric('023518', 'ARX', '100-4542', group)
      }, 1500);
    }
    //  alert(devicetype + '--' + browsername);
    //  --Chrome
    if (devicetype == "Desktop") {
      this.android = true;
      this.ios = true;
    }
    if (devicetype == "iPhone") {
      this.android = false;
      this.ios = true;
    }
    if (devicetype == "Android") {
      this.android = true;
      this.ios = false;
    }
    $(".footer-nav a").click(function () {
      $(".navbar-nav a").removeClass("home-active");
    });
    this.brandName = this.saveService.getFooterBrandName();
    this.logo = this.saveService.getLogo();
    this.currentYear = new Date().getFullYear();
  }
  appleWalletPopup() {
    this.showemailpopup = !this.showemailpopup;
  }
  slideToggle() {
    if (screen.width <= 767) {
      // $('.navbar-nav').slideToggle('slow');
      // $('.navbar').toggleClass('less');    
      if ($('.navbar').hasClass('less')) {
        $('#slide-icon').attr('src', 'assets/images/close.png').delay("slow").fadeIn();
      } else {
        // $('#slide-icon').attr('src','assets/images/menu.png').delay("slow").fadeIn();
      }
    }
  }
  GetBrowserName(UserAgent_Raw) {
    if (/Firefox/i.test(UserAgent_Raw)) {
      return 'Firefox';
    } else if (/Chrome/i.test(UserAgent_Raw)) {
      return 'Chrome';
    } else if (/Safari/i.test(UserAgent_Raw)) {
      return 'Safari';
    } else if (/MSIE|Trident/i.test(UserAgent_Raw)) {
      return 'Internet Explorer';
    } else if (/Edge/i.test(UserAgent_Raw)) {
      return 'Edge';
    } else if (/Opera|OPR/i.test(UserAgent_Raw)) {
      return 'Opera';
    } else if (/Brave/i.test(UserAgent_Raw)) {
      return 'Brave';
    } else if (/Vivaldi/i.test(UserAgent_Raw)) {
      return 'Vivaldi';
    } else if (/Yandex/i.test(UserAgent_Raw)) {
      return 'Yandex';
    } else if (/Chromium/i.test(UserAgent_Raw)) {
      return 'Chromium';
    } else {
      return 'Unknown';
    }
  }
  GetUserAgent(UserAgent_Raw) {
    if (/Mobi|Android/i.test(UserAgent_Raw)) {
      if (/iPhone|iPad|iPod/i.test(UserAgent_Raw)) {
        return 'iPhone';
      }
      else {
        return 'Android';
      }
    } else {
      return 'Desktop';
    }
  }
  googlegeneric(bin, pcn, memberid, group) {
    // let group = window.localStorage.getItem('UserGroup');
    // if (group === null) {
    //   group = 'GHRX334';
    // }
    this.apiService.googlegeneric(bin, pcn, group, memberid)
      .subscribe(
        data => {
          if (data[0].passlink != "") {
            this.googlegenericpassval = data[0].passlink
          }
        })
  }
  applegeneric(bin, pcn, memberid, group) {
    // let group = window.localStorage.getItem('UserGroup');
    // if (group === null) {
    //   group = 'GHRX334';
    // }
    this.apiService.applegeneric(bin, pcn, group, memberid)
      .subscribe(
        data => {
          if (data[0].passlink != "") {
            this.applegenericpassval = data[0].passlink
          }
        })
  }
  openpass(url) {
    let userAgent = window.navigator.userAgent;
    let devicetype = this.GetUserAgent(userAgent);
    let browsername = this.GetBrowserName(userAgent);
    if (devicetype == "Desktop") {
      window.open(url)
    }
    if (devicetype == "Android") {
      window.open(url)
    }
    if (devicetype == "iPhone" && browsername == "Safari") {
      this.showemailpopup = false;
      window.open(url)
    }
    if (devicetype == "iPhone" && browsername != "Safari") {
      this.showemailpopup = true;
    }
  }
  SharePassonemail() {
    let email = this.UserInputEmailcard;
    if (!email) {
      this.UserInputEmailcarderror = "Please fill this fields.";
      return;
    }

    // Simple email validation
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      this.UserInputEmailcarderror = "Please enter a valid email address.";
      return;
    }
    this.Sendtext = "Sending";
    this.apiService.SendChromeemail(this.applegenericpassval, email)
      .subscribe(
        data => {
          if (data[0].StatusMessage == "Mail sent successfully") {
            this.Sendtext = "Sent"
            setTimeout(() => {
              this.Sendtext = "Send"
            }, 1500);
          }
          else {
            this.UserInputEmailcarderror = data[0].StatusMessage;
            setTimeout(() => {
              this.Sendtext = "Send"
            }, 1500);
          }
        }
      )
  }
  ClearMessage() {
    this.UserInputEmailcarderror = ""
  }
}

<!-- // contact section \\ -->
<section class="contact-section green-theme aboutbox_header">
  <div class="comman-width">
    <div class="content">
      <form method="post" name="contact_form" action="" id="contactForm">
        <h2>Contact Us</h2>
        <div class="left-side">
          <div class="form-group">
            <input type="text" (focus)="focusValidation('f-name')" name="f-name" id="f-name_" class="form-control"
              placeholder="First Name" autocomplete="off" required />
            <div class="colorRed" *ngIf="emptyInput">
              Please enter your first name
            </div>
          </div>
        </div>
        <div class="right-side">
          <div class="form-group">
            <input type="text" (focus)="focusValidation('l-name')" name="l-name" id="l-name_" class="form-control"
              placeholder="Last Name" autocomplete="off" required />
            <div class="colorRed" *ngIf="emptyInputLname">
              Please enter your last name
            </div>
          </div>
        </div>
        <div class="form-group">
          <input type="email" name="email" id="email_" (focus)="focusValidation('email')" class="form-control"
            [(ngModel)]="emailmodel" placeholder="Email" autocomplete="off" required />
          <div class="colorRed" *ngIf="emptyEmail">
            Please enter a valid email address
          </div>
          <div class="colorRed" *ngIf="invalidEmail">
            Please enter a valid email address
          </div>
        </div>
        <div class="form-group">
          <span class="down-arrow">&nbsp;</span>
          <select id="hearUs" name="select2" [(ngModel)]="hearUs" (change)="onChange($event.target.value)" required class="form-control">
            <option *ngFor="let prodFam of howdidhear" 
            [ngValue]="prodFam">{{prodFam}}</option>
          </select>
          <div class="colorRed" *ngIf="emptySelect1">
            Please enter hear us
            
          </div>
        </div>
        <div class="form-group">
          <span class="down-arrow">&nbsp;</span>
          <select id="helpYou" name="select1" [(ngModel)]="helpYou" (change)="onChange2($event.target.value)" required class="form-control">
            <option *ngFor="let prodFam of howcanhelp" 
            [ngValue]="prodFam">{{prodFam}}</option>
          </select>
          <div class="colorRed" *ngIf="emptySelect2">
            Please enter help us
          </div>
        </div>
        <!--
  <div class="form-group destop">
    <label class="checkbox-container">
      I am not robot
      <input value="checked"
             name="captcha"
             type="checkbox"
             (change)="checkboxFocusValidation()" />
      <span class="checkmark"></span>
    </label>
    <div class="colorRed"
         *ngIf="emptyCheckbox"
         style="margin-top:30px;">
      Please check this box
    </div>
  </div>
      -->
        <div class="form-group fourth">
          <textarea name="message" (focus)="focusValidation('message')" id="message" placeholder="Message"
            class="form-control" maxlength="500" [(ngModel)]="message_content">Message</textarea>
          <span class="counter">{{maxlength - message_content.length}}</span>
          <div class="colorRed" *ngIf="emptyMessage">
            Please enter message
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="form-group ">
          <button type="submit" [disabled]="btnDisabled" class="btn button btn-hover-effect newContactBtn btnshover is-hidden-below-500"
            (click)="validate()">
            <div class="insider"></div>
            Submit
          </button>
          <re-captcha #captchaRef="reCaptcha" (resolved)="resolved($event)" size="invisible"></re-captcha>
          <label class="checkbox-container">
            I am not a robot
            <input value="checked" name="captcha" type="checkbox" (change)="checkboxFocusValidation()"
              (click)="onLoginClick(captchaRef)" />
            <span class="checkmark"></span>
          </label>
          <p class="captchalink">
            This site is protected by reCAPTCHA and the Google
            <a href="https://policies.google.com/privacy">Privacy Policy</a> and
            <a href="https://policies.google.com/terms">Terms of Service</a>
            apply.
          </p>
          <p class="colorRed btnValidator" *ngIf="emptyCheckbox">
            Please check this box
          </p>
          <button type="submit" [disabled]="btnDisabled" class="btn button btn-hover-effect newContactBtn btnshover is-hidden-above-501"
            (click)="validate()">
            <div class="insider"></div>
            Submit
          </button>
        </div>
        <div class="" id="contactsucess">
          Thank you, your request has been submitted.
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
  <app-getcard id="daffiliate"></app-getcard>
</section>
<!-- \\ contact section // -->

<!-- // accordian section 
<section id="faq" class="accordian-section needHelp contactSecFour green-theme">
  <app-accordian></app-accordian>
</section>
accordian section // -->

<div class="contact_info">
  <!-- <div>
    <p>GlicRx</p>
    <p>PO Box 102, Madison, NH 03849</p>
  </div> -->
  <div class="needa">
    <p>Need Further Assistance?</p>
    <p>
      <a class="u" href="mailto:info@glicrx.com">info@glicrx.com</a>
    </p>
  </div>
  <div></div>
</div>

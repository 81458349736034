import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpRequest,
  HttpParams
} from "@angular/common/http";
// import { HttpClient, HttpHeaders } from "@angular/common/http";
// import { analyzeAndValidateNgModules, Type } from "@angular/compiler";
import { map } from "rxjs/operators";
import * as $ from "jquery";
// import { fn } from "@angular/compiler/src/output/output_ast";
import { GeoLocationData, SendEmailResponse } from './geo-location-data'; // Adjust the path as per your project structure
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  BaseURL: string = "https://mysql-hdrx.23byt.es/api/v2/";
  BaseURL2: string = "https://mysql-hdrx.23byt.es/api/v3/";
  emailexist: any = true;
  /* newsletter email save to google sheet functionality */
  googleSheetApi =
    "https://script.google.com/macros/s/AKfycbzZKapInrPeLM9DG8JSChDkO7L3UdBQQwwOunrauCnQGBt67PFB/exec";

  googleSheetData() {
    var googleSheetForm = $("#submit-to-google-sheet");
    var jqxhr = $.ajax({
      url: this.googleSheetApi,
      method: "GET",
      dataType: "json",
      data: googleSheetForm.serialize()
    });
  }

  constructor(public http1: HttpClient, public http: HttpClient) { }


  // SendGrid Implementation
  verifyEmail(email, fname, lname, groupnumber?, deeplink?): Observable<SendEmailResponse> {
    let emailvalid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let listId;
    if (email.match(emailvalid)) {
      if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
        if (window.localStorage.getItem('SandGridListId')) {
          listId = window.localStorage.getItem('SandGridListId')
        }
        else {
          listId = 'a3950689-86d9-4513-bcb8-964b177042e0'
        }
      }
      else {
        listId = '9c68f84f-0821-4599-b06e-f44fcdaec7cd'
      }
      const headers = new HttpHeaders();
      headers.append("authorization", "Bearer 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");
      headers.append("Content-Type", "application/json");
      let body = { "list_ids": [listId], "contacts": [{ "alternate_emails": [], "email": email, "first_name": fname, "last_name": lname, "custom_fields": { "e1_T": groupnumber, "e2_T": deeplink } }] }
      return this.http.put<SendEmailResponse>(
        "https://api.sendgrid.com/v3/marketing/contacts",
        body,
        { headers: headers }
      );
    }
    else {
      // 
    }
  }
  verifyEmailklaviyo(email, fname, lname, groupnumber?, deeplink?) {

    let emailvalid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let listId = window.localStorage.getItem('klaviyoid');
    // group number condition
    let memberId = '100-4542';
    if (groupnumber === 'GST1016') {
      memberId = '48786';
    }
    let wholesalername = window.localStorage.getItem('WhName')
    if (wholesalername == "Avenel" && groupnumber != 'GST1016') {
      memberId = "12345"
    }
    if (email.match(emailvalid)) {
      if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
        //  listId = window.localStorage.getItem('klaviyoid');
        if (!listId) {
          listId = 'T8cDcK';
        }
        // const headers = new HttpHeaders();
        // headers.append("Content-Type", "application/json");
        const headers = new HttpHeaders({
          'Authorization': 'Klaviyo-API-Key pk_bd79264665209c605ee0841fdc55d3f52d',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'revision': '2024-02-15'
        });
        let body = {
          "data": {
            "type": "profile-subscription-bulk-create-job",
            "attributes": {
              "profiles": {
                "data": [
                  {
                    "type": "profile",
                    "attributes": {
                      "email": email,
                      "subscriptions": {
                        "email": {
                          "marketing": {
                            "consent": "SUBSCRIBED"
                          }
                        }
                      }
                    },
                    "id": ""
                  }
                ]
              },
              "custom_source": ""
            },
            "relationships": {
              "list": {
                "data": {
                  "type": "list",
                  "id": listId
                }
              }
            }
          }
        }

        return this.http.post(
          "https://cors.23byt.es/https://a.klaviyo.com/api/profile-subscription-bulk-create-jobs/",
          body,
          { headers: headers }
        );
      }
      else {

        if (!listId) {
          listId = 'WScGSe';
        }
        // const headers = new HttpHeaders();
        // headers.append("Content-Type", "application/json");
        const headers = new HttpHeaders({
          'Authorization': 'Klaviyo-API-Key pk_d7f8fc147049fd2983454020224fa33531',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'revision': '2024-02-15'
        });
        let body = {
          "data": {
            "type": "profile-subscription-bulk-create-job",
            "attributes": {
              "profiles": {
                "data": [
                  {
                    "type": "profile",
                    "attributes": {
                      "email": email,
                      "subscriptions": {
                        "email": {
                          "marketing": {
                            "consent": "SUBSCRIBED"
                          }
                        }
                      }
                    },
                    "id": ""
                  }
                ]
              },
              "custom_source": ""
            },
            "relationships": {
              "list": {
                "data": {
                  "type": "list",
                  "id": listId
                }
              }
            }
          }
        }

        return this.http.post(
          "https://cors.23byt.es/https://a.klaviyo.com/api/profile-subscription-bulk-create-jobs/",
          body,
          { headers: headers }
        );
      }

    }
    else {
      // 
    }
  }
  getProfileByEmail(email) {
    const encodedEmail = encodeURIComponent(email);
    const key = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'pk_bd79264665209c605ee0841fdc55d3f52d' : 'pk_d7f8fc147049fd2983454020224fa33531'
    const url = `https://cors.23byt.es/https://a.klaviyo.com/api/profiles/?filter=equals(email%2C%27${encodedEmail}%27)`;
    const headers = new HttpHeaders({
      'Authorization': `Klaviyo-API-Key ${key}`,
      'accept': 'application/json',
      'revision': '2024-02-15'
    });

    return this.http.get(url, { headers });
  }
  updateProfile(email, fname, lname, groupnumber, deeplink, id) {
    const url = `https://cors.23byt.es/https://a.klaviyo.com/api/profiles/${id}`;
    const key = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'pk_bd79264665209c605ee0841fdc55d3f52d' : 'pk_d7f8fc147049fd2983454020224fa33531'

    let memberId = window.localStorage.getItem("UserCharId") ? window.localStorage.getItem("UserCharId") : '100-4542';
    if (groupnumber === 'GST1016') {
      memberId = '48786';
    }
    let wholesalername = window.localStorage.getItem('WhName')
    if (wholesalername == "Avenel" && groupnumber != 'GST1016') {
      memberId = "12345"
    }
    const headers = new HttpHeaders({
      'Authorization': `Klaviyo-API-Key ${key}`,
      'accept': 'application/json',
      'content-type': 'application/json',
      'revision': '2024-02-15'
    });

    const data = {
      "data": {
        "type": "profile",
        "id": id,
        "attributes": {
          "email": email,
          "first_name": fname,
          "last_name": lname,
          "properties": {
            "email": email,
            "first_name": fname,
            "last_name": lname,
            "deeplink": deeplink,
            "Group No": groupnumber ? groupnumber : window.localStorage.getItem('UserGroup'),
            "memid_source": "web",
            "memid": memberId
          }
        }
      }
    };

    return this.http.patch(url, data, { headers });
  }
  addToMailingList(email, fname, lname, groupnumber?, deeplink?) {
    const headers = new HttpHeaders();
    headers.append("authorization", "Bearer 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");
    headers.append("Content-Type", "application/json");

    // group number condition
    let memberId = '100-4542';
    if (groupnumber === 'GST1016') {
      memberId = '48786';
    }
    let wholesalername = window.localStorage.getItem('WhName')
    if (wholesalername == "Avenel" && groupnumber != 'GST1016') {
      memberId = "12345"
    }
    let no_deeplink = {
      "first_name": fname,
      "last_name": lname,
      "e2_t": null,
      "e1_T": groupnumber,
      "playstore": 'https://play.google.com/store/apps/details?id=com.firebase.honest&hl=en&gl=US',
      "appstore": 'https://apps.apple.com/in/app/glicrx/id1439777162',
      "member_id": memberId
    }

    let has_deeplink = {
      "first_name": fname,
      "last_name": lname,
      "e2_t": deeplink,
      "e1_T": groupnumber,
      "member_id": memberId
    }


    let body = {
      "personalizations": [
        {
          "to": [
            {
              "email": email,
              "name": fname
            }
          ],
          "dynamic_template_data": !!deeplink ? has_deeplink : no_deeplink
        }
      ],
      "from": {
        "email": "development@glicrx.com",
        "name": "GlicRx"
      },
      "template_id": "d-81161b55115745c6a4e23f8c0c92bba2"
    }
    return this.http.post(
      "https://cors.23byt.es/https://api.sendgrid.com/v3/mail/send",
      body,
      { headers: headers }
    );
  }

  // BrokerList
  addTobrokerList(email, fname, lname, cc_rep_name, groupnumber, deeplink, cc_rep_phone, cc_rep_company_name, cc_rep_address_1, cc_rep_address_2, cc_rep_email) {
    let key = '';
    if (location.host !== 'glicrx.com') {
      key = '596d9a8a-c699-4077-86b3-2c5dacffd6a4'
    }
    else {
      key = window.localStorage.getItem('liid');
    }
    if (JSON.parse(window.localStorage.getItem('signstatus'))) {

    }
    else {
      cc_rep_name = '';
      cc_rep_phone = "";
      cc_rep_company_name = "";
      cc_rep_address_1 = "";
      cc_rep_address_2 = "";
      cc_rep_email = ""
    }
    const headers = new HttpHeaders();
    headers.append("authorization", "Bearer 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");
    headers.append("Content-Type", "application/json");
    let body = { "list_ids": [key], "contacts": [{ "alternate_emails": [], "email": email, "first_name": fname, "last_name": lname, "custom_fields": { "w7_T": cc_rep_name, "e1_T": groupnumber, "e2_T": deeplink, 'w8_T': cc_rep_phone, 'w9_T': cc_rep_company_name, 'w10_T': cc_rep_address_1, 'w11_T': cc_rep_address_2, w12_T: cc_rep_email } }] }
    return this.http.put(
      "https://api.sendgrid.com/v3/marketing/contacts",
      body,
      { headers: headers }
    );
  }
  subscribetobrokerlist(email, fname, lname, cc_rep_name, groupnumber, deeplink, cc_rep_phone, cc_rep_company_name, cc_rep_address_1, cc_rep_address_2, cc_rep_email) {

    let emailvalid = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let listId = window.localStorage.getItem('klaviyoid');
    // group number condition
    let memberId;
    if (email.match(emailvalid)) {
      if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
        if (!listId) {
          listId = 'T8cDcK';
        }
        if (JSON.parse(window.localStorage.getItem('signstatus'))) {

        }
        else {
          cc_rep_name = '';
          cc_rep_phone = "";
          cc_rep_company_name = "";
          cc_rep_address_1 = "";
          cc_rep_address_2 = "";
          cc_rep_email = ""
        }
        const headers = new HttpHeaders({
          'Authorization': 'Klaviyo-API-Key pk_bd79264665209c605ee0841fdc55d3f52d',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'revision': '2024-02-15'
        });
        let body = {
          "data": {
            "type": "profile-subscription-bulk-create-job",
            "attributes": {
              "profiles": {
                "data": [
                  {
                    "type": "profile",
                    "attributes": {
                      "email": email,
                      "subscriptions": {
                        "email": {
                          "marketing": {
                            "consent": "SUBSCRIBED"
                          }
                        }
                      }
                    },
                    "id": ""
                  }
                ]
              },
              "custom_source": ""
            },
            "relationships": {
              "list": {
                "data": {
                  "type": "list",
                  "id": listId
                }
              }
            }
          }
        }

        return this.http.post(
          "https://cors.23byt.es/https://a.klaviyo.com/api/profile-subscription-bulk-create-jobs/",
          body,
          { headers: headers }
        );
      }
      else {

        if (!listId) {
          listId = 'WScGSe';
        }
        // const headers = new HttpHeaders();
        // headers.append("Content-Type", "application/json");
        const headers = new HttpHeaders({
          'Authorization': 'Klaviyo-API-Key pk_d7f8fc147049fd2983454020224fa33531',
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'revision': '2024-02-15'
        });
        let body = {
          "data": {
            "type": "profile-subscription-bulk-create-job",
            "attributes": {
              "profiles": {
                "data": [
                  {
                    "type": "profile",
                    "attributes": {
                      "email": email,
                      "subscriptions": {
                        "email": {
                          "marketing": {
                            "consent": "SUBSCRIBED"
                          }
                        }
                      }
                    },
                    "id": ""
                  }
                ]
              },
              "custom_source": ""
            },
            "relationships": {
              "list": {
                "data": {
                  "type": "list",
                  "id": listId
                }
              }
            }
          }
        }

        return this.http.post(
          "https://cors.23byt.es/https://a.klaviyo.com/api/profile-subscription-bulk-create-jobs/",
          body,
          { headers: headers }
        );
      }

    }
    else {
      // 
    }
  }

  addTobrokerListKlaviyo(email, fname, lname, cc_rep_name, groupnumber, deeplink, cc_rep_phone, cc_rep_company_name, cc_rep_address_1, cc_rep_address_2, cc_rep_email, id) {
    const url = `https://cors.23byt.es/https://a.klaviyo.com/api/profiles/${id}`;
    const key = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'pk_bd79264665209c605ee0841fdc55d3f52d' : 'pk_d7f8fc147049fd2983454020224fa33531'
    const headers = new HttpHeaders({
      'Authorization': `Klaviyo-API-Key ${key}`,
      'accept': 'application/json',
      'content-type': 'application/json',
      'revision': '2024-02-15'
    });

    const data = {
      "data": {
        "type": "profile",
        "id": id,
        "attributes": {
          "email": email,
          "first_name": fname,
          "last_name": lname,
          "properties": {
            "email": email,
            "first_name": fname,
            "last_name": lname,
            "Cc Rep Name": cc_rep_name,
            "Group No": groupnumber,
            "Deep Link": deeplink,
            'Cc Rep Phone': cc_rep_phone,
            'Cc Rep Company Name': cc_rep_company_name,
            'Cc Rep Address 1': cc_rep_address_1,
            'Cc Rep Address 2': cc_rep_address_2,
            'Cc Rep Email': cc_rep_email,
            "memid_source": 'ecenter',
            "memid": '009-4542'
          }
        }
      }
    };

    return this.http.patch(url, data, { headers });


  }

  unsubscribe_klaviyo(email) {
    const url = 'https://cors.23byt.es/https://a.klaviyo.com/api/profile-subscription-bulk-delete-jobs/';
    const key = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'pk_bd79264665209c605ee0841fdc55d3f52d' : 'pk_d7f8fc147049fd2983454020224fa33531'
    let listid = window.localStorage.getItem('klaviyoid')
    if (!listid) {
      if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
        listid = 'T8cDcK';
      }
      else {
        listid = 'WScGSe'
      }
    }
    const headers = new HttpHeaders({
      'Authorization': `Klaviyo-API-Key ${key}`,
      'accept': 'application/json',
      'content-type': 'application/json',
      'revision': '2024-02-15'
    });

    const data = {
      "data": {
        "type": "profile-subscription-bulk-delete-job",
        "attributes": {
          "profiles": {
            "data": [
              {
                "type": "profile",
                "attributes": {
                  "email": email,
                }
              }
            ]
          }
        },
        "relationships": {
          "list": {
            "data": {
              "type": "list",
              "id": listid
            }
          }
        }
      }
    };

    return this.http.post(url, data, { headers });


  }
  savehistoricaldata(userRowId, fName, lName, email, phone) {
    let brokerdetail = JSON.parse(window.localStorage.getItem('brokerdetail'));
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    let body = {
      "distId": brokerdetail.brokerDetails[0].distId,
      "emailId": window.localStorage.getItem('brokeremail'),
      "groupNumber": window.localStorage.getItem('brokergroup'),
      "enroll_log": [
        {
          "userRowId": userRowId,
          "fName": fName,
          "lName": lName,
          "email": email,
          "phone": phone
        }
      ]
    }
    if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
      return this.http.post(
        "https://cors.23byt.es/https://mysql-hdrx.23byt.es/api/v3/enroll_edit",
        body,
        {
          headers: headers
        }
      );
    }
    else {
      return this.http.post(
        "https://cors.23byt.es/https://mysql-hdrx.23byt.es/api/v3/enroll_edit_dev",
        body,
        {
          headers: headers
        }
      );
    }
  }

  addToMailingListt(email, fname, lname, groupnumber?, deeplink?, dataurl?, memberid?) {
    const headers = new HttpHeaders();
    headers.append("authorization", "Bearer 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");
    headers.append("Content-Type", "application/json");

    // group number condition
    let memberId = '100-4542';
    if (groupnumber === 'GST1016') {
      memberId = '48786';
    }
    let wholesalername = window.localStorage.getItem('WhName')
    if (wholesalername == "Avenel" && groupnumber != 'GST1016') {
      memberId = "12345"
    }
    let no_deeplink = {
      "first_name": fname,
      "last_name": lname,
      "e2_t": null,
      "e1_T": groupnumber,
      "playstore": 'https://play.google.com/store/apps/details?id=com.firebase.honest&hl=en&gl=US',
      "appstore": 'https://apps.apple.com/in/app/glicrx/id1439777162',
      "member_id": memberId
    }

    let has_deeplink = {
      "first_name": fname,
      "last_name": lname,
      "e2_t": deeplink,
      "e1_T": groupnumber,
      "member_id": memberId
    }


    let body = {
      "personalizations": [
        {
          "to": [
            {
              "email": email,
              "name": fname
            }
          ],
          "dynamic_template_data": !!deeplink ? has_deeplink : no_deeplink
        }
      ],
      "attachments": [
        {
          "content": dataurl,
          "disposition": "inline",
          "filename": "Discount Card.png",
          "name": "file1",
          "type": "text/plain",
          "content_id": "file1"
        }
      ],
      "from": {
        "email": "wehelp@glicrx.com",
        "name": "GlicRx"
      },
      "template_id": "d-08eb6f063d4b47068b59a9eae8e28976"
    }
    return this.http.post(
      "https://cors.23byt.es/https://api.sendgrid.com/v3/mail/send",
      body,
      { headers: headers }
    );
  }

  // validatebrokeremail(email):Observable<SendEmailResponse> {
  validatebrokeremail(email): Observable<SendEmailResponse> {
    const headers = new HttpHeaders()
      .set("Authorization", "Bearer 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")
      .set("Content-Type", "application/json");

    const body = { "email": email, "source": "" };

    return this.http.post<SendEmailResponse>(
      "https://api.sendgrid.com/v3/validations/email",
      body,
      { headers: headers }
    );
  }
  savecustomqueryparams(customlink, gn) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let domain = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'prod' : 'dev'
    let body = { "customlink": customlink, "gn": gn, "domain": domain }
    return this.http.post(
      "https://cors.23byt.es/" + this.BaseURL2 + "deeplink_click",
      body,
      { headers: headers }
    );
  }
  SearchDrugList(searchtext) {
    var Encode_DrugName = encodeURIComponent(searchtext);
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    return this.http.post(
      this.BaseURL2 + "searchdrug",
      "stext=" + Encode_DrugName,
      {
        headers: headers
      }
    );
  }

  printcard(grpno) {
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json; charset=utf-8"
    );
    let body = {
      "gn": grpno,
      "p2": "",
      "p3": ""
    }
    return this.http.post(
      "https://cors.23byt.es/https://shareapp.23byt.es/api/v3/printcard",
      body,
      {
        headers: headers
      }
    );
  }
  GetPopularDrug() {
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    return this.http.post(this.BaseURL2 + "populardrug", "DrugName=" + "test", {
      headers: headers
    });
  }
  GetDrugDetails(DrugName) {
    var Encode_DrugName = encodeURIComponent(DrugName);
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=utf-8"
    );
    return this.http.post(
      this.BaseURL2 + "drugcomponents",
      "DrugName=" + Encode_DrugName,
      { headers: headers }
    );
  }
  // checkemailexist(email): Observable<SendEmailResponse> {
  //   const headers = new HttpHeaders();
  //   headers.append("Accept", "*/*");
  //   headers.append("Content-Type", "application/x-www-form-urlencoded");
  //   headers.append("No-Auth", "True");
  //   const body = new URLSearchParams();
  //   body.set("filters[email]", email);
  //   return this.http.post(
  //     "https://cors.23byt.es/https://valpaksi.api-us1.com/admin/api.php?api_action=contact_list&api_output=json&api_key=84747226ebba49bee437d9460570432594890916bcd02276a010033c5589579274b27e15",
  //     body.toString(),
  //     { headers: headers }
  //   );
  // }

  removetag(email) {
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("No-Auth", "True");
    const body = new URLSearchParams();
    body.set("email", email);
    body.set("tags", "HDWebsite");
    return this.http.post(
      "https://cors.23byt.es/https://valpaksi.api-us1.com/admin/api.php?api_action=contact_tag_remove&api_key=84747226ebba49bee437d9460570432594890916bcd02276a010033c5589579274b27e15&api_output=json",
      body.toString(),
      { headers: headers }
    );
  }
  addtag(email) {
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("No-Auth", "True");
    const body = new URLSearchParams();
    body.set("email", email);
    body.set("tags", "HDWebsite");
    return this.http.post(
      "https://cors.23byt.es/https://valpaksi.api-us1.com/admin/api.php?api_action=contact_tag_add&api_key=84747226ebba49bee437d9460570432594890916bcd02276a010033c5589579274b27e15&api_output=json",
      body.toString(),
      { headers: headers }
    );
  }
  addActiveContact(email, first_name, last_name, tags) {
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("No-Auth", "True");
    const body = new URLSearchParams();
    body.set("email", email);
    body.set("first_name", first_name);
    body.set("last_name", last_name);
    body.set("tags", tags);
    return this.http.post(
      "https://cors.23byt.es/https://valpaksi.api-us1.com/admin/api.php?api_action=contact_add&api_key=84747226ebba49bee437d9460570432594890916bcd02276a010033c5589579274b27e15&api_output=json",
      body.toString(),
      { headers: headers }
    );
  }
  sendEmail(email, name, Message, hearUs, helpUs) {
    let toEmail;
    if (helpUs == 'I need help with my GlicRx card') {
      toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'agentsupport@glicrx.com' : 'development@glichealth.com'
    } else if (helpUs == 'I would like to partner with Glic') {
      toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'sales@glicrx.com' : 'meher@23byt.es'
    } else {
      toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'info@glicrx.com' : 'development@glichealth.com'
    }
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      // "Bearer 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"
      "Bearer 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"
    );
    let body = {
      from: {
        email: email,
        name: name
      },
      personalizations: [
        {
          to: [
            {
              email: toEmail
            }
          ],
          dynamic_template_data: {
            receipt: true,
            name: name,
            email: email,
            Message: Message,
            hearUs: hearUs,
            helpUs: helpUs
          }
        }
      ],
      template_id: "d-1d9b2954146c4867a498753f6ab32fe9"
    };
    // body.set("email", email);
    // body.set("first_name", first_name);
    // body.set("last_name", last_name);
    // body.set("tags", tags);
    return this.http.post(
      "https://cors.23byt.es/https://api.sendgrid.com/v3/mail/send",
      body,
      { headers: headers }
    );
  }

  sendEmailContact(email, name, Message, hearUs, helpUs) {
    let toEmail;
    if (helpUs == 'I need help with my GlicRx card') {
      toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'agentsupport@glicrx.com' : 'development@glichealth.com'
    } else if (helpUs == 'I would like to partner with Glic') {
      toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'sales@glicrx.com' : 'meher@23byt.es'
    } else {
      toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'info@glicrx.com' : 'development@glichealth.com'
    }
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/json");

    let body = { "fullname": name, "email": email, "hearus": hearUs, "helpus": helpUs, "message": Message, "toemail": toEmail }
    // body.set("email", email);
    // body.set("first_name", first_name);
    // body.set("last_name", last_name);
    // body.set("tags", tags);
    return this.http.post(
      "https://cors.23byt.es/" + this.BaseURL2 + "grcontact",
      body,
      { headers: headers }
    );
  }

  sendAgentEmail(email, name, Message): Observable<SendEmailResponse> {
    let toEmail = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'agentsupport@glicrx.com' : 'development@glichealth.com'
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      // "Bearer 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"
      "Bearer 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"
    );
    let body = {
      from: {
        email: email,
        name: name
      },
      personalizations: [
        {
          to: [
            {
              email: toEmail
            }
          ],
          dynamic_template_data: {
            receipt: true,
            name: name,
            email: email,
            Message: Message
          }
        }
      ],
      template_id: "d-e9b53b8ea8bf4111b8d4a8ceebc9e91f"
    };
    // body.set("email", email);
    // body.set("first_name", first_name);
    // body.set("last_name", last_name);
    // body.set("tags", tags);
    return this.http.post<SendEmailResponse>(
      "https://cors.23byt.es/https://api.sendgrid.com/v3/mail/send",
      body,
      { headers: headers }
    );
  }
  sendHelp(email, name, Message): Observable<SendEmailResponse> {
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/json");
    headers.append(
      "Authorization",
      // "Bearer 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"
      "Bearer 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"
    );
    let body = {
      from: {
        email: email,
        name: name
      },
      personalizations: [
        {
          to: [
            {
              email: "info@glicrx.com"
            }
          ],
          dynamic_template_data: {
            receipt: true,
            name: name,
            email: email,
            Message: Message
          }
        }
      ],
      template_id: "d-6f3d0b6c46944d6d951a88b0289c79e1"
    };
    // body.set("email", email);
    // body.set("first_name", first_name);
    // body.set("last_name", last_name);
    // body.set("tags", tags);
    return this.http.post<SendEmailResponse>(
      "https://cors.23byt.es/https://api.sendgrid.com/v3/mail/send",
      body,
      { headers: headers }
    );
  }
  sendSms(mobileno) {

    var output = mobileno.replace(/\W+/g, "");
    var dl = window.localStorage.getItem('DeepLinkURL') ? window.localStorage.getItem('DeepLinkURL') : 'https://bit.ly/GlicRxShare';
    output = "+1" + output;
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append(
      "Authorization",
      "Basic " +
      btoa(
        "AC2f03c2edaf3af88d1c3edb84608d0697: a6532949dd889ad71af9c88ae6b92ed7"
      )
    );
    const body = new URLSearchParams();
    body.set("To", output);
    body.set(
      "Body",
      "Please use the link below to download GlicRx and start saving now! \n \n " + dl
    );
    body.set("From", "(877) 313-5219");
    // headers.append(
    //   "Content-Type",
    //   "application/x-www-form-urlencoded; charset=utf-8"
    // );
    return this.http.post(
      "https://cors.23byt.es/https://api.twilio.com/2010-04-01/Accounts/AC2f03c2edaf3af88d1c3edb84608d0697/Messages.json",
      body.toString(),
      { headers: headers }
    );
  }
  validatephone(phoneno): Observable<SendEmailResponse> {
    const headers = new HttpHeaders();
    headers.append(
      "Authorization",
      "Basic " +
      btoa(
        "AC2f03c2edaf3af88d1c3edb84608d0697:a6532949dd889ad71af9c88ae6b92ed7"
      )
    );
    return this.http.get<SendEmailResponse>(
      "https://cors.23byt.es/https://lookups.twilio.com/v1/PhoneNumbers/" + phoneno + "?CountryCode=US",
      { headers: headers }
    );
  }
  sendSmsbroker(mobileno, deeplink) {

    let DLink = JSON.parse(window.localStorage.getItem('brokerdetail'));
    DLink = DLink.brokerDetails[0];
    DLink = DLink.deepLink;

    var output = mobileno.replace(/\W+/g, "");
    output = "+1" + output;
    const headers = new HttpHeaders();
    headers.append("Accept", "*/*");
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append(
      "Authorization",
      "Basic " +
      btoa(
        "AC2f03c2edaf3af88d1c3edb84608d0697: a6532949dd889ad71af9c88ae6b92ed7"
      )
    );
    const body = new URLSearchParams();
    body.set("To", output);
    body.set(
      "Body",
      "Welcome to GlicRx! You can save up to $95 when you pay cash for your prescriptions. Click to get started! \n" + DLink + "\n Reply STOP to unsubscribe. Msg&Data Rates May Apply."
    );
    body.set("From", "(877) 313-5219");
    // headers.append(
    //   "Content-Type",
    //   "application/x-www-form-urlencoded; charset=utf-8"
    // );
    return this.http.post(
      "https://cors.23byt.es/https://api.twilio.com/2010-04-01/Accounts/AC2f03c2edaf3af88d1c3edb84608d0697/Messages.json",
      body.toString(),
      { headers: headers }
    );
  }
  googlegeneric(bin, pcn, group, memberid) {
    const headers = new HttpHeaders();
    let dl = window.localStorage.getItem("DeepLinkURL");
    headers.append("Content-Type", "application/json");
    let body = { "bin": bin, "pcn": pcn, "group": group, "memberid": memberid, "appname": "Glic" }
    return this.http.post(
      "https://cors.23byt.es/https://passes.23byt.es/api/v1/pass/google_generic_pass",
      body,
      { headers: headers }
    );
  }
  applegeneric(bin, pcn, group, memberid) {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let body = { "bin": bin, "pcn": pcn, "group": group, "memberid": memberid, "appname": "Glic" }
    return this.http.post(
      "https://cors.23byt.es/https://passes.23byt.es/api/v1/pass/apple_generic_pass",
      body,
      { headers: headers }
    );
  }
  SendChromeemail(link, email) {
    // let baseurlbackoffice = location.host == 'rxhacker.com' || location.host == 'www.rxhacker.com/' ? 'https://backoffice.rxhacker.com' : 'https://dev-backoffice.rxhacker.com'
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let body = { "link": link, "email": email }
    return this.http.post(
      "https://cors.23byt.es/https://mysql-hdrx.23byt.es/api/v3/walletmail",
      body,
      { headers: headers }
    );
  }
}
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  HostListener,
} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MedicinService } from "../services/index";
import { PrerequisiteService } from "../services/prerequisite.service";
import { CommonService } from "../services/common.service";
import { map, tap } from "rxjs/operators";
import * as $ from "jquery";
// import { strictEqual } from "assert";
import { AppComponent } from "../app.component";
import { PharmacyComponent } from "../pharmacy/pharmacy.component";
import { ActivatedRoute, NavigationExtras } from "@angular/router";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { GoogleAnalyticsService } from "../services/google-analytics-service.service";
// import { stringify } from "@angular/compiler/src/util";
import { SaveService } from "../services/save.service";
import { ApiService } from "../services/api.service";
// import { debugOutputAstAsTypeScript } from "@angular/compiler";
import { PdflinkComponent } from "../pdflink/pdflink.component";
const branch = window['branch']

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  providers: [
    MedicinService,
    PrerequisiteService,
    GoogleAnalyticsService,
    ApiService,
  ],
})
export class HomeComponent implements OnInit {
  // @Output() someEvent = new EventEmitter<string>();
  public incorrect_fullname: any;
  public incorrect_groupnumber: any;
  public access_code: String = '';
  public current_group_number: String = '';
  public deeplink_url: any = '';
  public DrugNameTypeSingle: boolean = false;
  public DrugFormSingle: boolean = false;
  public DrugDoseSingle: boolean = false;
  public SearchItemArray: any[] = [];
  PopularDrugs: any = [];
  BindDrugs: any = [];
  SerachList: any = [];
  SearchList: any = [];
  DrugDetailsResponseData: any = [];
  public ShowDisplay: boolean = false;
  public ShowRecentSearch: boolean = false;
  public ShowDrugdetails: boolean = false;
  private SearchText: any;
  public SelectedDrug: string = "";
  public DisplayBindList: boolean = false;
  hideme = [];
  //--------Click on drug ------------
  public Quantity_error: string = "";
  public Quantity_Manual: string = "";
  public ManualQuantity_box: boolean = false;
  public NoDrugsFound: boolean = false;
  public searchtextbox_error: string = "";
  selectedValue: string;
  optionlist: any[] = [];
  DoseDescriptor_JSON: any;
  DrugDetails: any;
  values: any;
  coordinates: any = [];
  DrugTypeList: any = [];
  DrugDoseList: any = [];
  DrugQuantityList: any = [];
  _NameFilterForQuantity: any = [];
  DrugDoseDescriptorList: any = [];
  LocationPermission: any = [];
  FormDoseData: any = [];
  ResponseData: any = [];
  PresetDrugData: any = [];
  public DrugConfigList: any = [];
  DrugDoseListCount: any;
  BrandType: boolean = false;
  GenericType: boolean = false;
  IsPresetFound: boolean = false;
  public ShowBrandDetails: boolean = false;
  public ShowGenericDetails: boolean = false;
  public DoseFormSingle: any = false;
  public StrengthSingle: any = false;
  public ShowDetails: boolean = false;
  public DrugName: string = "";
  public DrugCat: string = "";
  public DrugDose: string = "";
  public DrugQuant: any;
  public DrugType: string = "";
  public _IsBack: string = "No";
  public unregisterBackButtonAction: any;
  public Latitude: string = "";
  public Longitude: string = "";
  public Swap_Drug_With_MultiSource: string = "";
  public _UserZip = "";
  public _CurrLocation: string = "";
  public ZipCodeMessage: string = "";
  public IsAutoLocation: string = "N/A";
  public group_number: Boolean = true;
  private email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  //-----------Account Details-------
  public Group_Number_Box: boolean = false;
  public _AppName: string = "";
  public _AppEmail: string = "";
  public Enter_Zip: boolean = false;

  public acceptLogoArray;
  public incorrect_email: any;
  public input_groupnumber_error: any;

  branchCopy: any;
  brandName;
  secondBrandName;
  sendno: any = "";
  sendmessageClass: any = "";
  sendmessageStatus: any = "";
  email: any = "";
  first_name: any = "";
  last_name: any = "";
  tags: any = "";
  errormessageFName: any = "";
  errormessageLName: any = "";
  errormessageEmail: any = "";
  cardsuccess: any = false;

  slides = [
    {
      img: "../assets/images/thumbnail_icon1.png",
      heading: "Bill, South Berwick, ME",
      text:
        "Using my GlicRx discount card saved me more than half of my prescription costs this month. Over $65 dollars!",
    },
    {
      img: "../assets/images/thumbnail_icon2.png",
      heading: "Malinda, Brooklyn, NY",
      text:
        "I used the GlicRx mobile app and found a cheaper pharmacy in my town.  I saved $108 on my last prescription.",
    },
    {
      img: "../assets/images/thumbnail_icon3.png",
      heading: "Enrico, Boston, MA",
      text: "Awesome app! easy to use and has saved me a ton of money",
    },
    {
      img: "../assets/images/thumbnail_icon1.png",
      heading: "JDParkes, Houston, TX",
      text:
        "Works much better than old app. Better prices than other discount cards",
    },
  ];
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: "<div class='nav-btn next-slide'></div>",
    prevArrow: "<div class='nav-btn prev-slide'></div>",
    dots: false,
    infinite: true,
    arrows: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerMode: false,
          nextArrow: "<div class='nav-btn next-slide'></div>",
          prevArrow: "<div class='nav-btn prev-slide'></div>",
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 481,
        settings: {
          centerMode: false,
          nextArrow: "<div class='nav-btn next-slide'></div>",
          prevArrow: "<div class='nav-btn prev-slide'></div>",
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
    ],
  };
  constructor(
    public http: HttpClient,
    private ms: MedicinService,
    public rs: PrerequisiteService,
    private route: ActivatedRoute,
    public router: Router,
    private sidebarService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService,
    public SaveService: SaveService,
    public ApiService: ApiService
  ) {
    this._AppName = window.localStorage.getItem("WhiteName");
    this._AppEmail = window.localStorage.getItem("WhiteEmail");
    this.AppInitialize();
    this._CurrLocation = window.localStorage.getItem("UserCurrentAddress");
    this._UserZip = window.localStorage.getItem("userZipCode");
    this.sidebarService.apptohome.subscribe((value) => {
      this.ShowDisplay = false;
      this.DisplayBindList = false;
    });
  }
  slideToggle() {
    if (screen.width <= 767) {
      // $('.navbar-nav').slideToggle('slow');
      // $('.navbar').toggleClass('less');    
      if ($('.navbar').hasClass('less')) {
        $('#slide-icon').attr('src', 'assets/images/close.png').delay("slow").fadeIn();
      } else {
        // $('#slide-icon').attr('src','assets/images/menu.png').delay("slow").fadeIn();
      }
    }
  }
  setGroupNumber(event) {
    this.incorrect_email = this.incorrect_fullname = this.incorrect_groupnumber = false;

    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.first_name == "") {
      $(event.target).removeClass("loader");
      $("#fnameerror").parent().addClass("error_msg");
      this.errormessageFName = "Please enter your first name";
      this.cardsuccess = false;
    }
    if (this.last_name == "") {
      $(event.target).removeClass("loader");
      $("#lnameerror").parent().addClass("error_msg");
      this.errormessageLName = "Please enter your last name";
      this.cardsuccess = false;
    }
    if (this.email != "" && !this.email.match(mailformat)) {
      $(event.target).removeClass("loader");
      $("#emailerror").parent().addClass("error_msg");
      this.errormessageEmail = "Please enter a valid email address";
      this.cardsuccess = false;
    }
    if (this.email == "") {
      $(event.target).removeClass("loader");
      $("#emailerror").parent().addClass("error_msg");
      this.errormessageEmail = "Please enter a valid email address";
      this.cardsuccess = false;
    }

    if (!(!!this.access_code)) {
      $(event.target).removeClass("loader");
      $("#access_code").parent().addClass("error_msg");
      this.incorrect_groupnumber = "Please enter a valid access code";
      this.cardsuccess = false;
    }


    if (!!this.first_name && !!this.last_name) {
      //
    } else {
      this.incorrect_fullname = true;
      return 0;
    }

    if (!!this.email) {
      this.email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.email_REGEX.test(this.email) == false) {
        this.incorrect_email = true;
        return 0;
      }
    } else {
      this.incorrect_email = true;
      return 0;
    }

    let ac = window.localStorage.getItem("UserGroup");
    ac = !!ac ? ac.toString() : this.access_code.toString();

    this.ms
      .GNValidate(ac)
      // .pipe(map((res) => res.json()))
      .subscribe(
        (data) => {
          if (data.length > 0) {
            if (!data[0].GroupNumber[0].Status) {
              console.log("Incorrect group number");
              this.incorrect_groupnumber = true;
              $("#access_code").parent().addClass("error_msg");
              this.incorrect_groupnumber = "Please enter a valid access code";
              // this.input_groupnumber_error = "* " + data[0].StatusMessage;
              // $("#InputGroup").parent().addClass("error_msg");
            } else if (data[0].GroupNumber[0].Status) {
              this.incorrect_email = this.incorrect_fullname = this.incorrect_groupnumber = false;
              window.localStorage.setItem("UserGroup", data[0].GroupNumber[0].GroupNumber.toUpperCase());
              //this.AddUserToSendGrid(this.email, this.last_name, this.last_name, this.current_group_number, this.deeplink_url);
              if (data[0].Details.length > 0) {
                if (data[0].Details[0].SandGridListId) {
                  window.localStorage.setItem('SandGridListId', data[0].Details[0].SandGridListId);
                }
                if (data[0].Details[0].KlaviyoListId != '') {
                  window.localStorage.setItem('klaviyoid', data[0].Details[0].KlaviyoListId)
                }
                else {
                  window.localStorage.setItem('klaviyoid', '')
                }
              }

              this.checkGno_DeeplinkURL();
              try {
                this.submitCard(event);
              } catch (error) {
                console.log("Incorrect gno");
                this.incorrect_email = true;
                $("#access_code").parent().addClass("error_msg");
                this.incorrect_groupnumber = "Please enter a valid access code";

              }
              // this.success_group_validate = true;
              // setTimeout(() => {
              //   this.success_group_validate = false;
              //   this.groupnumber = false;
              // }, 4000);
            }
          } else {
            console.log("Incorrect gno");

            $("#access_code").parent().addClass("error_msg");
            this.incorrect_groupnumber = "Please enter a valid access code";
            // window.localStorage.setItem("UserGroup", "False");
            // $("#InputGroup").parent().addClass("error_msg");
          }
          if (data[0].Wholesaer != null) {
            if (data[0].Wholesaer[0].WhName == "Avenel") {
              window.localStorage.setItem('WhName', data[0].Wholesaer[0].WhName)
              window.localStorage.setItem('UserCharId', '12345')
            }
            else {
              window.localStorage.removeItem('WhName')
            }
          }
          else {
            window.localStorage.removeItem('WhName')
          }
        },
        (error) => { }
      );
    // window.localStorage.setItem("UserGroup", this.current_group_number);
  }
  setDefaultGroup(event) {
    try {
      this.access_code = 'GHRX334';
      this.removeerror(event)
    }
    catch (error) { }
  }
  AddUserToSendGrid(email, fname, lname, groupnumber, deeplink_url, event?) {
    this.ApiService.verifyEmail(email,
      fname,
      lname,
      groupnumber,
      deeplink_url
    )
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        if (data.job_id) {
          this.submitCard(event);
          // this.success_group_validate = true;
          // setTimeout(() => {
          //   this.success_group_validate = false;
          //   // this.groupnumber = false;
          // }, 4000);
        }
        else {
          console.log("No result");
          console.log("Data: ", data);
        }
      });
  }
  pharmacynavigate() {
    if (location.pathname != '/enrollmentcenter' && location.pathname != '/guidebook-and-resources') {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      this.router.navigate(["/Pharmacy"], navigationExtras);
    }
    else {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      const url = this.router.serializeUrl(this.router.createUrlTree(['/Pharmacy'], { queryParams: { 'Enrollmentcenter': "ec" } }));
      window.open(url, '_blank');
      // this.router.navigate([], navigationExtras).then(result => { window.open(`/Pharmacy`, '_blank'); });

    }
  }
  clicked() {
    $(window).click();
    $("body").click();
    console.log("clicked");
  }
  setBranchInfo() {
    var self = this;
    let gn = window.localStorage.getItem("UserGroup");
    ; // 123
    if (!!gn) {
      self.group_number = false;
    } else {
      self.group_number = true;
    }
    branch.init('key_live_paMZ6SQ15FL0RpyuUnHxBmphAAh3Ma16', function (err, data) {
      let referral_link = data.data_parsed['~referring_link'];
      let group_no = data.data_parsed.Group_no;
      if (!!group_no) {
        // 123
        console.log("Branch Data Received");
        console.log("Group Number: ", group_no);
        window.localStorage.setItem("UserGroup", group_no);
        self.group_number = false;
        self.clicked();
      } else {
        // 123
        if (!!gn) {
          self.group_number = false;
        } else {
          self.group_number = true;
        }
        self.clicked();
      }

      if (!!referral_link) {
        window.localStorage.setItem("DeepLinkURL", referral_link);
      } else {
        console.log("Undefined Referral Li nk")
      }
    });
  }
  ngOnInit() {
    // let gn = window.localStorage.getItem("UserGroup");
    // if (!!gn) {
    //   this.group_number = false;
    // } else {
    //   this.group_number = true;
    // } 
    // branch.init('key_live_paMZ6SQ15FL0RpyuUnHxBmphAAh3Ma16', function (err, data) {
    //   let referral_link = data.data_parsed['~referring_link'];
    //   let group_no = data.data_parsed.Group_no;
    //   if (!!group_no) {
    //     console.log("Branch Data Received");
    //     console.log("Group Number: ", group_no);
    //     window.localStorage.setItem("UserGroup", group_no);
    //     this.access_code = group_no;
    //     this.group_number = false;
    //   } else {
    //     this.group_number = true;
    //     this.access_code = '';
    //   }

    //   if (!!referral_link) {
    //     window.localStorage.setItem("DeepLinkURL", referral_link);
    //   } else {
    //     console.log("Undefined Referral Li nk")
    //   }
    // });
    this.brandName = this.SaveService.getBrandName();
    this.secondBrandName = this.SaveService.getSecondBrandName();
    this.acceptLogoArray = this.SaveService.getAcceptSectionLogos();
    //------------Check status to open signup popup start here------------
    let Account_assets = this.route.snapshot.queryParamMap.get("account");
    if (this.ms.IsNullOrEmptyField(Account_assets) == false) {
      if (Account_assets.toLowerCase() == "signup") {
        if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
          this.ShowDisplay = false;
          this.ShowDrugdetails = false;
          $(".signupbtnn")[0].click();
          return false;
        } else if (this.rs.GetUserLoggedInStatus() == "groupscreen") {
          this.ShowDisplay = false;
          this.ShowDrugdetails = false;
          this.Group_Number_Box = true;
          $(".TestGroup a")[0].click();
          return false;
        }
      }
    }
    //------------Check status to open signup popup end here------------

    // this.getPosition1().subscribe((pos: Position) => {
    //   this.coordinates = {
    //     latitude: +pos.coords.latitude,
    //     longitude: +pos.coords.longitude,
    //   };
    //   window.localStorage.setItem("_LocalLat", this.coordinates.latitude);
    //   window.localStorage.setItem("_LocalLang", this.coordinates.longitude);
    // });
    $(window).on("click", () => {
      this.ShowDisplay = false;
      this.DisplayBindList = false;
      $(".menu_main").removeClass("st-effect-10");
      this.hideme = [];
    });
    // ie fix
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      $(".headermenu").css({ "padding-top": "11px" });
    }
    if (edge > 0) {
    }

    this.setBranchInfo();
  }
  removemsgerr(e) {
    this.sendmessageStatus = "";
    this.sendmessageClass = "";
    // e = e || window.event;
    // var charCode = typeof e.which == "number" ? e.which : e.keyCode;
    // if (!charCode || charCode == 8 /* Backspace */) {
    //   return;
    // }

    // var typedChar = String.fromCharCode(charCode);

    // // Allow numeric characters
    // if (/\d/.test(typedChar)) {
    //   return;
    // }

    // // Allow the minus sign (+) if the user enters it first
    // if (typedChar == "+" && e.target.value.charAt() == "") {
    //   return;
    // }
    // return false;
    var val_old = e.target.value;
    var val = val_old.replace(/\D/g, "");
    var len = val.length;
    if (len >= 1) {
      val = "(" + val.substring(0);
    }
    if (len >= 3) {
      val = val.substring(0, 4) + ")" + "-" + val.substring(4);
    }
    if (len >= 6) {
      val = val.substring(0, 9) + "-" + val.substring(9);
    }
    if (val != val_old) {
      $(event.target).focus().val("").val(val);
    }
  }

  // ie fix issue
  setHeight(val) {
    var outHeight = $("body").outerHeight();
    var hHome = $(".homepage").outerHeight();
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    if (val == "focusin") {
      if (hHome <= 749) {
        //$(".homepage").css({'height':`${outHeight}px`});
      }
    } else {
      $(".homepage, html, body").removeAttr("style");
    }
    if (
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      edge > 0
    ) {
      // If Internet Explorer, return version number
      $("html").css({ "overflow-y": "hidden" });
      if (val == "focusin") {
        var outHeight = $("body").outerHeight();
        var hHome = $(".homepage").outerHeight();
        if (hHome <= 749) {
          $(".homepage").css({
            height: `${outHeight}px`,
            "overflow-y": "hidden",
          });
          setTimeout(() => {
            $(".serachlist").css({
              "max-height": "205px",
              "min-height": "205px",
            });
          }, 500);
        }
      } else if (val == "focusout") {
        $("html").css({ "overflow-y": "hidden" });
      } else {
        $(".homepage, html, body, .serachlist").removeAttr("style");
      }
    }
  }
  sendmsg(val) {
    console.log(val);
    let mobUsPattern = "^(([0-9]{3}) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$";

    // ;
    if (val != "" && val.length >= 7) {
      this.ApiService.sendSms(val)
        .pipe(
          tap(
            (data) => {
              // ;
            },
            (error) => {
              // ;
              let errorData = (this.sendmessageStatus = JSON.parse(
                error._body
              ));
              if (errorData.code == "21408") {
                this.sendmessageStatus = "Please enter a valid country code";
                this.sendmessageClass = "msgerror";
              } else if (errorData.code == "21211") {
                this.sendmessageStatus =
                  "Please enter a valid phone number";
                this.sendmessageClass = "msgerror";
              } else {
                this.sendmessageStatus = "Try a different number";
                this.sendmessageClass = "msgerror";
              }
            }
          )
        )
        .subscribe((data) => {
          this.sendmessageClass = "Success_sent";
          this.sendmessageStatus = "Message Sent";
          setTimeout(() => {
            this.sendmessageClass = "";
            this.sendmessageStatus = "";
            this.sendno = "";
          }, 6000);
        });
    } else {
      this.sendmessageStatus = "Please enter a valid phone number";
      this.sendmessageClass = "msgerror";
    }

    /*
    var phoneNumber = val // +919812345678, +442071234567
    var linkData = {
      campaign: 'Microsite form Data'
    };
    var linkOptions = {
      make_new_link: false // don't create a new deep link if one already exists (e.g. _branch_match_id is in the address bar)
    };
    (function(b,r,a,n,c,h,_,s,d,k){if(!b[n]||!b[n]._q){for(;s<_.length;)c(h,_[s++]);d=r.createElement(a);d.async=1;d.src="https://cdn.branch.io/branch-latest.min.js";k=r.getElementsByTagName(a)[0];k.parentNode.insertBefore(d,k);b[n]=h}})
    (window,document,"script","branch",function(b,r){b[r]=function(){b._q.push([r,arguments])}},{_q:[],_v:1},"addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(" "), 0);              
      // init Branch
      this.branch.init('key_test_kbVA7KLYjQr3IhziIq9prflaBwjkef5p');
      // document.getElementById("myBtn").addEventListener("click", function(){
      this.branch.sendSMS(val, linkData, linkOptions, function(err, data) {
      console.log(err);
    });
    */
  }
  get isSidebarVisible(): boolean {
    return this.sidebarService.isSidebarVisible;
  }

  clearHeight() {
    setTimeout(() => {
      $(".searchbox_home_parent").css({
        "margin-top": "0",
        transition: "all 0.2s ease",
      });
    }, 600);
  }

  // public getPosition1(): Observable<Position> {
  //   return Observable.create((observer) => {
  //     navigator.geolocation.watchPosition((pos: Position) => {
  //       observer.next(pos);
  //     }),
  //       () => {
  //         console.log("Position is not available");
  //       },
  //     {
  //       enableHighAccuracy: true,
  //     };
  //   });
  // }
  removileview() {
    $(".searchbox_home").removeClass("mobile_searchbox_home");
  }
  AppInitialize() {
    try {
      if (
        this.ms.IsNullOrEmptyField(window.localStorage.getItem("WhiteName")) ==
        true ||
        this.ms.IsNullOrEmptyField(window.localStorage.getItem("WhiteEmail")) ==
        true
      )
        this.ms
          .GetWhiteParams()
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                window.localStorage.setItem("WhiteName", data[0].WhiteName);
                window.localStorage.setItem("WhiteEmail", data[0].WhiteEmail);
                this._AppName = data[0].WhiteName;
                this._AppEmail = data[0].WhiteEmail;
              }
            },
            (error) => { }
          );
    } catch { }
  }

  checkGno_DeeplinkURL() {
    let gn = window.localStorage.getItem("UserGroup");
    let dl = window.localStorage.getItem("DeepLinkURL");

    this.current_group_number = !!gn ? gn : '';
    this.deeplink_url = !!dl ? dl : '';


  }
  submitCard(event) {
    let fName = this.first_name;
    let lName = this.last_name;
    let Email = this.email;
    $(event.target).addClass("loader");
    event.stopImmediatePropagation();
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (
      this.first_name != "" &&
      this.last_name != "" &&
      this.email != "" &&
      this.email.match(mailformat)
    ) {
      this.checkGno_DeeplinkURL();
      this.ApiService.verifyEmailklaviyo(Email, fName, lName, this.current_group_number, this.deeplink_url)
        // .pipe(map((res) => res.json()))
        .subscribe((data) => {
          this.ApiService.getProfileByEmail(Email).subscribe((emaildata) => {

            this.ApiService.updateProfile(Email, fName, lName, this.current_group_number, this.deeplink_url, emaildata['data'][0].id).subscribe((savedata) => {
              window.localStorage.setItem("UserEmail", Email);
              window.localStorage.setItem("FirstName", fName);
              window.localStorage.setItem("LastName", lName);
              this.email = "";
              this.first_name = "";
              this.last_name = "";
              this.cardsuccess = true;
              $(event.target).removeClass("loader");
              this.ms.SaveDeeplink(Email, fName, lName, this.current_group_number.toUpperCase(), this.deeplink_url, "")
                // .pipe(map((res) => res.json()))
                .subscribe((data2) => {
                })
              setTimeout(() => {
                let gn = window.localStorage.getItem("UserGroup");
                this.group_number = !!gn ? false : true;
                this.cardsuccess = false;
              }, 4500);
            });


          })

        })

      // this.ApiService.verifyEmail(this.email,
      //   this.first_name,
      //   this.last_name,
      //   this.current_group_number,
      //   this.deeplink_url
      // )
      //   .pipe(map((res) => res.json()))
      //   .subscribe((data) => {
      //     if (data.job_id) {
      //       this.checkGno_DeeplinkURL();


      //     }
      //     else {
      //       console.log("No result");
      //       console.log("Data: ", data);
      //     }
      //   });
    } else {
      console.log("No result");
      this.email = "";
      this.first_name = "";
      this.last_name = "";
      this.cardsuccess = false;
      $(event.target).removeClass("loader");


      //     this.checkGno_DeeplinkURL();

      //     // Syntax 
      //     // addToMailingList(email, fname, lname, groupnumber, deeplink?) {}
      //     this.ApiService.addToMailingList(
      //       this.email,
      //       this.first_name,
      //       this.last_name,
      //       this.current_group_number,
      //       this.deeplink_url
      //     )
      //       .pipe(map((res) => res.json()))

      //       .subscribe((data) => {
      //         window.localStorage.setItem("UserEmail", this.email);
      //         window.localStorage.setItem("FirstName", this.first_name);
      //         window.localStorage.setItem("LastName", this.last_name);
      //         this.email = "";
      //         this.first_name = "";
      //         this.last_name = "";
      //         this.cardsuccess = true;
      //         $(event.target).removeClass("loader");
      //         setTimeout(() => {
      //           this.cardsuccess = false;
      //         }, 4500);
      //       });
      //   }
      // }
      // );
    }
    if (this.first_name == "") {
      $(event.target).removeClass("loader");
      $("#fnameerror").parent().addClass("error_msg");
      this.errormessageFName = "Please enter your first name";
      this.cardsuccess = false;
    }
    if (this.last_name == "") {
      $(event.target).removeClass("loader");
      $("#lnameerror").parent().addClass("error_msg");
      this.errormessageLName = "Please enter your last name";
      this.cardsuccess = false;
    }
    if (this.email != "" && !this.email.match(mailformat)) {
      $(event.target).removeClass("loader");
      $("#emailerror").parent().addClass("error_msg");
      this.errormessageEmail = "Please enter a valid email address";
      this.cardsuccess = false;
    }
    if (this.email == "") {
      $(event.target).removeClass("loader");
      $("#emailerror").parent().addClass("error_msg");
      this.errormessageEmail = "Please enter a valid email address";
      this.cardsuccess = false;
    }
  }
  removeerror(event) {
    $(event.target).removeClass("loader");
    $(event.target).parent().removeClass("error_msg");
  }
  removeErrorOut() {
    $("#fnameerror,#lnameerror,#emailerror").css({ display: "none" });
    $("#fnameerror,#lnameerror,#emailerror").prev().prev().removeAttr("style");
  }
}
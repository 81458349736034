import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
// import { info } from 'console';
import { map } from "rxjs/operators";
import * as $ from 'jquery';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-drugs-covered-by-the-program',
  templateUrl: './drugs-covered-by-the-program.component.html',
  styleUrls: ['./drugs-covered-by-the-program.component.scss']
})
export class DrugsCoveredByTheProgramComponent implements OnInit {

  details: any;

  GeoBaseURL: string = "https://maps.googleapis.com/maps/api/geocode/json?";
  BaseURL2: string = "https://hdrx.23byt.es/api/v2/";
  BaseURL3: string = "https://hdrx.23byt.es/api/v3/";

  GeoAPIKey = "AIzaSyC46tn0xWqdGs1bD60z4cFakEh510tR3dw";
  loading: Boolean = true;

  constructor(public http: HttpClient) { }

  // GetCoordinatebyzip() {
  //   let headers = new Headers();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(

  //     this.BaseURL3 + "GetDrugList",
  //     "stext=" + "GlicRx",
  //     {
  //       headers: headers
  //     }
  //   );
  // }

  GetCoordinatebyzip(): Observable<any> {
    let formData = {"stext":"GlicRx"}
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "GetDrugList", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }

  ngOnInit() {
    this.GetCoordinatebyzip()
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        this.details = data;
        this.loading = false;
      });
  }

  scroll(id) {
    let el = document.getElementById(id);
    $(".active").removeClass('active');
    (id == "#") ? $('.character.num').addClass('active') : $('.character.' + id).addClass('active');
    el.scrollIntoView();
  }

}

<section class="card-section termsofuse green-theme">
  <div class="comman-width">
    <span class="terms">Privacy Policy</span>
    <div class="repeat">
      <p>
        EFFECTIVE DATE: November 30, 2023
      </p>
      <p>This Web site <a href="https://glicrx.com" rel="noopener" target="_blank">www.glicrx.com</a> and the GlicRx mobile app are owned and operated by Glic Health, LLC, a Delaware Limited Liability Company (“HD,” “we,” “our,” and “us”). This Privacy Policy applies to:</p>
      <ul>
        <li>all aspects of our prescription discount program (the “Glic Program” or the “GlicRx Program”), including;</li>
        <li>any physical card relating to the Glic Program, including any physical manifestation of our digital card (the “Glic Card”);</li>
        <li>our Web site; </li>
        <li>the GlicRx mobile application (the “Glic App”); and</li>
        <li>
          any physical card or software application (whether internet-based, mobile application, or the like) that posts a link to this Privacy Policy, as well as any features, ‎widgets, plug-ins, content, downloads or other services that are offered or such other Glic ‎online service or application (collectively, the “Services”).        </li>
      </ul>
      <p>
        This Privacy Policy will apply regardless of how you access ‎or use the Services, whether it be via physical card, computer, tablet, mobile device, smart watch, console, or otherwise.‎ By using the Services, including without limitation, accessing or using our Site, Glic Program, the Glic App, or the Glic Card, you consent to this Privacy Policy and our <a class="u" [routerLink]="['/terms-and-conditions']" routerLinkActive="active">Terms and Conditions</a>, including our collection, use, and sharing of your information, as described below. If you do not agree to these Terms and Conditions or our Privacy Policy, please exit our Services and do not use the Glic Program. If you have any questions regarding our policies or your use of the Services or any aspect thereof, please contact us at <a class="u" href="mailto:support@glichealth.com">support@glichealth.com</a>.</p>
    </div>
    <div class="repeat">
      <h3>Information We Collect</h3>
      <p>
        We collect information from you in several ways, including when you choose to share information with us by
        entering it through our Services, through the postings that you may make, through healthcare providers, through
        our marketing and data partners, through your pharmaceutical purchases when using the Services, through pharmacy
        benefit managers, and by using automated processes. This information includes information about the drugs
        purchased, such as pricing, the prescribing physician, the location of the pharmacy at which the drugs are
        purchased, and your birthdate among other things.
      </p>
      <h4>Information You Provide to Us</h4>
      <p>
        We may ask you and you may choose to provide certain personal information to us, such as your first and last
        name and e-mail address. We may collect this information in various places, including through our register and
        login pages, “Contact Us” page, or in other parts of our Services.
      </p>
      <h4>Automated Information Collection</h4>
      <p>
        We may automatically collect certain information when you use our Services. This information may include your
        IP address (or other unique device identifier, including one that we may assign); certain details about your
        browser, operating system, and hardware; your location, if available; the URL that referred you to our Services;
        your activities on our Services, including your preferences; and other logging information, such as the date and
        time of your use. We collect information about the prescriptions purchased using our Services and about the
        pharmacy through which they were purchased. We also collect the birthdate of individuals who purchase
        prescriptions through our Services. We may use a variety of tracking technologies to automatically collect
        information, such as cookies, web beacons, embedded scripts, browser fingerprinting, GPS, iBeacons, and ETags
        (or “entity tags”). A cookie (or “locally shared object”) is a data file placed on your device when you visit
        our Web site. Your browser, or other software (including Adobe Flash), may allow you to reject or remove
        cookies, but if you do so, some features of our Web site may not work properly, and we may not be able to
        remember your preferences the next time that you visit. We may also use web server logs to collect and store
        information about usage of our Services. When you use our Services through a mobile device, like a smartphone,
        we may also collect additional information from your device, including your location, device identifier, and
        information about your mobile network.
      </p>
      <h4>User Accounts</h4>
      <p>
        To use certain features of our Services you may need to create a personal username and password. You are
        responsible ‎for any interaction with our Services that takes place through your personal account, regardless of
        whether such use ‎is unauthorized or by a third party. Please keep your username and password secure and do not
        let anyone else ‎use your account information to access our Services.
      </p>
      <h4>Analytics Services and Targeted Ads</h4>
      <p>
        Our Services use third-party analytics services, including, but not necessarily limited to, Google Analytics.
        ‎These services may track details about your online activities over time and across different sites. ‎These
        services help us to improve our Services. You can learn more about Google Analytics and your choices below.
        While we do not serve you targeted advertisements, if you would like to learn more ‎about targeted ads that may
        be based on your online activities (and the choices that you may ‎exercise for certain sites and advertisers)
        you may wish to visit the <a class="u" href="https://www.networkadvertising.org/">
          Network Advertising
          Initiative
        </a> or
        ‎the <a class="u" href="https://digitaladvertisingalliance.org/">Digital Advertising Alliance</a> Web sites. To
        opt
        out of being tracked by Google Analytics across all ‎Web sites, visit:‎
        <a class="u" href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>.
      </p>
    </div>
    <div class="repeat">
      <h3>How We Use the Information That We Collect</h3>
      <p>
        We use the information that we collect from you and others to provide you with the services requested, to
        facilitate your obtaining discount pricing on prescriptions, and to provide you information that may be of
        interest to you, such as services information, newsletters, and special offers. We may also use the information
        that we collect to customize your experience on our Services, to provide customer support, and to enforce our
        Terms and Conditions.
      </p>
    </div>
    <div class="repeat">
      <h3>How and When We Share Information with Third Parties</h3>
      <p>
        We may share the information that we collect with third parties for a variety of purposes. These third parties
        include marketing and data partners, pharmacy benefit managers, and service providers that help support our
        business. For example, we may share information with pharmacy benefit managers and healthcare providers in order
        to advise you of and facilitate your obtaining discount pricing on prescriptions, with our marketing and data
        partners to tailor our services to your preferences and to share relevant information with you, and with service
        providers to support our web platforms, mailing services and other business operations. We may also share
        information where required by law or to satisfy any applicable law, regulation, subpoena, government request, or
        other legal process. We may also share information with third parties, including law enforcement, to protect the
        GlicRx Program, and any of our Services, and to enforce our Terms and Conditions. We also reserve the right
        to
        share
        the information that we collect with our subsidiaries and affiliates and with any subsequent owner in the event
        of a merger, consolidation, sale of our assets, or other change in our business.
      </p>
      <h4>
        Co-Branded Areas
      </h4>
      <p>
        We may provide certain Services in association with third parties, such as promotional partners. These
        “co-branded areas” will identify the third party. If you choose to use these co-branded areas, we may share your
        information with the identified third party, and that third party may also collect information from you, in
        addition to the information that we collect, as described above. You should review the privacy policies of those
        identified third parties to understand how they collect and use information.
      </p>
    </div>
    <div class="repeat">
      <h3>Third-Party Sites & Services</h3>
      <h4>Links to Third-Party Sites</h4>
      <p>
        Our Services may include links to third-party Web sites or other online services. We are not responsible for
        these other Web sites and services, and they may collect and use information about you. You should review the
        privacy policies for such third parties before using their Web sites or services to understand how they collect
        and use information.
      </p>
      <h4>Third-Party Tracking and Do Not Track</h4>
      <p>
        Third parties may use tracking technologies in connection with our Services, which may include the collection
        of information about your online activities over time and across third-party websites. This Privacy Policy does
        not apply to these third-party technologies because we may not control them and we are not responsible for them.
        Do Not Track is a technology that enables users to opt out of tracking by Web sites they do not visit.
        Currently, we do not monitor or take any action with respect to Do Not Track technology.
      </p>
    </div>
    <div class="repeat">
      <h3>Updating Your Information</h3>
      <p>
        Our Services may allow you to update the information that we collect from you, such as the contact information.
        You may also request that we update your information by sending an e-mail to us at <a class="u" href="mailto:support@glichealth.com">support@glichealth.com</a>. Our Services may also allow you to
        update your e-mail preferences.
      </p>
    </div>
    <div class="repeat">
      <h3>How We Protect Your Information</h3>
      <p>
        We use commercially reasonable and industry standard security technologies and safeguards to protect the
        information that we collect and use. We also expect our service providers to protect information in the same
        manner. No data transmission over the Internet, mobile networks, wireless transmission or electronic storage of
        information can, however, be guaranteed 100% secure. Please note that we cannot guarantee the security of any
        information you transmit to us.
      </p>
    </div>
    <div class="repeat">
      <h3>Transfer of Information to the United States</h3>
      <p>
        If you are from a non-U.S. country, please be aware that the personal information you submit, including
        information provided through our Services, is being sent to a location in the United States. The data protection
        laws in the United States may differ from those of the country in which you are located, and your personal
        information may be subject to access requests from governments, courts, or law enforcement in the United States
        according to laws of the United States. By providing such personal information, you are consenting to (and
        represent that you have authority to consent to), the transfer of such information to the United States for the
        uses and purposes described in this Privacy Policy.
      </p>
    </div>
    <div class="repeat">
      <h3>Children’s Privacy</h3>
      <p>
        Our Services are intended for general audiences, and we do not knowingly seek or collect personal information
        from children under the age of 18. In accordance with the Child Online Privacy Protection Act, in the event that
        we learn that we have collected personal information from a child under age 13 without verification of parental
        consent, we will delete that information as quickly as possible. If you believe that we might have any personal
        information from or about a child, please contact us at <a class="u" href="mailto:support@glichealth.com">support@glichealth.com</a>.
      </p>
    </div>
    <div class="repeat">
      <h3>California Privacy Rights</h3>
      <p>If you are a resident of California you have additional rights under California law. Please review ‎our California Consumer Privacy Act Privacy Policy <a href="assets/Glic.Privacy.Policy.for.California.Residents.pdf" target="_blank">here</a> for ‎more information about additional rights under California law.‎</p>
      <p>Residents of California may also, under §1798.83, known as the “Shine The Light” law, request ‎and obtain from us, once a year and free of charge, information about categories of personal ‎‎information (if any) we disclosed to third parties for direct marketing purposes and the names ‎and ‎addresses of all third parties with which we shared personal information in the immediately ‎preceding ‎calendar year. If you are a California resident and would like to make such a request, ‎please submit your ‎request clearly in writing to us using the contact information provided below.</p>
    </div>
    <div class="repeat">
      <h3>Privacy Policy Changes</h3>
      <p>
        We reserve the right to change this Privacy Policy at any time and we will post notice of any such changes on
        our Web site. Your use of Services after the effective date constitutes your acceptance of the amended terms and
        conditions. We reserve the right to apply the amended terms to the information that we have already collected,
        subject to any legal constraints.
      </p>
    </div>
    <div class="repeat">
      <h3>Contact Us</h3>
      <p>
        If you have any questions regarding our Privacy Policy, practices, or your use of our Services, please contact
        us at <a class="u" href="mailto:support@glichealth.com">support@glichealth.com</a> or using the
        information
        below:
      </p>
      <p>
        Glic Health LLC<br>
        One Marina Park Drive<br>
        Suite 1410<br>
        Boston, MA 02210
      </p>
    </div>
  </div>
</section>

import { Component, OnDestroy, OnInit, Input } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
// import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Location } from "@angular/common"; // <--- Here
import { map } from "rxjs/operators";
import { Router, ActivatedRoute, NavigationExtras, Event, NavigationEnd, Event as NavigationEvent } from "@angular/router";
import { Subscription, from } from "rxjs";
import { MedicinService } from "./services/index";
import { PrerequisiteService } from "./services/prerequisite.service";
import { CommonService } from "./services/common.service";
import { } from "googlemaps";
import { GoogleAnalyticsService } from "./services/google-analytics-service.service";
import { Observable } from "rxjs/Observable";
// import { timingSafeEqual } from 'crypto';
import { SaveService } from "./services/save.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ApiService } from "./services/api.service";
import { VersionCheckService } from './services/version-check.service'
import { take } from 'rxjs/operators';


// const branch = window['branch']


import * as $ from 'jquery';
import { environment } from "src/environments/environment";
// import { link } from "fs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
  providers: [
    MedicinService,
    PrerequisiteService,
    CommonService,
    GoogleAnalyticsService,
    SaveService,
    ApiService,
    VersionCheckService
  ],
})
export class AppComponent implements OnInit {
  public deeplink_url: any;
  public success_group_validate: Boolean = false;
  public current_first_name: any;
  public current_last_name: any;
  public current_email: any;
  public incorrect_email: any;
  public incorrect_fullname: any;
  public incorrect_groupnumber: boolean = false;
  public current_group_number: string = "";
  public group_number: Boolean = false;
  public groupnumber: boolean = false;
  public daffiliate: boolean = true;
  public mobsuccess: boolean = false;
  //-------------Top Search Implement Start Here--------------------
  public DrugNameTypeSingle: boolean = false;
  public DrugFormSingle: boolean = false;
  public DrugDoseSingle: boolean = false;
  public ShowDisplay: boolean = false;
  public ShowDrugdetails: boolean = false;
  private SearchText: any;
  public NoDrugsFound: boolean = false;
  public Quantity_error: string = "";
  public Quantity_Manual: string = "";
  public ManualQuantity_box: boolean = false;
  public searchtextbox_error: string = "";
  public Enter_Zip: boolean = false;
  public ShowRecentSearch: boolean = false;
  public SelectedDrug: string = "";
  selectedValue: string;
  optionlist: any[] = [];
  SerachList: any = [];
  DrugDetailsResponseData: any = [];
  DoseDescriptor_JSON: any;
  DrugDetails: any;
  values: any;
  hideme = [];
  coordinates: any = [];
  DrugTypeList: any = [];
  DrugDoseList: any = [];
  DrugQuantityList: any = [];
  _NameFilterForQuantity: any = [];
  DrugDoseDescriptorList: any = [];
  LocationPermission: any = [];
  FormDoseData: any = [];
  ResponseData: any = [];
  PresetDrugData: any = [];
  public DrugConfigList: any = [];
  DrugDoseListCount: any;
  BrandType: boolean = false;
  GenericType: boolean = false;
  IsPresetFound: boolean = false;
  public ShowBrandDetails: boolean = false;
  public ShowGenericDetails: boolean = false;
  public DoseFormSingle: any = false;
  public StrengthSingle: any = false;
  public ShowDetails: boolean = false;
  public DrugName: string = "";
  public DrugCat: string = "";
  public DrugDose: string = "";
  public DrugQuant: any;
  public DrugType: string = "";
  public _IsBack: string = "No";
  public unregisterBackButtonAction: any;
  public Latitude: string = "";
  public Longitude: string = "";
  public Swap_Drug_With_MultiSource: string = "";
  public _UserZip = "";
  public _CurrLocation: string = "";
  public ZipCodeMessage: string = "";
  public IsAutoLocation: string = "N/A";
  //-------------Top Search Impelmet End Here----------
  private email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

  private sign_in_error = "";
  private sign_up_error = "";
  private sign_in_email: string = "";
  private sign_in_password: string = "";
  private sign_in_email_error: string = "";
  private sign_in_password_error: string = "";
  public sign_in_user_email: string = "";
  public signeduseremailbeforeAt: any;

  private sign_up_email: string = "";
  private sign_up_password: string = "";
  private sign_up_cpassword: string = "";
  private sign_up_email_error: string = "";
  private sign_up_password_error: string = "";
  private sign_up_cpassword_error: string = "";

  private current_password: string = "";
  private new_password: string = "";
  private c_new_password: string = "";
  private current_password_error: string = "";
  private new_password_error: string = "";
  private c_new_password_error: string = "";

  private forgot_email: string = "";
  private forgot_email_error: string = "";

  private forgot_new_password: string = "";
  private forgot_new_password_error: string = "";

  public Email_OTP_Input: string = "";
  private input_otp: string = "";
  private input_otp_error: string = "";
  private _CurrentUserId: string = "";
  public change_password_error: string = "";
  public show_password: boolean = false;
  public show_password_msg: string = "Show password";
  public show_cpassword_msg: string = "Show password";
  public show_signin_password_msg: string = "Show password"

  public show_cpassword: boolean = false;
  public show_signin_password: boolean = false;

  private reset_new_password: string = "";
  private reset_c_new_password: string = "";
  private reset_new_password_error: string = "";
  private reset_c_new_password_error: string = "";
  private reset_update_password_error: string = "";
  public loginPage: boolean = true;

  showloginbox = false;
  signup_fields = false;
  signin_fields = false;
  resetpassword_Box = false;
  public isLoggedin: any = false;
  public notLoggedin: any = true;
  public menubar: any = true;
  public _AppName: string = "";
  public _AppEmail: string = "";
  messages: any[] = [];
  subscription: Subscription;

  SearchList: any = [];
  public DisplayBindList: boolean = false;
  changepassword: any = false;
  forgotpassword_box: any = false;
  otp_sent: any = false;
  public Group_Number_Box: boolean = false;
  public InputGroupNumber: string = "";
  public input_groupnumber_error: string = "";
  asideVisible: boolean;
  public logo: any;
  public activepricing: any = false;
  public PopupImage64: any;
  public PopDescText: any;
  public AffiliatePopUp: boolean = false;
  brokerpage: any = false;
  brokerexist: any = false;
  whname: any = [0];
  PicName: any = [0];
  public support_email: string = "";
  public support_phone: any;
  constructor(
    private ms: MedicinService,
    private rs: PrerequisiteService,
    public router: Router,
    private sidebarService: CommonService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private location: Location,
    public SaveService: SaveService,
    public activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    public ApiService: ApiService,
    private versionCheckService: VersionCheckService,
    private activeRoute: ActivatedRoute,
  ) {
    this.AffiliatePopUp = false;
    this.GetUserLoggedInStatus();
    window.localStorage.setItem("WhiteName", "GlicRx");
    this._AppName = window.localStorage.getItem("WhiteName");
    this._AppEmail = window.localStorage.getItem("WhiteEmail");
    this.AppInitialize();
    this.subscription = this.ms.getMessage().subscribe((message) => {
      if (message) {
        this.messages.push(message);
      } else {
        // clear messages when empty message received
        this.messages = [];
      }
    });
    this.sidebarService.sidebarVisibilityChange.subscribe((value) => {
      this.menubar = false;
      this.DisplayBindList = false;
      $(".menu_main").removeClass("st-effect-10");
    });
    this.sidebarService.pharmacytoapp.subscribe((value) => {
      this.menubar = false;
      $(".menu_main").removeClass("st-effect-10");
    });

    let stext = this.location.path().replace("/", "");
    if (stext === "stext") {
    } else {

      // this.Page_Forward(stext);
    }
    router.events
      .subscribe(
        (event: NavigationEvent) => {
          // debugger;
          if (event instanceof NavigationEnd) {
            if (event.url == "/cards/TracFone-GlicRx-Card.pdf") {
              $('.mobileheader_download').hide();
            }

          }

          $(".fnewss").hide();
          $("#newsvalidator").hide();
          $(".newsLetter").removeAttr("style");
          if (window.location.href.indexOf("Pharmacy") > -1) {
            this.activepricing = true;
          } else {
            this.activepricing = false;
          }

        });
    this.subscription = this.router.events.subscribe((event: Event) => {
      // 
      if (event instanceof NavigationEnd) {

        if (event.url.includes('brokerportal')) {
          if (event.url.lastIndexOf('/') != 0) {
            let lastcode = event.url.split("/").pop();
            this.router.navigate(['/enrollmentcenter/' + lastcode]);
          }
          else {
            this.router.navigate(['/enrollmentcenter']);
          }
        }
        if (event.url.lastIndexOf('/') != 0) {

          this.loginPage = false;
          $('body').addClass('loginPage');
          if (window.localStorage.getItem('brokerdetail')) {

            var b = new Date();
            localStorage.b = b;
            let differenceDate = Math.abs(Date.parse(localStorage.b) - Date.parse(localStorage.a)) / 36e5 * 60;
            if (differenceDate > 720) {
              window.localStorage.removeItem('brokerdetail');

              // this.router.navigate(['/']);
            }
            else {

              this.router.navigate(['/enrollmentcenter']);
            }
            setTimeout(() => {

              let whname = JSON.parse(window.localStorage.getItem('brokerdetail'))
              this.whname = whname.brokerDetails[0].name;
            }, 600);
          }

        }
        if (event.url.lastIndexOf('/') == 0) {

          if (event.url == '/guidebook-and-resources') {
            if (window.location !== window.parent.location) {

            }
            else {
              if (window.localStorage.getItem('brokerdetail')) {
                this.brokerpage = true;
                this.loginPage = false;
                this.brokerexist = true;
                setTimeout(() => {
                  let whname = JSON.parse(window.localStorage.getItem('brokerdetail'))
                  this.whname = whname.brokerDetails[0].name;
                  // wholesaler details
                  if (JSON.parse(window.localStorage.getItem("brokerdetail")).wholesalerUserDetails[0].support.length != 0) {
                    this.support_email = JSON.parse(window.localStorage.getItem("brokerdetail")).wholesalerUserDetails[0].support[0].userEmail;
                    this.support_phone = JSON.parse(window.localStorage.getItem("brokerdetail")).wholesalerUserDetails[0].support[0].phoneNumber;
                  }
                  $('body').addClass('broker-pages');
                  let username = this.whname;
                  let Username_Space_Split = username.split(' ');
                  var Username_Hyphen_Split = username.split('-');
                  if (Username_Space_Split.length > 1) {
                    this.PicName = username.split(' ')[0].substring(username.split(' ')[0], 1) + '' + username.split(' ')[1].substring(username.split(' ')[1], 1);
                  }
                  else if (Username_Hyphen_Split.length > 1) {
                    this.PicName = username.split('-')[0].substring(username.split('-')[0], 1) + '' + username.split('-')[1].substring(username.split('-')[1], 1);
                  }
                  else {
                    this.PicName = username.substring(0, 1);
                  }
                }, 600);

              }
              else {

                this.router.navigate(['/']);
              }
            }
          }
        }
        if (event.url.lastIndexOf('/') == 0) {

          if (event.url == '/enrollmentcenter') {

            if (window.localStorage.getItem('brokerdetail')) {
              this.brokerpage = true;
              this.loginPage = false;
              this.brokerexist = true;
              setTimeout(() => {
                let whname = JSON.parse(window.localStorage.getItem('brokerdetail'))
                this.whname = whname.brokerDetails[0].name;
                // wholesaler details
                if (JSON.parse(window.localStorage.getItem("brokerdetail")).wholesalerUserDetails[0].support.length != 0) {
                  this.support_email = JSON.parse(window.localStorage.getItem("brokerdetail")).wholesalerUserDetails[0].support[0].userEmail;
                  this.support_phone = JSON.parse(window.localStorage.getItem("brokerdetail")).wholesalerUserDetails[0].support[0].phoneNumber;
                }
                $('body').addClass('broker-pages');
                let username = this.whname;
                let Username_Space_Split = username.split(' ');
                var Username_Hyphen_Split = username.split('-');
                if (Username_Space_Split.length > 1) {
                  this.PicName = username.split(' ')[0].substring(username.split(' ')[0], 1) + '' + username.split(' ')[1].substring(username.split(' ')[1], 1);
                }
                else if (Username_Hyphen_Split.length > 1) {
                  this.PicName = username.split('-')[0].substring(username.split('-')[0], 1) + '' + username.split('-')[1].substring(username.split('-')[1], 1);
                }
                else {
                  this.PicName = username.substring(0, 1);
                }
              }, 600);


            }
            else {

              this.router.navigate(['/']);
            }
          }
        }
        if (event.url.lastIndexOf('/') == 0) {
          if (event.url == '/Pharmacy') {
            if (window.localStorage.getItem('brokerdetail')) {
              if (this.brokerexist == true) {
                this.brokerpage = true;
                this.loginPage = false;
                this.brokerexist = true;
                setTimeout(() => {
                  let whname = JSON.parse(window.localStorage.getItem('brokerdetail'))
                  this.whname = whname.brokerDetails[0].name;
                  $('body').addClass('broker-pages');
                  let username = this.whname;
                  let Username_Space_Split = username.split(' ');
                  var Username_Hyphen_Split = username.split('-');
                  if (Username_Space_Split.length > 1) {
                    this.PicName = username.split(' ')[0].substring(username.split(' ')[0], 1) + '' + username.split(' ')[1].substring(username.split(' ')[1], 1);
                  }
                  else if (Username_Hyphen_Split.length > 1) {
                    this.PicName = username.split('-')[0].substring(username.split('-')[0], 1) + '' + username.split('-')[1].substring(username.split('-')[1], 1);
                  }
                  else {
                    this.PicName = username.substring(0, 1);
                  }
                }, 600);

              }
              else {
                this.brokerpage = false;
                this.loginPage = true;
                this.brokerexist = false;
              }
            }

          }
        }
        if (event.url.lastIndexOf('/') == 0) {
          if (event.url == '/contact#faq') {
            if (window.localStorage.getItem('brokerdetail')) {
              if (this.brokerexist == true) {
                this.brokerpage = true;
                this.loginPage = false;
                this.brokerexist = true;
                setTimeout(() => {
                  let whname = JSON.parse(window.localStorage.getItem('brokerdetail'))
                  this.whname = whname.brokerDetails[0].name;
                  $('body').addClass('broker-pages');
                  let username = this.whname;
                  let Username_Space_Split = username.split(' ');
                  var Username_Hyphen_Split = username.split('-');
                  if (Username_Space_Split.length > 1) {
                    this.PicName = username.split(' ')[0].substring(username.split(' ')[0], 1) + '' + username.split(' ')[1].substring(username.split(' ')[1], 1);
                  }
                  else if (Username_Hyphen_Split.length > 1) {
                    this.PicName = username.split('-')[0].substring(username.split('-')[0], 1) + '' + username.split('-')[1].substring(username.split('-')[1], 1);
                  }
                  else {
                    this.PicName = username.substring(0, 1);
                  }
                }, 600);

              }
              else {
                this.brokerpage = false;
                this.loginPage = true;
                this.brokerexist = false;
              }
            }

          }
        }
        if (event.url.includes('printmycard')) {
          $('.header-section').hide();
          $('.footer-section').hide();
        }
        if (event.url.includes('recoverAccessCode')) {
          $('.loginh').hide();
          $('.footer-section').hide();
        }
      }

    });
    this.activeRoute.queryParams.subscribe((qp) => {
      if (qp.GroupNo != undefined) {
        // debugger;
        // this.showfullcard = true;
        let link = window.location.host + "/?GroupNo=" + qp.GroupNo;
        window.localStorage.setItem("UserGroup", qp.GroupNo);

        this.ApiService.savecustomqueryparams(link, qp.GroupNo)
          //  .pipe(map((res) => res.json()))
          .subscribe((data) => {
          })
        //this.Group_Number = window.localStorage.getItem("UserGroup");
      }
      if (qp.fpage == "pharmacy") {
        // this.LoadPharmacyList();
        setTimeout(() => {
          // debugger;
          // let abc = location.href;
          // abc = abc.split("&")[0];
          // history.pushState({}, null, abc);
        }, 600);
      } else {
        // debugger;
        // this.showfullcard = false;
        //this.Group_Number = window.localStorage.getItem("UserGroup");
      }
    });
  }
  get apptoHome(): boolean {
    return this.sidebarService.apptoHome;
  }
  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
  }
  removeGroupNumber() {
    this.groupnumber = false;
  }

  AddUserToSendGrid(email, fname, lname, groupnumber, deeplink_url) {

    this.ApiService.verifyEmailklaviyo(email, fname, lname, groupnumber, deeplink_url)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        window.localStorage.setItem("UserEmail", email);
        window.localStorage.setItem("FirstName", fname);
        window.localStorage.setItem("LastName", lname);
        $(event.target).removeClass("loader");
        this.ApiService.getProfileByEmail(email).subscribe((emaildata) => {

          this.ApiService.updateProfile(email, fname, lname, this.current_group_number, this.deeplink_url, emaildata['data'][0].id).subscribe((savedata) => {
            this.ms.SaveDeeplink(this.current_email, this.current_first_name, this.current_last_name, this.current_group_number.toUpperCase(), this.deeplink_url, '')
              // .pipe(map((res) => res.json()))
              .subscribe((data2) => {
              })
            setTimeout(() => {
              let gn = window.localStorage.getItem("UserGroup");
              this.group_number = !!gn ? false : true;
            }, 4500);

            this.success_group_validate = true;
            setTimeout(() => {
              this.success_group_validate = false;
              this.groupnumber = false;
              this.current_group_number = this.current_email = this.current_first_name = this.current_last_name = null;
            }, 3000);
          });


        })






      })

    // this.ApiService.verifyEmail(email,
    //   fname,
    //   lname,
    //   groupnumber,
    //   deeplink_url
    // )
    //   .pipe(map((res) => res.json()))
    //   .subscribe((data) => {

    //     if (data.job_id) {

    //         window.localStorage.setItem("UserEmail", email);
    //         window.localStorage.setItem("FirstName", fname);
    //         window.localStorage.setItem("LastName", lname);
    //         $(event.target).removeClass("loader");
    //         this.ms.SaveDeeplink(this.current_email, this.current_first_name, this.current_last_name, this.current_group_number.toUpperCase(), this.deeplink_url, '')
    // .pipe(map((res) => res.json()))
    //             .subscribe((data2) => {
    //             })
    //           setTimeout(() => {
    //             let gn = window.localStorage.getItem("UserGroup");
    //             this.group_number = !!gn ? false : true;
    //           }, 4500);

    //       this.success_group_validate = true;
    //       setTimeout(() => {
    //         this.success_group_validate = false;
    //         this.groupnumber = false;
    //         this.current_group_number = this.current_email = this.current_first_name = this.current_last_name = null;
    //       }, 3000);
    //     }
    //     else {
    //       console.log("No result");
    //       console.log("Data: ", data);
    //     }
    //   });
  }
  sidebarToggle() {
    try {
      $(".sidebar").animate({
        left: 0 + 'px'
      }, 1000);
      $("body").addClass('no-scroll');
    }
    catch (error) { }
  }
  closeSidebar() {
    try {
      $(".sidebar").animate({
        left: -340 + 'px'
      }, 1000);
      $("body").addClass('no-scroll');
    }
    catch (error) { }
  }
  setGroupNumber() {
    this.incorrect_email =
      this.incorrect_groupnumber =
      this.incorrect_fullname = false;

    if (!!this.current_first_name && !!this.current_last_name) {
      //
    } else {
      this.incorrect_fullname = true;
      return 0;
    }

    if (!!this.current_email) {
      this.email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.email_REGEX.test(this.current_email) == false) {
        this.incorrect_email = true;
        return 0;
      }
    } else {
      this.incorrect_email = true;
      return 0;
    }

    this.ms
      .GNValidate(this.current_group_number)
      // .pipe(map((res) => res.json()))
      .subscribe(
        (data) => {
          if (data.length > 0) {
            if (!data[0].GroupNumber[0].Status) {
              console.log("Incorrect group number");
              this.incorrect_groupnumber = true;
              // this.input_groupnumber_error = "* " + data[0].StatusMessage;
              // $("#InputGroup").parent().addClass("error_msg");
            } else if (data[0].GroupNumber[0].Status) {
              this.incorrect_email = this.incorrect_fullname = this.incorrect_groupnumber = false;
              window.localStorage.setItem("UserGroup", data[0].GroupNumber[0].GroupNumber.toUpperCase());
              window.localStorage.setItem("UserEmail", this.current_email);
              window.localStorage.setItem("FirstName", this.current_first_name);
              window.localStorage.setItem("LastName", this.current_last_name);
              // this.ms.sendGrid(this.current_first_name, this.current_last_name, this.current_email, this.current_group_number, this.deeplink_url);
              if (data[0].Details.length > 0) {
                if (data[0].Details[0].SandGridListId) {
                  window.localStorage.setItem('SandGridListId', data[0].Details[0].SandGridListId);
                }
              }

              this.checkGno_DeeplinkURL();
              try {
                this.AddUserToSendGrid(this.current_email, this.current_first_name, this.current_last_name, this.current_group_number, this.deeplink_url);



              } catch (error) {
                console.log("Incorrect gno");
                this.incorrect_email = true;
                this.input_groupnumber_error = "* Invalid Group Number";

              }
              // this.success_group_validate = true;
              // setTimeout(() => {
              //   this.success_group_validate = false;
              //   this.groupnumber = false;
              // }, 4000);
            }
          } else {
            console.log("Incorrect gno");

            this.input_groupnumber_error = "* Invalid Group Number";
            // window.localStorage.setItem("UserGroup", "False");
            // $("#InputGroup").parent().addClass("error_msg");
          }
          if (data[0].Wholesaer != null) {
            if (data[0].Wholesaer[0].WhName == "Avenel") {
              window.localStorage.setItem('WhName', data[0].Wholesaer[0].WhName)
              window.localStorage.setItem('UserCharId', '12345')
              let cardindex = window.localStorage.getItem('cardIndex');
              $('.card').eq(parseInt(cardindex)).click();
            }
            else {
              window.localStorage.removeItem('WhName')
            }
          }
          else {
            window.localStorage.removeItem('WhName')
          }
        },
        (error) => { }
      );
    // window.localStorage.setItem("UserGroup", this.current_group_number);
  }
  setDefaultGroup() {
    try {
      this.current_group_number = 'GHRX334';
    }
    catch (error) { }
  }
  checkGno_DeeplinkURL() {
    let gn = window.localStorage.getItem("UserGroup");
    let dl = window.localStorage.getItem("DeepLinkURL");

    this.current_group_number = !!gn ? gn : '';
    this.deeplink_url = !!dl ? dl : '';

    // this.ms.SaveDeeplink(this.current_email, this.current_first_name, this.current_last_name, this.current_group_number.toUpperCase(), this.deeplink_url).pipe(map((res) => res.json()))
    //   .subscribe((data2) => {
    //     
    //   })
  }
  pharmacynavigate() {
    if (location.pathname != '/enrollmentcenter' && location.pathname != '/guidebook-and-resources') {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      this.router.navigate(["/Pharmacy"], navigationExtras);
    }
    else {
      const navigationExtras: NavigationExtras = {
        state: {
          Page: "Fromheader",
        },
      };
      const url = this.router.serializeUrl(this.router.createUrlTree(['/Pharmacy'], { queryParams: { 'Enrollmentcenter': "ec" } }));
      window.open(url, '_blank');
      // this.router.navigate([], navigationExtras).then(result => { window.open(`/Pharmacy`, '_blank'); });

    }
  }
  checkGroupNumberState() {
    if (!!window.localStorage.getItem("UserGroup")) {
      return true;
    } else {
      return false;
    }
  }
  ngOnInit() {
    this.versionCheckService.initVersionCheck(environment.versionCheckURL);
    if (window.location !== window.parent.location) {
      setTimeout(() => {
        $('header').addClass('hideheaderfooter');
        $('.mobileheader_download').addClass('hideheaderfooter');
        $('.footer-section').addClass('hideheaderfooter');
        $('.marketing-section').addClass('marketing-section_frame');
        $('.monitor-tag').addClass('hideheaderfooter');
        $('.marketing-section_frame').addClass('frameresource');
      }, 600);
    } else {
      setTimeout(() => {
        $('header').removeClass('hideheaderfooter');
        $('.mobileheader_download').removeClass('hideheaderfooter');
        $('.footer-section').removeClass('hideheaderfooter');
        $('.marketing-section').removeClass('marketing-section_frame');
        $('.monitor-tag').removeClass('hideheaderfooter');
        $('.marketing-section_frame').removeClass('frameresource');
      }, 600);
    }
    let gn = window.localStorage.getItem("UserGroup");
    this.group_number = !!gn ? false : true;

    // Branch.io - DeepLinks
    // branch.init('key_live_paMZ6SQ15FL0RpyuUnHxBmphAAh3Ma16', function (err, data) {
    //   let referral_link = data.data_parsed['~referring_link'];
    //   let group_no = data.data_parsed.Group_no;
    //   if (!!group_no) {
    //     console.log("Branch Data Received");
    //     console.log("Group Number: ", group_no);
    //     window.localStorage.setItem("UserGroup", group_no);
    //   } else {
    //     console.log("Undefined Group Number")
    //   }

    //   if (!!referral_link) {
    //     window.localStorage.setItem("DeepLinkURL", referral_link);
    //   } else {
    //     console.log("Undefined Referral Link")
    //   }
    // });
    // this.checkGroupNumberState();
    let currentURL = location.href;
    if (currentURL.includes("_branch_match_id")) {
      setTimeout(() => {
        $('html, body').animate({ scrollTop: $('#form-section').offset().top }, 500)
      }, 800);
    }
    try {
      if (window.location.pathname === "/domain-affiliate") {
        this.daffiliate = false;
      }
    } catch { }
    try {
      $("#newsvalidator").text("").prev().removeAttr("syle");
    } catch { }
    try {
      $("#newsvalidator").text("").prev().removeAttr("syle");
    } catch { }

    $(".navbar-nav a").click(function () {
      $(".navbar-nav a").removeClass("home-active");
    });
    var path = window.location.pathname.split("/").pop();
    if (path == "") {
      $(".navbar-nav li:first-child a").addClass("home-active");
    }
    this.logo = this.SaveService.getLogo();
    try {
      this.logo = this.SaveService.getLogo();
      //       let googleMapsScript = document.createElement("script");
      //       googleMapsScript.setAttribute(
      //         "src",
      //         "https://maps.googleapis.com/maps/api/js?key=AIzaSyAuNL17CjdSdiDc3fQ46T6Sy2oB2UPuLB0"
      //       );
      //       document.head.appendChild(googleMapsScript);
    } catch { }
    this.lastLoginStatus();
    try {
      $(".navbar-nav").removeClass("pad0");
      $(".navbar-nav").addClass("pad25");
      this.menubar = false;
      $(".navbar-nav").css({});
      $(".menu_main").css({ display: "none" });
    } catch { }
    try {
      let status = window.localStorage.getItem("SkipSignup");
      if (status == "loggedin") {
        this.isLoggedin = true;
        this.sign_in_user_email = window.localStorage.getItem("UserEmail");
        this.signeduseremailbeforeAt = window.localStorage.getItem("UserEmail");
        this.signeduseremailbeforeAt = this.signeduseremailbeforeAt.slice(
          0,
          this.signeduseremailbeforeAt.indexOf("@")
        );
        this.notLoggedin = false;
      } else {
        this.isLoggedin = false;
        this.notLoggedin = true;
      }
    } catch { }
    // return this.subscription;
    // signin_fields
    // signup_fields
    $(document).on("click", ".userArea", function () {
      $(".sortby").click();
      $(".sortby").click();
    });
    $(document).on("click", ".input_box > div i", function () {
      if ($(this).parent().find("input").attr("type") == "password") {
        $(this).parent().find("input").attr("type", "text");
      } else {
        $(this).parent().find("input").attr("type", "password");
      }
    });
    $(window).on("click", () => {
      this.menubar = false;
      this.ShowDisplay = false;
      this.DisplayBindList = false;
      $(".menu_main").removeClass("st-effect-10");
      this.hideme = [];
    });
    $(document).on("click", "header", function () {
      $(".type_list").hide();
    });
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    // For routes

    var self = this;
    $(document).on("click", ".CheckLoginState", function () {
      console.log("click");
      self.getLoginState();
      return 0;
    });
    setTimeout(() => {
      console.clear()
    }, 1500);
  }

  getLoginState() {
    let gn = window.localStorage.getItem("UserGroup");
    this.group_number = !!gn ? false : true;

    this.incorrect_email =
      this.incorrect_groupnumber =
      this.incorrect_fullname = false;

    var user_email = window.localStorage.getItem("UserEmail");
    var group_number = window.localStorage.getItem("UserGroup");
    var first_name = window.localStorage.getItem("FirstName");
    var last_name = window.localStorage.getItem("LastName");
    // var skip_signup = window.localStorage.getItem("SkipSignup");

    if (!!user_email) {
      this.current_email = user_email;
    }

    if (!!first_name) {
      this.current_first_name = first_name;
    }

    if (!!last_name) {
      this.current_last_name = last_name;
    }
    if (!!group_number) {
      this.current_group_number = group_number;
    }

    if (!!group_number && !!user_email && !!first_name && !!last_name) {
      // If Group Number, Email, First/Last name exists, Do nothing
    } else {
      this.groupnumber = true;
    }
  }


  Page_Forward(stext: string) {
    if (stext != "") {
      this.ms
        .GetDomainAffiliate(stext)
        // .pipe(map((res) => res.json()))
        .subscribe((data) => {
          if (data.length > 0) {
            window.localStorage.setItem("UserGroup", data[0].group);
            window.localStorage.setItem("URLpcn", data[0].pcn);
            if (window.localStorage.getItem("AffiliatePopUpShown") != "Yes") {
              if (data[0].image64) {
                try {
                  ;
                  this.PopupImage64 = data[0].image64;
                  this.PopDescText = data[0].desc;
                  this.AffiliatePopUp = true;
                  window.localStorage.setItem("AffiliatePopUpShown", "Yes");
                } catch { }
              }
            }
          } else {
          }
        });
    }
    //if(apptext.toLowerCase()=="atest"){
    //this.router.navigate(["/domain"],{ state: { GN: 'HDRX5389',PCN:'' } });
    //}
  }
  imagetransform() {
    try {
      ;
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/png;base64, " + this.PopupImage64.replace(" ", "+")
      );
    } catch { }
  }
  Close_Affiliate() {
    this.AffiliatePopUp = false;
  }
  sl;
  slideToggle() {
    try {
      this.humbergMenu();
    } catch { }
  }
  humbergMenu() {
    if ($(window).width() <= 1024) {
      $(".mMenu").fadeToggle("slow");
      this.mobsuccess = false;
      // $('.mMenu').toggle('slow').animate({left:'0'});
      $(".sidebar").animate({
        left: -340 + 'px'
      }, 1000);
      $("body").addClass('no-scroll');
    }
  }
  nagigatetodownload() {
    this.router.navigate(["/download-mobile-app"]);
  }
  //check login status last time
  lastLoginStatus() {
    let getUserEmail = window.localStorage.getItem("UserEmail");
    let getUserId = window.localStorage.getItem("UserId");
    try {
      if (
        this.ms.IsNullOrEmptyField(getUserEmail) == false &&
        this.ms.IsNullOrEmptyField(getUserId) == false
      ) {
        this.ms
          .lastLoginStatus(getUserId, getUserEmail)
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                window.localStorage.setItem("UserId", data[0].UserId);
                window.localStorage.setItem("UserCharId", data[0].UserMemberId);
                let DAGroup = window.localStorage.getItem("UserGroup");
                if (!DAGroup) {
                  window.localStorage.setItem("UserGroup", DAGroup);
                }
                window.localStorage.setItem("UserEmail", data[0].UserEmail);
                window.localStorage.setItem("SkipSignup", "loggedin");
                $(".close_search_pharmacy_popup").click();
                this.isLoggedin = true;
                this.notLoggedin = false;
                setTimeout(() => {
                  $(".homepage").addClass("loggedin_header");
                  $(".pharmacyPage").addClass("loggedin_header");
                }, 300);
                this.menubar = false;
                this.sign_in_user_email = window.localStorage.getItem(
                  "UserEmail"
                );
                this.signeduseremailbeforeAt = window.localStorage.getItem(
                  "UserEmail"
                );
                this.signeduseremailbeforeAt = this.signeduseremailbeforeAt.slice(
                  0,
                  this.signeduseremailbeforeAt.indexOf("@")
                );
              } else {
                this.menubar = false;
                this.router.navigate(["/"]);
              }
            },
            (error) => { }
          );
      } else {
      }
    } catch { }
  }

  hidemenubar() {
    this.menubar = !this.menubar;
    $(".type_list").hide();
    if ($(window).width() <= 768) {
      if (this.menubar) {
        $(".menu_main").addClass("st-effect-10");
      } else {
        $(".menu_main").removeClass("st-effect-10");
      }
    } else {
      $(".menu_main").removeClass("st-effect-10");
    }
    this.sidebarService.toggleapptohome();
  }

  getNotification(evt) {
    // Do something with the notification (evt) sent by the child!
  }

  openlogin_signup(event, val) {
    this.removeStyle(val);
    this.ClearErrorMessage();
    this.ms.Getsignup();
    if (val == "sign_up") {
      this.ms.Setsignup(true, true, false);
      this.showloginbox = this.ms.Getsignup()[0];
      this.signup_fields = this.ms.Getsignup()[1];
      this.signin_fields = this.ms.Getsignup()[2];
      $("body").css("overflow", "hidden");
    } else if (val == "my_cards") {
      if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
        this.ms.Setsignup(true, true, false);
        this.showloginbox = this.ms.Getsignup()[0];
        this.signup_fields = this.ms.Getsignup()[1];
        this.signin_fields = this.ms.Getsignup()[2];
        $("body").css("overflow", "hidden");
        return false;
      }
      if (
        window.localStorage.getItem("UserGroup") == null ||
        window.localStorage.getItem("UserGroup") == "False" ||
        window.localStorage.getItem("UserGroup") == "null" ||
        window.localStorage.getItem("UserGroup") == ""
      ) {
        this.Group_Number_Box = true;
        // $(".TestGroup a")[0].click();
        return false;
      } else {
        this.router.navigate(["/MyCards"]);
      }
    } else if (val == "groupnumber") {
      this.Group_Number_Box = true;
      $("body").css("overflow", "hidden");
    } else {
      this.ms.Setsignup(true, false, true);
      this.showloginbox = this.ms.Getsignup()[0];
      this.signup_fields = this.ms.Getsignup()[1];
      this.signin_fields = this.ms.Getsignup()[2];
      $("body").css("overflow", "hidden");
    }
    // ie fix issue
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    var ff = ua.indexOf("Firefox");
    if (
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      edge > 0 ||
      ff > 0
    ) {
      // If Internet Explorer, return version number
      if (val == "my_cards" || val == "home_screen") {
        $(".ie_fixed_add_n, #Signin_Signup_Header").removeAttr("style");
      }
    }
  }
  removesignup(event) {
    this.ms.Setsignup(false, false, false);
    this.showloginbox = this.ms.Getsignup()[0];
    this.signup_fields = this.ms.Getsignup()[1];
    this.signin_fields = this.ms.Getsignup()[2];
    $("body").removeAttr("style");
  }
  ClearErrorMessage() {
    this.sign_in_email_error = "";
    this.sign_in_password_error = "";
    this.sign_up_email_error = "";
    this.sign_up_password_error = "";
    this.sign_up_cpassword_error = "";
    this.sign_in_email = "";
    this.sign_in_password = "";
    this.sign_up_email = "";
    this.sign_up_password = "";
    this.sign_up_cpassword = "";
    this.input_groupnumber_error = "";
    this.current_password_error = "";
    this.new_password_error = "";
    this.c_new_password_error = "";
    this.sign_in_error = null;
    this.sign_up_error = "";
  }
  ClearErrorMessage_InputGroup() {
    this.input_groupnumber_error = "";
    $("#InputGroup").parent().removeClass("error_msg");
  }

  Keyup_ClearField(event: any) {
    this.incorrect_email = this.incorrect_fullname = this.incorrect_groupnumber = false;
    this.sign_up_error = null;
    if (event.target.name == "email_signin") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
        this.sign_in_email_error = "";
        $("#email_signin")
          .parent()
          .removeClass("error_msg");
      } else {
        this.sign_in_email_error = "Email Id is required";
        $("#email_signin")
          .parent()
          .addClass("error_msg");
      }
    } else if (event.target.name == "email_signup") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
        this.sign_up_email_error = "";
        $("#email_signup")
          .parent()
          .removeClass("error_msg");
      } else {
        this.sign_up_email_error = "Email Id is required";
        $("#email_signup")
          .parent()
          .addClass("error_msg");
      }
    } else if (event.target.name == "password_signin")
      if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
        this.sign_in_password_error = "";
        $("#password_signin")
          .parent()
          .removeClass("error_msg");
      }
      // else {
      //   this.sign_in_password_error = "Password is required";
      //   $("#password_signin")
      //     .parent()
      //     .addClass("error_msg");
      // }
      else if (event.target.name == "password_signup") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.sign_up_password_error = "";
          $("#password_signup")
            .parent()
            .removeClass("error_msg");
        } else {
          // this.sign_up_password_error = "Password is required";
          // $("#password_signup")
          //   .parent()
          //   .addClass("error_msg");
        }
        // if (this.ms.IsNullOrEmptyField(this.sign_up_cpassword) == false)
        //   this.sign_up_cpassword_error = "";
      } else if (event.target.name == "c_password_signup") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.sign_up_cpassword_error = "";
          $("#c_password_signup")
            .parent()
            .removeClass("error_msg");
        } else {
          // this.sign_up_cpassword_error = "Confirm password is required";
          // $("#c_password_signup")
          //   .parent()
          //   .addClass("error_msg");
        }
        // if (this.ms.IsNullOrEmptyField(this.sign_up_password) == false)
        //   this.sign_up_password_error = "";
      } else if (event.target.name == "InputGroup") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.input_groupnumber_error = "";
          $("#InputGroup")
            .parent()
            .removeClass("error_msg");
        } else {
          this.input_groupnumber_error = "Group number is required";
          $("#InputGroup")
            .parent()
            .addClass("error_msg");
        }
      } else if (event.target.name == "currentpass") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.current_password_error = "";
          $("#currentpass")
            .parent()
            .removeClass("error_msg");
        } else {
          this.current_password_error = "Current password is required";
          $("#currentpass")
            .parent()
            .addClass("error_msg");
        }
      } else if (event.target.name == "newpass") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.new_password_error = "";
          $("#newpass")
            .parent()
            .removeClass("error_msg");
        } else {
          this.new_password_error = "New password is required";
          $("#newpass")
            .parent()
            .addClass("error_msg");
        }
      } else if (event.target.name == "confirmpass") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.c_new_password_error = "";
          $("#confirmpass")
            .parent()
            .removeClass("error_msg");
        } else {
          this.c_new_password_error = "Confirm password is required";
          $("#confirmpass")
            .parent()
            .addClass("error_msg");
        }
      } else if (event.target.name == "forgotemail") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.forgot_email_error = "";
          $("#forgotemail")
            .parent()
            .parent()
            .removeClass("error_msg");
        }
        // else {
        //   $("#forgotemail")
        //     .parent()
        //     .parent()
        //     .addClass("error_msg");
        //   this.forgot_email_error = "Email Id is required";
        // }
      } else if (event.target.name == "OTP") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.input_otp_error = "";
          $("#OTP")
            .parent()
            .parent()
            .removeClass("error_msg");
        }
        // else {
        //   this.input_otp_error = "6 digit code is required";
        //   $("#OTP")
        //     .parent()
        //     .addClass("error_msg");
        // }
      } else if (event.target.name == "resetnewpass") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.reset_new_password_error = "";
          $("#resetnewpass")
            .parent()
            .removeClass("error_msg");
        } else {
          this.reset_new_password_error = "New password is required";
          $("#resetnewpass")
            .parent()
            .addClass("error_msg");
        }
      } else if (event.target.name == "resetconfirmpass") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == false) {
          this.reset_c_new_password_error = "";
          $("#resetconfirmpass")
            .parent()
            .removeClass("error_msg");
        } else {
          this.reset_c_new_password_error = "Confirm new password is required";
          $("#resetconfirmpass")
            .parent()
            .addClass("error_msg");
        }
      }
  }
  OnBlur_CheckPattern(event: any) {
    this.sign_up_error = null;
    this.email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (this.email_REGEX.test(event.target.value) == false) {
      if (event.target.name == "email_signin") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
          this.sign_in_email_error = "Email Id is required";
          $("#email_signin")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        }
        else if (event.target.value.indexOf(' ') > -1) {
          this.sign_in_email_error = "Please enter a valid email address";
          $("#email_signin")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        }
        else {
          this.sign_in_email_error = "Please enter a valid email address";
          $("#email_signin")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        }
      } else if (event.target.name == "email_signup") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
          this.sign_up_email_error = "Email Id is required";
          $("#email_signup")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        } else {
          this.sign_up_email_error = "Please enter a valid email address";
          $("#email_signup")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        }
      } else if (event.target.name == "password_signin") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
          this.sign_in_password_error = "Password is required";
          $("#password_signin")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        } else {
          this.sign_in_password_error = "";
          $("#password_signin")
            .parent()
            .removeClass("error_msg");
        }
      } else if (event.target.name == "password_signup") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
          this.sign_up_password_error = "Password is required";
          $("#password_signup")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        } else if (event.target.value.length < 6) {
          this.sign_up_password_error = "Minimum password length must be 6";
          $("#password_signup")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        } else {
          this.sign_up_password_error = "";
          $("#password_signup")
            .parent()
            .removeClass("error_msg");
        }
      } else if (event.target.name == "c_password_signup") {
        if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
          this.sign_up_cpassword_error = "Confirm Password is required";
          $("#c_password_signup")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        } else if (event.target.value.length < 6) {
          this.sign_up_cpassword_error =
            "Minimum confirm password length must be 6";
          $("#c_password_signup")
            .parent()
            .removeClass("error_msg")
            .addClass("error_msg");
        } else {
          this.sign_up_cpassword_error = "";
          $("#c_password_signup")
            .parent()
            .removeClass("error_msg");
        }
      }
    }
  }
  MakeSignIn() {
    let ValidField = 0;
    this.email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (this.ms.IsNullOrEmptyField(this.sign_in_email) == true) {
      this.sign_in_email_error = "Email Id is required";
      $("#email_signin").parent().addClass("error_msg");
    } else {
      if (this.email_REGEX.test(this.sign_in_email) == false) {
        this.sign_in_email_error = "Please enter correct email address";
      } else {
        this.sign_in_email_error = "";
        ValidField = ValidField + 1;
      }
    }
    if (this.ms.IsNullOrEmptyField(this.sign_in_password) == true) {
      this.sign_in_password_error = "Password is required";
      $("#password_signin").parent().addClass("error_msg");
    } else {
      this.sign_in_password_error = "";
      ValidField = ValidField + 1;
    }
    if (ValidField >= 2) {
      this.googleAnalyticsService.eventEmitter(
        "Account Page",
        "Login",
        this.sign_in_email,
        0
      );
      this.ms
        .LoginAccount(this.sign_in_email, this.sign_in_password)
        // .pipe(map((res) => res.json()))
        .subscribe(
          (data) => {
            if (data.length > 0) {
              if (data[0].Success == false) {
                this.sign_in_error = data[0].StatusMessage;
              } else {
                if (this.ms.IsNullOrEmptyField(data[0].UserGroup) == true) {
                  window.localStorage.setItem("UserEmail", this.sign_in_email);
                  window.localStorage.setItem("UserId", data[0].UserId);
                  window.localStorage.setItem("UserCharId", data[0].UserCharId);
                  this.Group_Number_Box = true;
                  return false;
                }
                window.localStorage.setItem("UserId", data[0].UserId);
                window.localStorage.setItem("UserCharId", data[0].UserCharId);
                this.ms.PushCardList();
                let DAGroup = window.localStorage.getItem("UserGroup");
                if (!DAGroup) {
                  window.localStorage.setItem("UserGroup", DAGroup);
                }
                //window.localStorage.setItem("UserGroup", data[0].UserGroup);
                window.localStorage.setItem("SkipSignup", "loggedin");
                window.localStorage.setItem("UserEmail", this.sign_in_email);
                this.sign_in_error = "";
                $(".close_search_pharmacy_popup").click();
                this.isLoggedin = true;
                this.notLoggedin = false;
                setTimeout(() => {
                  $(".homepage").addClass("loggedin_header");
                  $(".pharmacyPage").addClass("loggedin_header");
                }, 600);
                this.menubar = false;
                this.sign_in_user_email = window.localStorage.getItem(
                  "UserEmail"
                );
                this.signeduseremailbeforeAt = window.localStorage.getItem(
                  "UserEmail"
                );
                this.signeduseremailbeforeAt = this.signeduseremailbeforeAt.slice(
                  0,
                  this.signeduseremailbeforeAt.indexOf("@")
                );
                if (this.rs.GetUserLoggedInStatus() == "groupscreen") {
                  this.Group_Number_Box = true;
                  return false;
                }
                if (!!window.localStorage.getItem("UserGroup")) {
                  this.groupnumber = true;
                }
                this.isLoggedin = true;
                this.notLoggedin = false;
                this.menubar = false;
                this.router.navigate(["/Home"]);
              }
            } else {
              this.sign_in_error = "Please try aftersometime";
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }

  setGroupNumberDetails() {
    this.current_email = window.localStorage.getItem("UserEmail");
    this.current_first_name = window.localStorage.getItem("FirstName");
    this.current_first_name = window.localStorage.getItem("LastName");
  }
  MakeSignUp() {
    this.email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let ValidField = 0;
    if (this.sign_up_password.length < 6) {
      this.sign_up_password_error = "Minimum password length must be 6";
      $("#password_signup").parent().addClass("error_msg");
      return false;
    }
    if (this.ms.IsNullOrEmptyField(this.sign_up_email) == true) {
      this.sign_up_email_error = "Email Id is required";
      $("#email_signup").parent().addClass("error_msg");
    } else {
      if (this.email_REGEX.test(this.sign_up_email) == false) {
        this.sign_in_email_error = "Please enter correct email or valid email";
        $("#email_signup").parent().addClass("error_msg");
      } else {
        this.sign_up_email_error = "";
        ValidField = ValidField + 1;
      }
    }
    if (this.ms.IsNullOrEmptyField(this.sign_up_password) == true) {
      this.sign_up_password_error = "Password is required";
      $("#password_signup").parent().addClass("error_msg");
    } else {
      if (
        this.ms.IsNullOrEmptyField(this.sign_up_cpassword) == false &&
        this.sign_up_password.toUpperCase() !=
        this.sign_up_cpassword.toUpperCase()
      ) {
        this.sign_up_password_error = "Minimum password length must be 6";
        $("#password_signup").parent().addClass("error_msg");
      } else {
        this.sign_up_password_error = "";
        ValidField = ValidField + 1;
      }
    }
    if (this.ms.IsNullOrEmptyField(this.sign_up_cpassword) == true) {
      this.sign_up_cpassword_error = "Confirm Password is required";
      $("#c_password_signup").parent().addClass("error_msg");
    } else {
      if (
        this.ms.IsNullOrEmptyField(this.sign_up_password) == false &&
        this.sign_up_password.toUpperCase() !=
        this.sign_up_cpassword.toUpperCase()
      ) {
        this.sign_up_cpassword_error = "Passwords must match";
        $("#c_password_signup").parent().addClass("error_msg");
      } else {
        this.sign_up_cpassword_error = "";
        ValidField = ValidField + 1;
      }
    }
    if (ValidField >= 3) {
      let gn = "";
      if (
        window.localStorage.getItem("UserGroup") == "" ||
        window.localStorage.getItem("UserGroup") == null ||
        window.localStorage.getItem("UserGroup") == undefined
      ) {
        gn = "";
      } else {
        gn = window.localStorage.getItem("UserGroup").toUpperCase();
      }

      this.googleAnalyticsService.eventEmitter(
        "Account Page",
        "Signup",
        this.sign_up_email,
        0
      );
      this.ms
        .CreateAccount(this.sign_up_email.trim(), this.sign_up_password, gn)
        // .pipe(map((res) => res.json()))
        .subscribe(
          (data) => {
            if (data.length > 0) {
              if (data[0].Success == false) {
                this.sign_up_error = data[0].StatusMessage;
              } else {
                window.localStorage.setItem("UserId", data[0].UserId);
                window.localStorage.setItem("UserCharId", data[0].UserCharId);
                window.localStorage.setItem("UserEmail", this.sign_up_email);
                window.localStorage.setItem("SkipSignup", "signupdone");
                // window.localStorage.setItem("UserGroup", this.sign_up_email);
                this.ms.PushCardList();
                this.sign_up_error = "";
                $(".close_search_pharmacy_popup").click();
                this.isLoggedin = true;
                this.notLoggedin = false;
                setTimeout(() => {
                  $(".homepage").addClass("loggedin_header");
                  $(".pharmacyPage").addClass("loggedin_header");
                }, 600);
                this.menubar = false;
                this.sign_in_user_email = window.localStorage.getItem(
                  "UserEmail"
                );
                this.signeduseremailbeforeAt = window.localStorage.getItem(
                  "UserEmail"
                );
                this.signeduseremailbeforeAt = this.signeduseremailbeforeAt.slice(
                  0,
                  this.signeduseremailbeforeAt.indexOf("@")
                );
                if (
                  window.localStorage.getItem("UserGroup") == "" ||
                  window.localStorage.getItem("UserGroup") == null ||
                  window.localStorage.getItem("UserGroup") == undefined
                ) {
                  this.InputGroupNumber = "";
                  this.Group_Number_Box = true;
                } else {
                  this.InputGroupNumber = "";
                  this.Group_Number_Box = false;
                }
                this.setGroupNumberDetails();
                this.groupnumber = true;
              }
            } else {
              this.sign_up_error = "Please try aftersometime";
            }
          },
          (error) => { }
        );
    }
  }

  SkipSignUp() {
    // window.localStorage.setItem("UserGroup", this.rs.DefaultGN());
    this.groupnumber = true;
    window.localStorage.setItem("SkipSignup", "signupskipped");
    if ($(window).width() <= 768) {
      $(".searchbox_home").addClass("mobile_searchbox_home");
    } else {
      $(".searchbox_home").removeClass("mobile_searchbox_home");
    }
    if (
      window.localStorage.getItem("UserGroup") == null ||
      window.localStorage.getItem("UserGroup") == "False" ||
      window.localStorage.getItem("UserGroup") == undefined
    ) {
      this.Group_Number_Box = true;
      $("body").css("overflow", "hidden");
    } else {
      this.Group_Number_Box = false;

      //this.events.publish('toggleMenu', 1);
      //this.navCtrl.push(HomePage)
    }
    this.showloginbox = false;
    this.signup_fields = false;
    this.signin_fields = false;
    $("body").removeAttr("style");
  }

  MakeSignOut() {
    window.localStorage.removeItem('brokerdetail');
    window.localStorage.removeItem('a');
    window.localStorage.removeItem('brokergroup');
    window.localStorage.removeItem('portallink');
    window.localStorage.removeItem('signstatus');
    window.localStorage.removeItem('klaviyoid');
    this.isLoggedin = false;
    this.notLoggedin = true;
    this.brokerexist = false;
    this.brokerpage = false;
    this.loginPage = true;
    $('body').removeClass('loginPage');
    this.router.navigate(['/enrollmentcenter/login']);
    setTimeout(() => {
      $(".homepage").removeClass("loggedin_header");
      $(".pharmacyPage").removeClass("loggedin_header");

    }, 600);
    this.menubar = true;
    // try {
    //   window.location.href = this.router["location"]["_platformStrategy"][
    //     "_platformLocation"
    //   ]["location"]["origin"];
    // } catch { }
  }
  ForgotPassword() {
    this.otp_sent = false;
    this.forgot_email_error = null;
    this.input_otp_error = null;
    this.input_otp = null;
    this.reset_update_password_error = null;
    this.forgotpassword_box = true;
    this.showloginbox = false;
  }
  removeforgot(event) {
    this.otp_sent = false;
    this.forgot_email = "";
    this.forgotpassword_box = false;
    this.resetpassword_Box = false;
  }
  removegroup(event) {
    this.Group_Number_Box = false;
    $("body").removeAttr("style");
  }
  KeyUp_TypeDrug(Event: any) {
    if (Event.target.value.length >= 3) {
      this.ms
        .SearchDrugList(Event.target.value)
        // .pipe(map((res) => res.json()))
        .subscribe(
          (data) => {
            if (data.length > 0) {
              this.SearchList = data[0]["data"];
            }
            if (this.SearchList.length > 0) {
              this.DisplayBindList = true;
            } else {
              this.DisplayBindList = false;
            }
          },
          (error) => { }
        );
    } else {
      this.DisplayBindList = false;
    }
  }
  GetUserLoggedInStatus() {
    if (this.rs.GetUserLoggedInStatus() == "loggedin") {
      this.isLoggedin = true;
      this.notLoggedin = false;
      this.sign_in_user_email = window.localStorage.getItem("UserEmail");
      this.menubar = false;
      this.signeduseremailbeforeAt = window.localStorage.getItem("UserEmail");
      this.signeduseremailbeforeAt = this.signeduseremailbeforeAt.slice(
        0,
        this.signeduseremailbeforeAt.indexOf("@")
      );
    } else {
      this.isLoggedin = false;
      this.notLoggedin = true;
    }
  }

  AppInitialize() {
    try {
      if (
        this.ms.IsNullOrEmptyField(window.localStorage.getItem("WhiteName")) ==
        true ||
        this.ms.IsNullOrEmptyField(window.localStorage.getItem("WhiteEmail")) ==
        true
      )
        this.ms
          .GetWhiteParams()
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                window.localStorage.setItem("WhiteName", data[0].WhiteName);
                window.localStorage.setItem("WhiteEmail", data[0].WhiteEmail);
                this._AppName = data[0].WhiteName;
                this._AppEmail = data[0].WhiteEmail;
              }
            },
            (error) => { }
          );
    } catch { }
  }
  HomeScreen(val) {
    this.removeStyle("home_screen");
    this.router.navigate([""]);
  }
  MySavedCards() {
    this.removeStyle("home_screen");
    this.router.navigate(["/MyCards"]);
    this.menubar = !this.menubar;
  }
  removeStyle(val) {
    // ie fix issue
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");
    var edge = ua.indexOf("Edge/");
    var ff = ua.indexOf("Firefox");
    if (
      msie > 0 ||
      !!navigator.userAgent.match(/Trident.*rv\:11\./) ||
      edge > 0 ||
      ff > 0
    ) {
      // If Internet Explorer, return version number
      if (val == "home_screen" || val == "sign_up" || val == "sign_in") {
        $(".ie_fixed_add_n, #Signin_Signup_Header").removeAttr("style");
      }
    }
  }
  removechangepass(event) {
    this.changepassword = false;
    this.otp_sent = false;
    this.forgot_email = "";
    this.current_password = null;
    this.new_password = null;
    this.c_new_password = null;
    this.resetpassword_Box = false;
    this.forgotpassword_box = false;
    this.changepassword = false;
    $("body").removeAttr("style");
  }
  showchangepass(event) {
    this.current_password_error = null;
    this.new_password_error = null;
    this.c_new_password_error = null;
    this.change_password_error = null;
    this.changepassword = true;
    $("input[type='password']").val("");
    $("body").css("overflow", "hidden");
  }
  SkipGroupNumber() {
    // window.localStorage.setItem("UserGroup", this.rs.GNForOPT2());
    if ($(window).width() <= 768) {
      $(".searchbox_home").addClass("mobile_searchbox_home");
    } else {
      $(".searchbox_home").removeClass("mobile_searchbox_home");
    }
    this.UpdateuserGroup(
      window.localStorage.getItem("UserId"),
      this.rs.GNForOPT2()
    );
    this.Group_Number_Box = false;
    $(".close_search_pharmacy_popup").click();
    //this.isLoggedin = false;
    this.GetUserLoggedInStatus();
    $("body").removeAttr("style");
    //this.SignupScreen();
  }
  _GrnumberValidate(e: any) {
    var keyCode = e.which ? e.which : e.keyCode;
    if (keyCode != 8 && keyCode != 0 && (keyCode < 48 || keyCode > 57)) {
      return false;
    } else if (e.target.value.length > 4) {
      return false;
    }
  }
  SubmitGroupNumber() {
    if (this.ms.IsNullOrEmptyField(this.InputGroupNumber) == true) {
      this.input_groupnumber_error = "Group number is required";
      $("#InputGroup").parent().addClass("error_msg");
    } else {
      this.ms
        .GNValidate(this.InputGroupNumber)
        // .pipe(map((res) => res.json()))
        .subscribe(
          (data) => {
            if (data.length > 0) {
              if (data[0].StatusCode == "False") {
                window.localStorage.setItem("UserGroup", "False");
                this.input_groupnumber_error = "* " + data[0].StatusMessage;
                $("#InputGroup").parent().addClass("error_msg");
              } else {
                window.localStorage.setItem("UserGroup", data[0].StatusMessage);
                this.UpdateuserGroup(
                  window.localStorage.getItem("UserId"),
                  this.InputGroupNumber
                  //data[0].StatusMessage
                );
                this.Group_Number_Box = false;
                $("body").removeAttr("style");
                $(".close_search_pharmacy_popup").click();
                if (
                  window.localStorage.getItem("signupskipped") ==
                  "signupskipped" ||
                  window.localStorage.getItem("UserId") == null ||
                  window.localStorage.getItem("UserId") == "" ||
                  window.localStorage.getItem("UserId") == undefined
                ) {
                  this.isLoggedin = false;
                  this.notLoggedin = true;
                } else {
                  this.isLoggedin = true;
                  this.notLoggedin = true;
                }
              }
            } else {
              this.input_groupnumber_error = "* Invalid Group Number";
              window.localStorage.setItem("UserGroup", "False");
              $("#InputGroup").parent().addClass("error_msg");
            }
            if (data[0].Wholesaer != null) {
              if (data[0].Wholesaer[0].WhName == "Avenel") {
                window.localStorage.setItem('WhName', data[0].Wholesaer[0].WhName)
                window.localStorage.setItem('UserCharId', '12345')
                let cardindex = window.localStorage.getItem('cardIndex');
                $('.card').eq(parseInt(cardindex)).click();
              }
              else {
                window.localStorage.removeItem('WhName')
              }
            }
            else {
              window.localStorage.removeItem('WhName')
            }
          },
          (error) => { }
        );
    }
  }
  UpdateuserGroup(UserId, UserGroup) {
    try {
      if (
        window.localStorage.getItem("UserId") == null ||
        window.localStorage.getItem("UserId") == "" ||
        window.localStorage.getItem("UserId") == undefined
      ) {
        return;
      } else {
        this.ms
          .UpdateGroupNumber(UserId, UserGroup)
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                if (data[0].StatusCode == "0") {
                  window.localStorage.setItem("UserGroup", UserGroup);
                  this.input_groupnumber_error = "* " + data[0].StatusMessage;
                  $("#InputGroup").parent().addClass("error_msg");
                } else {
                  window.localStorage.setItem("UserGroup", UserGroup);
                  this.Group_Number_Box = false;
                  $(".close_search_pharmacy_popup").click();
                }
              } else {
                this.input_groupnumber_error = "Please try after sometime.";
                window.localStorage.setItem("UserGroup", "False");
                $("#InputGroup").parent().addClass("error_msg");
              }
            },
            (error) => { }
          );
      }
    } catch { }
  }
  OnBlur_ChangePassword(event: any) {
    if (event.target.name == "currentpass") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.current_password_error = "Current password is required";
        $("#currentpass").parent().addClass("error_msg");
      } else {
        this.current_password_error = "";
        $("#currentpass").parent().removeClass("error_msg");
      }
    } else if (event.target.name == "newpass") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.new_password_error = "Current password is required";
        $("#newpass").parent().addClass("error_msg");
      } else if (event.target.value.length < 6) {
        this.new_password_error = "Minimum password length must be 6!";
        $("#newpass").parent().addClass("error_msg");
      } else if (
        event.target.value.length >= 6 &&
        event.target.value.split(" ").length - 1 > 0
      ) {
        this.new_password_error = "White spaces are not allowed in password!";
        $("#newpass").parent().addClass("error_msg");
        return;
      } else {
        this.new_password_error = "";
        $("#newpass").parent().removeClass("error_msg");
      }
    } else if (event.target.name == "confirmpass") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.c_new_password_error = "Confirm password is required";
        $("#confirmpass").parent().addClass("error_msg");
      } else if (event.target.value.length < 5) {
        this.c_new_password_error = "Minimum password length must be 5!";
        $("#confirmpass").parent().addClass("error_msg");
      } else if (
        event.target.value.length >= 5 &&
        event.target.value.split(" ").length - 1 > 0
      ) {
        this.c_new_password_error = "White spaces are not allowed in password!";
        $("#confirmpass").parent().addClass("error_msg");
      } else {
        this.c_new_password_error = "";
        $("#confirmpass").parent().removeClass("error_msg");
      }
    } else if (event.target.name == "forgotemail") {
      this.Email_OTP_Input = "Email";
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.forgot_email_error = "Email Id is required";
        // $("#forgotemail").parent().addClass("error_msg");
      } else {
        this.forgot_email_error = "";
        // $("#forgotemail").parent().removeClass("error_msg");
      }
    } else if (event.target.name == "OTP") {
      this.Email_OTP_Input = "OTP";
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.input_otp_error = "6 digit code is required";
        //  $("#confirmpass").parent().addClass("error_msg");
      } else {
        this.input_otp_error = "";
        //$("#confirmpass").parent().removeClass("error_msg");
      }
    } else if (event.target.name == "resetnewpass") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.reset_new_password_error = "New password is required";
        $("#resetnewpass").parent().addClass("error_msg");
      } else if (event.target.value.length < 5) {
        this.reset_new_password_error = "Minimum password length must be 5!";
        $("#resetnewpass").parent().addClass("error_msg");
      } else if (
        event.target.value.length >= 5 &&
        event.target.value.split(" ").length - 1 > 0
      ) {
        this.reset_new_password_error =
          "White spaces are not allowed in password!";
        $("#resetnewpass").parent().addClass("error_msg");
      } else {
        this.reset_new_password_error = "";
        $("#resetnewpass").parent().removeClass("error_msg");
      }
    } else if (event.target.name == "resetconfirmpass") {
      if (this.ms.IsNullOrEmptyField(event.target.value) == true) {
        this.reset_c_new_password_error = "Confirm new password is required";
        $("#resetconfirmpass").parent().addClass("error_msg");
      } else if (event.target.value.length < 5) {
        this.reset_c_new_password_error = "Minimum password length must be 5!";
        $("#resetconfirmpass").parent().addClass("error_msg");
      } else if (
        event.target.value.length >= 5 &&
        event.target.value.split(" ").length - 1 > 0
      ) {
        this.reset_c_new_password_error =
          "White spaces are not allowed in password!";
        $("#resetconfirmpass").parent().addClass("error_msg");
      } else {
        this.reset_c_new_password_error = "";
        $("#resetconfirmpass").parent().removeClass("error_msg");
      }
    }
  }
  StopWhiteSpace(e: any) {
    var keyCode = e.which ? e.which : e.keyCode;
    if (keyCode == 32) {
      return false;
    }
  }
  UpdatePassWord() {
    let ValidField = 0;
    this.change_password_error = "";
    this.new_password_error = "";
    this.c_new_password_error = "";
    $("#currentpass").parent().removeClass("error_msg");
    $("#newpass").parent().removeClass("error_msg");
    $("#confirmpass").parent().removeClass("error_msg");
    //--------------current password-----------------
    if (this.ms.IsNullOrEmptyField(this.current_password) == true) {
      this.current_password_error = "Current password is required";
      $("#currentpass").parent().addClass("error_msg");
    } else {
      this.current_password_error = "";
      $("#currentpass").parent().removeClass("error_msg");
      ValidField = ValidField + 1;
    }
    //--------------new password-----------------
    if (this.ms.IsNullOrEmptyField(this.new_password) == true) {
      this.new_password_error = "New password is required";
      $("#newpass").parent().addClass("error_msg");
    } else if (this.new_password.length < 6) {
      this.new_password_error = "Minimum password length must be 6";
      $("#newpass").parent().addClass("error_msg");
      return;
    } else if (
      this.new_password.length >= 6 &&
      this.new_password.split(" ").length - 1 > 0
    ) {
      this.new_password_error = "White spaces are not allowed in password!";
      $("#newpass").parent().addClass("error_msg");
      return;
    } else {
      if (
        this.ms.IsNullOrEmptyField(this.c_new_password) == false &&
        this.new_password.toUpperCase() != this.c_new_password.toUpperCase()
      ) {
        // this.new_password_error = "New password & confirm new password must match!";
        // $("#newpass")
        //   .parent()
        //   .addClass("error_msg");
        // return;
      } else {
        this.new_password_error = "";
        $("#newpass").parent().removeClass("error_msg");
        ValidField = ValidField + 1;
      }
    }
    //--------------confirm new password-----------------
    if (this.ms.IsNullOrEmptyField(this.c_new_password) == true) {
      this.c_new_password_error = "Confirm password is required";
      $("#confirmpass").parent().addClass("error_msg");
    }
    //  else if (this.c_new_password.length < 5) {
    //   this.c_new_password_error = "Minimum password length must be 5";
    //   $("#confirmpass")
    //     .parent()
    //     .addClass("error_msg");
    // }
    else if (
      this.ms.IsNullOrEmptyField(this.new_password) == false &&
      this.new_password.toUpperCase() != this.c_new_password.toUpperCase()
    ) {
      this.c_new_password_error =
        "New password & confirm new password must match!";
      $("#confirmpass").parent().addClass("error_msg");
    } else if (
      this.c_new_password.length >= 6 &&
      this.c_new_password.split(" ").length - 1 > 0
    ) {
      this.c_new_password_error = "White spaces are not allowed in password!";
      $("#confirmpass").parent().addClass("error_msg");
      // this.change_password_error="White spaces are not allowed in password!";
    } else {
      this.c_new_password_error = "";
      $("#confirmpass").parent().removeClass("error_msg");
      ValidField = ValidField + 1;
    }
    if (ValidField >= 3) {
      try {
        this.ms
          .ResetPassword(
            window.localStorage.getItem("UserId"),
            this.current_password,
            this.c_new_password
          )
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                if (data[0].StatusCode == "1") {
                  this.change_password_error = "";
                  this.removechangepass("$event");
                  $(".change_pwd_btn")
                    .addClass("success")
                    .text("Success")
                    .css("pointer-events", "none");
                  this.changepassword = false;
                } else {
                  //Error
                  this.change_password_error = data[0].StatusMessage;
                }
              } else {
                //Error
                this.change_password_error = "Please try after sometime";
              }
            },
            (error) => {
              //Error
              this.change_password_error = "Please try after sometime";
            }
          );
      } catch {
        //Error
        this.change_password_error = "Please try after sometime";
      }
    }
  }
  mobCloseToggle() {
    this.mobsuccess = false;
  }
  Submit_Email_OTP() {
    if (
      this.Email_OTP_Input == "Email" ||
      this.Email_OTP_Input == "" ||
      this.Email_OTP_Input == null
    ) {
      this.email_REGEX = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (this.ms.IsNullOrEmptyField(this.forgot_email) == true) {
        {
          this.forgot_email_error = "Email Id is required";
          $("#forgotemail").parent().parent().addClass("error_msg");
        }
      } else if (this.email_REGEX.test(this.forgot_email) == false) {
        {
          this.forgot_email_error = "Please enter correct email address";
          $("#forgotemail").parent().parent().addClass("error_msg");
        }
      } else {
        $("#forgotemail").parent().parent().removeClass("error_msg");
        this.googleAnalyticsService.eventEmitter(
          "Account Page",
          "Forgot Password",
          this.forgot_email,
          0
        );
        this.ms
          .RequestOTP_Forgotpassword(this.forgot_email)
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                if (data[0].Message != "OTP sent successfully.") {
                  this.forgot_email_error = data[0].Message;
                } else {
                  $("#forgotemail").prop("disabled", true);
                  this._CurrentUserId = data[0].UserId;
                  this.otp_sent = true;
                }
              } else {
                this.forgot_email_error = "Please try again";
              }
            },
            (error) => { }
          );
      }
    } else {
      if (this.ms.IsNullOrEmptyField(this.input_otp) == true) {
        this.input_otp_error = "6 digit code is required";
      } else {
        this.ms
          .VerifyOTP_Forgotpassword(this.input_otp, this._CurrentUserId)
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                if (data[0].StatusCode == "1") {
                  window.localStorage.setItem("UserCharId", data[0].UserCharId);
                  if (
                    window.localStorage.getItem("UserGroup") != null ||
                    window.localStorage.getItem("UserGroup") != undefined ||
                    window.localStorage.getItem("UserGroup") != ""
                  ) {
                    window.localStorage.setItem("UserGroup", data[0].UserGroup);
                  }
                  this.reset_c_new_password_error = null;
                  this.reset_update_password_error = null;
                  this.reset_new_password_error = null;
                  this.forgotpassword_box = false;
                  this.reset_new_password = null;
                  this.reset_c_new_password = null;
                  this.resetpassword_Box = true;
                } else {
                  this.input_otp_error = data[0].StatusMessage;
                  $("#OTP").parent().parent().addClass("error_msg");
                }
              } else {
                this.input_otp_error = "Please try again";
                $("#OTP").parent().parent().addClass("error_msg");
              }
            },
            (error) => { }
          );
      }
    }
  }
  ResetPassword() {
    this.reset_update_password_error = "";
    this.reset_new_password_error = "";
    this.reset_c_new_password_error = "";
    $("#resetnewpass").parent().removeClass("error_msg");
    $("#resetconfirmpass").parent().removeClass("error_msg");
    let ValidField = 0;
    //--------------new password-----------------
    if (this.ms.IsNullOrEmptyField(this.reset_new_password) == true) {
      this.reset_new_password_error = "New password is required!";
      $("#resetnewpass").parent().addClass("error_msg");
    } else if (this.reset_new_password.length < 6) {
      this.reset_new_password_error = "Minimum password length must be 6!";
      $("#resetnewpass").parent().addClass("error_msg");
      return;
    } else if (
      this.reset_new_password.length >= 6 &&
      this.reset_new_password.split(" ").length - 1 > 0
    ) {
      this.reset_new_password_error =
        "White spaces are not allowed in password!";
      $("#resetnewpass").parent().addClass("error_msg");
      return;
    } else {
      // if (
      //   this.ms.IsNullOrEmptyField(this.reset_c_new_password) == false &&
      //   this.reset_new_password.toUpperCase() !=
      //   this.reset_c_new_password.toUpperCase()
      // ) {
      //   this.reset_new_password_error = "Passwords must match!";
      //   $("#resetnewpass")
      //     .parent()
      //     .addClass("error_msg");
      //   this.reset_update_password_error = "";
      // } else {
      this.reset_new_password_error = "";
      $("#resetnewpass").parent().removeClass("error_msg");
      ValidField = ValidField + 1;
      // }
    }
    //--------------confirm new password-----------------
    if (this.ms.IsNullOrEmptyField(this.reset_c_new_password) == true) {
      this.reset_c_new_password_error = "Confirm password is required!";
      $("#resetconfirmpass").parent().addClass("error_msg");
    }
    // else if (this.reset_c_new_password.length < 5) {
    //   this.reset_c_new_password_error = "Minimum password length must be 5!";
    //   $("#resetconfirmpass")
    //     .parent()
    //     .addClass("error_msg");
    // }
    // else if (this.reset_c_new_password.length >= 5 && (this.reset_c_new_password.split(" ").length - 1) > 0) {
    //   this.reset_c_new_password_error = "White spaces are not allowed in password!";
    //   $("#resetconfirmpass").parent().addClass("error_msg");
    // }
    else {
      if (
        this.ms.IsNullOrEmptyField(this.reset_new_password) == false &&
        this.reset_new_password.toUpperCase() !=
        this.reset_c_new_password.toUpperCase()
      ) {
        this.reset_c_new_password_error =
          "New password & confirm new password must match!";
        $("#resetconfirmpass").parent().addClass("error_msg");
      } else {
        this.reset_c_new_password_error = "";
        $("#resetconfirmpass").parent().removeClass("error_msg");
        ValidField = ValidField + 1;
      }
    }
    if (ValidField >= 2) {
      try {
        this.ms
          .VerifyOTP_Resetpassword(this._CurrentUserId, this.reset_new_password)
          // .pipe(map((res) => res.json()))
          .subscribe(
            (data) => {
              if (data.length > 0) {
                if (data[0].StatusCode == "1") {
                  this.ms.Setsignup(false, false, false);
                  this.showloginbox = this.ms.Getsignup()[0];
                  this.signup_fields = this.ms.Getsignup()[1];
                  this.signin_fields = this.ms.Getsignup()[2];
                  this.removechangepass("$event");
                  this.resetpassword_Box = false;
                  $(".reset_button").addClass("success").text("Success");
                  $("body").removeAttr("style");
                } else {
                  this.reset_update_password_error = data[0].StatusMessage;
                }
              } else {
                this.reset_update_password_error = "Please try again";
              }
            },
            (error) => {
              this.reset_update_password_error = "Please try again";
            }
          );
      } catch {
        this.reset_update_password_error = "Please try again";
      }
    }
  }
  //----------------------------Top Search Implementation-------------------------------------------------------
  addActive_select(event, number, selected_value) {
    $(event.target).parent().find("li").removeClass("active");
    event.target.classList.add("active");
    this.hideme[number] = false;
    if (number == 0) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Name",
        this.SelectedDrug + "<-->" + selected_value,
        0
      );
      this.SelectedDrug = selected_value
        .replace(" (Generic Drug)", "")
        .replace(" (Brand Drug)", "")
        .trim();
      window.localStorage.setItem("SelectedDrugName", this.SelectedDrug);
      if (selected_value.indexOf("Generic Drug") > -1) {
        this.DrugCat = "Generic";
        window.localStorage.setItem("IsBG", this.DrugCat);
      } else {
        this.DrugCat = "Brand";
        window.localStorage.setItem("IsBG", this.DrugCat);
      }
      this.UpdateDrugParamsWithSwaping(this.SelectedDrug, this.DrugCat);
    } else if (number == 1) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Form",
        this.DrugType + "<-->" + selected_value,
        0
      );
      this.DrugType = selected_value;
      window.localStorage.setItem("DrugType", this.DrugType);
      this.UpdateDoseByFormTypeSwaping(this.DrugType);
    } else if (number == 2) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Dosage",
        this.DrugDose + "<-->" + selected_value,
        0
      );
      this.DrugDose = selected_value;
      window.localStorage.setItem("DrugDose", this.DrugDose);
    } else if (number == 3) {
      this.googleAnalyticsService.eventEmitter(
        "Drug Details Pop Up",
        "Swap Drug Quantity",
        this.DrugQuant + "<-->" + selected_value,
        0
      );
      this.DrugQuant = selected_value;
      window.localStorage.setItem("DrugQuant", this.DrugQuant);
    } else if (number == -3) {
      this.Quantity_Manual = null;
      this.ManualQuantity_box = true;
    }
  }
  CloseDrugDetails() {
    this.ShowDrugdetails = false;
    $("body").removeAttr("style");
  }
  TypeDrug(stext: any) {
    if (stext.keyCode == 13 || stext.key == "Enter") {
      stext.preventDefault();
      return false;
    }
    if (this.rs.GetUserLoggedInStatus() == "signupscreen") {
      $(".login_btn a")[0].click();
      this.ShowDrugdetails = false;
      return false;
    } else if (this.rs.GetUserLoggedInStatus() == "groupscreen") {
      this.ShowDisplay = false;
      this.ShowDrugdetails = false;
      this.Group_Number_Box = true;
      return false;
    } else {
      this.SearchText = stext.target.value;
      this.SearchDrug(this.SearchText);
    }
  }
  SearchDrug(stext: string) {
    if (stext.length >= 3) {
      // this.googleAnalyticsService.eventEmitter("Home Page", "Search Drug", stext, 0);
      this.GetDrugList(stext);
    } else {
      this.DisplayBindList = false;
      this.NoDrugsFound = true;
      this.SearchList = [];
    }
  }

  GetDrugList(TypedText) {
    this.ms
      .SearchDrugList(TypedText)
      // .pipe(map((res) => res.json()))
      .subscribe(
        (data) => {
          if (data.length > 0) {
            this.NoDrugsFound = false;
            this.SearchList = data;
          } else {
            setTimeout(() => {
              $(".heading").hide();
            }, 50);
            this.NoDrugsFound = true;
            this.SearchList = [];
          }
          if (this.SearchList.length > 0) {
            this.DisplayBindList = true;
            this.ShowDisplay = false;
            $(".heading").show();
          } else {
            this.DisplayBindList = false;
            this.ShowDisplay = false;
          }
          if (this.NoDrugsFound == true) {
            this.DisplayBindList = false;
            this.ShowDisplay = true;
          }
        },
        (error) => { }
      );
  }
  DrugSetting(_Drugname: any) {
    try {
      if (
        window.localStorage.getItem("SearchItemList") != null &&
        window.localStorage.getItem("SearchItemList").length != 0 &&
        window.localStorage.getItem("SearchItemList") != undefined
      ) {
        let RecentSearchListData = JSON.parse(
          window.localStorage.getItem("SearchItemList")
        );
        let _RecentNameFilter = RecentSearchListData.filter((item) => {
          return (
            item["RecentSearchName"].toLowerCase() == _Drugname.toLowerCase()
          );
        });
        if (_RecentNameFilter.length == 0) {
          this.SerachList = [];
          window.localStorage.setItem("SearchItemList", "");

          for (let x3 = 0; x3 < RecentSearchListData.length; x3++) {
            let TempItem = {
              RecentSearchName: RecentSearchListData[x3]["RecentSearchName"],
            };
            this.SerachList.push(TempItem);
          }
          let TempItem1 = {
            RecentSearchName: _Drugname,
          };
          this.SerachList.push(TempItem1);
          window.localStorage.setItem(
            "SearchItemList",
            JSON.stringify(this.SerachList)
          );
        } else {
          this.SerachList = RecentSearchListData.filter(
            (uname) => uname["RecentSearchName"] !== _Drugname
          );
          let TempItem1 = {
            RecentSearchName: _Drugname,
          };
          this.SerachList.push(TempItem1);
          window.localStorage.setItem(
            "SearchItemList",
            JSON.stringify(this.SerachList)
          );
        }
      } else {
        let newItem = {
          RecentSearchName: _Drugname,
        };
        this.SerachList = [];
        this.SerachList.push(newItem);
        window.localStorage.setItem(
          "SearchItemList",
          JSON.stringify(this.SerachList)
        );
      }
    } catch { }
    this._UserZip = window.localStorage.getItem("userZipCode");
    this.SelectedDrug = _Drugname;
    this.DrugComposition();
    this.ShowDisplay = false;
    this.DisplayBindList = false;
  }
  DrugComposition() {
    try {
      this.ClearErrorClass();
      this.ms
        .GetDrugDetails(this.SelectedDrug)
        // .pipe(map((res) => res.json()))
        .subscribe(
          (data) => {
            if (
              data[0]["brand"].length == 0 &&
              data[0]["generic"].length == 0
            ) {
              $(".searchbox_box")
                .parent()
                .removeClass("error_msg")
                .addClass("error_msg");
              this.searchtextbox_error = "Invalid drug name";
              return false;
            }
            window.localStorage.setItem(
              "SelectedDrugConfig",
              JSON.stringify(data)
            );
            this.DoseDescriptor_JSON = JSON.parse(
              this.rs.DrugDoseDescription()
            );
            this.DrugDoseList = [];
            this.optionlist = [];
            this.DrugTypeList = [];
            if (data.length > 0) {
              this.DrugDetailsResponseData = data;
              this.DrugDetails = data;
              this.ResponseData = data;
              this.ShowDetails = true;
              this.ShowDrugdetails = true;
              this.Enter_Zip = false;
              $("body").css("overflow", "hidden");
              this.DrugName = this.SelectedDrug;
              if (data[0]["brand_name_code"] == "Brand") {
                // this.MsourceList.push({ value:"BRAND", text: "Branded" });
                this.DrugCat = "Brand";
                this.BrandType = true;
                try {
                  this.DrugDoseList = [];
                  this.optionlist = [];
                  if (this.ResponseData.length > 0) {
                    let _NameFilter = this.ResponseData[0]["brand"].filter(
                      (item) => {
                        return (
                          item["sub_drug_name"].toLowerCase() ==
                          this.DrugName.toLowerCase()
                        );
                      }
                    );
                    for (let x of _NameFilter[0]["types"][0]["strength"]) {
                      this.DrugDoseList.push(x["Strength"]);
                    }

                    for (let x of _NameFilter[0]["types"]) {
                      try {
                        let _DoseFilter = this.DoseDescriptor_JSON.filter(
                          (item) => {
                            return (
                              item["Dose"].toLowerCase() == x.dose.toLowerCase()
                            );
                          }
                        );
                        if (_DoseFilter.length > 0) {
                          this.optionlist.push({
                            value: _DoseFilter[0]["Descrption"],
                            text: _DoseFilter[0]["Descrption"],
                          });
                        } else {
                          this.optionlist.push({ value: x.dose, text: x.dose });
                        }
                      } catch {
                        this.optionlist.push({ value: x.dose, text: x.dose });
                      }
                    }
                  }

                  if (this.optionlist.length > 0) {
                    this.DrugType = this.optionlist[0].value; /////DosageForm(TABS/CAPS/SOLN ....)
                  }
                  if (this.DrugDoseList.length > 0) {
                    this.DrugDose = this.DrugDoseList[0].replace(" ", ""); //////// Strength & Strength Unit(10MG, 100MG/2ML .....)
                  }

                  this.selectedValue = this.DrugType;
                  // window.localStorage.setItem("DrugType", this.DrugType);
                } catch { }
              } else {
                this.DrugCat = "Generic";
                this.BrandType = false;
                // window.localStorage.setItem("IsBG", data[0]["brand_name_code"]);
                try {
                  this.DrugDoseList = [];
                  this.optionlist = [];
                  if (this.ResponseData.length > 0) {
                    let _NameFilter = this.ResponseData[0]["generic"].filter(
                      (item) => {
                        return (
                          item["sub_drug_name"].toLowerCase() ==
                          this.DrugName.toLowerCase()
                        );
                      }
                    );
                    for (let x of _NameFilter[0]["types"][0]["strength"]) {
                      this.DrugDoseList.push(x["Strength"]);
                    }
                    for (let x of _NameFilter[0]["types"]) {
                      try {
                        let _DoseFilter = this.DoseDescriptor_JSON.filter(
                          (item) => {
                            return (
                              item["Dose"].toLowerCase() == x.dose.toLowerCase()
                            );
                          }
                        );
                        if (_DoseFilter.length > 0) {
                          this.optionlist.push({
                            value: _DoseFilter[0]["Descrption"],
                            text: _DoseFilter[0]["Descrption"],
                          });
                        } else {
                          this.optionlist.push({ value: x.dose, text: x.dose });
                        }
                      } catch {
                        this.optionlist.push({ value: x.dose, text: x.dose });
                      }
                    }
                  }
                  if (this.optionlist.length > 0) {
                    this.DrugType = this.optionlist[0].value;
                  }
                  if (this.DrugDoseList.length > 0) {
                    this.DrugDose = this.DrugDoseList[0].replace(" ", "");
                  }

                  this.selectedValue = this.DrugType;
                  // window.localStorage.setItem("DrugType", this.DrugType);
                } catch { }
                if (this.DrugDoseList.length > 0) {
                  this.DrugDose = this.DrugDoseList[0].replace(" ", "");
                }
              }
              try {
                if (this.optionlist.length <= 1) {
                  this.DrugFormSingle = true;
                } else {
                  this.DrugFormSingle = false;
                }
              } catch { }
              try {
                if (this.DrugDoseList.length <= 1) {
                  this.DrugDoseSingle = true;
                } else {
                  this.DrugDoseSingle = false;
                }
              } catch { }
              this.InitQuantityList();
              this.DrugNameType();

              try {
                if (
                  this.DrugName == undefined ||
                  this.DrugName == "" ||
                  this.DrugQuant == undefined ||
                  this.DrugQuant == "" ||
                  this.DrugDose == undefined ||
                  this.DrugDose == "" ||
                  this.DrugType == undefined ||
                  this.DrugType == ""
                ) {
                  for (let xp of this.ResponseData[0]["generic"]) {
                    if (xp["prerset"].length > 0) {
                      if (
                        xp["prerset"][0]["DrugName"].toLowerCase() ==
                        this.DrugName.toLowerCase()
                      ) {
                        this.DrugQuant = xp["prerset"][0]["Quantity"];
                        this.DrugDose = xp["prerset"][0]["Strength"].replace(
                          " ",
                          ""
                        );
                        this.DrugType = xp["prerset"][0]["DoseForm"];
                        this.DrugCat = "Generic";
                        this.IsPresetFound = true;
                        //window.localStorage.setItem("IsPresetAvail", "True");
                        // window.localStorage.setItem("DrugQuant", this.DrugQuant);
                        // window.localStorage.setItem("DrugType", this.DrugType);
                        // window.localStorage.setItem("DrugDose", this.DrugDose);
                        break;
                      }
                    }
                  }
                }
              } catch { }
              try {
                if (this.IsPresetFound == false) {
                  //window.localStorage.setItem("IsPresetAvail", "False");
                  for (let xp of this.ResponseData[0]["brand"]) {
                    if (xp["prerset"].length > 0) {
                      if (
                        xp["prerset"][0]["DrugName"].toLowerCase() ==
                        this.DrugName.toLowerCase()
                      ) {
                        this.DrugQuant = xp["prerset"][0]["Quantity"];
                        this.DrugDose = xp["prerset"][0]["Strength"].replace(
                          " ",
                          ""
                        );
                        this.DrugType = xp["prerset"][0]["DoseForm"];
                        this.DrugCat = "Brand";
                        this.IsPresetFound = true;
                        // window.localStorage.setItem("IsPresetAvail", "True");
                        // window.localStorage.setItem("DrugQuant",this.DrugQuant);
                        // window.localStorage.setItem("DrugQuant",this.DrugQuant);
                        // window.localStorage.setItem("DrugType", this.DrugType);
                        // window.localStorage.setItem("DrugDose", this.DrugDose);
                        break;
                      }
                    }
                  }
                }
              } catch { }
              try {
                if (this.DrugTypeList.length <= 1) {
                  this.DrugNameTypeSingle = true;
                } else {
                  this.DrugNameTypeSingle = false;
                }
              } catch { }

              try {
                if (this.DrugDoseList.length > 0) {
                  this.DrugDose = this.DrugDoseList[0];
                  // window.localStorage.setItem("DrugDose", this.DrugDose);
                }
              } catch { }

              if (this.optionlist.length > 1) {
                this.DoseFormSingle = false;
              } else {
                this.DoseFormSingle = true;
              }

              if (this.DrugDoseList.length > 1) {
                this.StrengthSingle = false;
              } else {
                this.StrengthSingle = true;
              }
              if (this.DrugCat.toLocaleLowerCase() == "generic") {
                this.Swap_Drug_With_MultiSource =
                  this.SelectedDrug + " (Generic Drug)";
              } else {
                this.Swap_Drug_With_MultiSource =
                  this.SelectedDrug + " (Brand Drug)";
              }
            }
          },
          (error) => { }
        );
    } catch { }
  }
  InitQuantityList() {
    try {
      this.DrugQuantityList = [];
      let _DoseFilter_1 = this.DoseDescriptor_JSON.filter((item) => {
        return item["Descrption"].toLowerCase() == this.DrugType.toLowerCase();
      });
      let DoseForm = _DoseFilter_1[0]["Dose"];
      let APIResponse = this.ResponseData;
      this._NameFilterForQuantity = [];
      if (this.DrugCat.toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(
          (item) => {
            return (
              item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
            );
          }
        );
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter((item) => {
          return (
            item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          (item) => {
            return item["dose"].toLowerCase() == DoseForm.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter((item) => {
            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              this.DrugDose.toLowerCase().replace(/\s/g, "")
            );
          });
          if (_StrengthFilter.length > 0) {
            for (let xqx of _StrengthFilter[0]["Quantity"]) {
              if (xqx.Quantity != 0) {
                this.DrugQuantityList.push(parseInt(xqx["Quantity"]));
              }
            }
          }
          if (this.IsPresetFound == false) {
            if (this.DrugQuantityList.length > 0) {
              this.DrugQuant = this.DrugQuantityList[0];
              // 0
            }
            if (
              this.DrugQuant == "" ||
              this.DrugQuant == null ||
              this.DrugQuant == undefined
            ) {
              if (this.DrugQuantityList.length > 0) {
                this.DrugQuant = this.DrugQuantityList[0];
                // window.localStorage.setItem("DrugQuant", this.DrugQuant);
              } else {
                this.DrugQuant = 10;
                // window.localStorage.setItem("DrugQuant", this.DrugQuant);
              }
            }
          }
        }
      }
    } catch { }
    if (
      this.DrugQuant == undefined ||
      this.DrugQuant == "" ||
      this.DrugQuant == null
    ) {
      this.DrugQuant = "10";
    }
    if (this.DrugQuantityList.length == 0) {
      this.DrugQuantityList.push(this.DrugQuant);
    }
  }
  DrugNameType() {
    try {
      this.DrugTypeList = [];
      for (let ik = 0; ik < this.ResponseData[0]["generic"].length; ik++) {
        try {
          if (
            this.ResponseData[0]["generic"][ik][
              "sub_drug_name"
            ].toLowerCase() == this.DrugName.toLowerCase()
          ) {
          }
          this.DrugTypeList.push(
            this.ResponseData[0]["generic"][ik]["sub_drug_name"] +
            " (Generic Drug)"
          );
        } catch { }
      }
      for (let jik = 0; jik < this.ResponseData[0]["brand"].length; jik++) {
        try {
          if (
            this.ResponseData[0]["brand"][jik]["sub_drug_name"].toLowerCase() ==
            this.DrugName.toLowerCase()
          ) {
          }
          this.DrugTypeList.push(
            this.ResponseData[0]["brand"][jik]["sub_drug_name"] +
            " (Brand Drug)"
          );
        } catch { }
      }
    } catch { }
    try {
      if (this.DrugTypeList.size <= 1) {
        this.DrugNameTypeSingle = true;
      } else {
        this.DrugNameTypeSingle = false;
      }
    } catch { }
  }
  ZipCodeValidate(event): boolean {
    this.ClearErrorClass();
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    $("#pick_location").prop("checked", false);
    return true;
  }
  zipCodeLenthInit() {
    let zipLen = new String($("#getzipcodeTop").val()).length;
    if (zipLen < 5) {
      $("#pick_location").prop("checked", false);
    }
  }
  ClearErrorClass() {
    this.ZipCodeMessage = "";
    $(".PharmacyNear").removeClass("error_msg");
  }
  GetAutoLocation(e) {
    var AutoLocate = e.target.checked;
    if (AutoLocate == true) {
      try {
        this.ZipCodeMessage = "";
        $(".PharmacyNear").removeClass("error_msg");
        this.googleAnalyticsService.eventEmitter(
          "Click for Auto Location",
          "User Current Co-Ordinate",
          window.localStorage.getItem("_LocalLat") +
          "," +
          window.localStorage.getItem("_LocalLang"),
          0
        );
        window.localStorage.setItem(
          "UserLat",
          window.localStorage.getItem("_LocalLat")
        );
        window.localStorage.setItem(
          "UserLong",
          window.localStorage.getItem("_LocalLang")
        );
        this.GetLatLongByCo_ordinate(
          window.localStorage.getItem("_LocalLat"),
          window.localStorage.getItem("_LocalLang")
        );
      } catch { }
    } else {
      this._UserZip = null;
      this.IsAutoLocation = "Not Found";
      this._CurrLocation = null;
      window.localStorage.setItem("UserLat", null);
      window.localStorage.setItem("UserLong", null);
      window.localStorage.setItem("userZipCode", null);
    }
  }
  // public getPosition1(): Observable<Position> {
  //   return Observable.create((observer) => {
  //     navigator.geolocation.watchPosition((pos: Position) => {
  //       observer.next(pos);
  //     }),
  //       () => {
  //         console.log("Position is not available");
  //       },
  //     {
  //       enableHighAccuracy: true,
  //     };
  //   });
  // }

  GetLatLongByCo_ordinate(lat: string, long: string) {
    if (lat == "" || lat == undefined || lat == null) {
      return false;
    }
    this.ms
      .GetLocationByLatLongWithSensor(lat, long)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        try {
          for (let ix of data.results) {
            if (ix["types"][0] == "street_address") {
              let tempaddress = "";
              for (
                let ad = 1;
                ad < ix["formatted_address"].split(",").length;
                ad++
              ) {
                if (
                  tempaddress == "" ||
                  tempaddress == null ||
                  tempaddress == undefined
                ) {
                  tempaddress = ix["formatted_address"].split(",")[ad];
                } else {
                  tempaddress =
                    tempaddress + "," + ix["formatted_address"].split(",")[ad];
                }
              }
              this._CurrLocation = tempaddress; //ix["formatted_address"];
              window.localStorage.setItem(
                "UserCurrentAddress",
                this._CurrLocation
              );
              break;
            }
          }
          try {
            if (data.results.length > 0) {
              for (let ix of data.results[0]["address_components"]) {
                if (ix["types"] == "postal_code") {
                  window.localStorage.setItem("userZipCode", ix["long_name"]);
                  this._UserZip = ix["long_name"];
                  this.IsAutoLocation = "Found";
                  break;
                }
              }
            }
          } catch { }
        } catch { }
      });
  }
  GetLatLongByZipCode(ZipCode: string) {
    let _Zip = "";
    if (String(ZipCode).length == 4) {
      _Zip = "0" + String(ZipCode);
    } else {
      _Zip = String(ZipCode);
    }
    //--------------------Reset pharmacy search params--------------
    window.localStorage.setItem("SelectedDrugName", this.SelectedDrug);
    window.localStorage.setItem("DrugType", this.DrugType);
    window.localStorage.setItem("DrugDose", this.DrugDose);
    window.localStorage.setItem("DrugQuant", this.DrugQuant);
    window.localStorage.setItem("IsBG", this.DrugCat);
    window.localStorage.setItem("userZipCode", _Zip);
    // window.localStorage.setItem("DrugDose", this.DrugDose);
    // window.localStorage.setItem("DrugType", this.DrugType);
    this.ms
      .GetCoordinatebyzip(_Zip)
      // .pipe(map((res) => res.json()))
      .subscribe((data) => {
        try {
          if (data.results.length > 0) {
            this._CurrLocation = data.results[0]["formatted_address"];
            window.localStorage.setItem(
              "UserLat",
              data.results[0].geometry.location.lat
            );
            window.localStorage.setItem(
              "UserLong",
              data.results[0].geometry.location.lng
            );
            window.localStorage.setItem("userZipCode", _Zip);
            window.localStorage.setItem(
              "UserCurrentAddress",
              this._CurrLocation
            );
            let Pharmacy_Request_Params =
              window.localStorage.getItem("SelectedDrugName") +
              "|" +
              window.localStorage.getItem("DrugType") +
              "|" +
              window.localStorage.getItem("DrugDose") +
              "|" +
              window.localStorage.getItem("DrugQuant") +
              "|" +
              window.localStorage.getItem("IsBG") +
              "|" +
              window.localStorage.getItem("userZipCode");
            this.googleAnalyticsService.eventEmitter(
              "Drug Details Pop Up",
              "Click to Load Pharmacy",
              Pharmacy_Request_Params,
              0
            );
            this.ShowDrugdetails = false;
            $("body").removeAttr("style");
            if (this.router.url.toLocaleLowerCase().indexOf("pharmacy") > -1) {
              let BaseURL = this.router["location"]["_platformStrategy"][
                "_platformLocation"
              ]["location"]["origin"];
              // this.router.url ==BaseURL+"/Pharmacy"
              window.location.href = BaseURL + "/Pharmacy";
            } else {
              this.router.navigate(["/Pharmacy"]);
            }
          } else {
            _Zip = String(ZipCode);
            this._UserZip = _Zip;
            this._CurrLocation = this._UserZip;
            this.ZipCodeMessage = "Please enter valid zipcode";
            $(".PharmacyNear").addClass("error_msg");
          }
        } catch { }
      });
  }
  getLock() { }

  //+++--------------------------------Drug Details Popup UI-------------------------+++//
  UpdateDrugParamsWithSwaping(_drugname, _sourcetype) {
    try {
      this.DrugName = _drugname;
      window.localStorage.setItem("SelectedDrugName", this.DrugName);
      if (_sourcetype.toLowerCase() == "generic") {
        let FilterItem = this.ResponseData[0]["generic"].filter((item) => {
          return item["sub_drug_name"].toLowerCase() == _drugname.toLowerCase();
        });
        if (FilterItem.length > 0) {
          this.optionlist = [];
          for (let x of FilterItem[0]["types"]) {
            try {
              let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
                return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              });
              if (_DoseFilter.length > 0) {
                this.optionlist.push({
                  value: _DoseFilter[0]["Descrption"],
                  text: _DoseFilter[0]["Descrption"],
                });
              } else {
                this.optionlist.push({ value: x.dose, text: x.dose });
              }
            } catch {
              this.optionlist.push({ value: x.dose, text: x.dose });
            }
          }
          try {
            if (this.optionlist.length <= 1) {
              this.DrugFormSingle = true;
            } else {
              this.DrugFormSingle = false;
            }
          } catch { }
          this.UpdateDoseByFormTypeSwaping(this.optionlist[0].value);
        }
      } else {
        let FilterItem = this.ResponseData[0]["brand"].filter((item) => {
          return item["sub_drug_name"].toLowerCase() == _drugname.toLowerCase();
        });
        if (FilterItem.length > 0) {
          this.optionlist = [];
          for (let x of FilterItem[0]["types"]) {
            try {
              let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
                return item["Dose"].toLowerCase() == x.dose.toLowerCase();
              });
              if (_DoseFilter.length > 0) {
                this.optionlist.push({
                  value: _DoseFilter[0]["Descrption"],
                  text: _DoseFilter[0]["Descrption"],
                });
              } else {
                this.optionlist.push({ value: x.dose, text: x.dose });
              }
            } catch {
              this.optionlist.push({ value: x.dose, text: x.dose });
            }
          }
          this.UpdateDoseByFormTypeSwaping(this.optionlist[0].value);
        }
      }

      if (this.optionlist.length > 1) {
        this.DoseFormSingle = false;
      } else {
        this.DoseFormSingle = true;
      }
      if (this.DrugDoseList.length > 1) {
        this.StrengthSingle = false;
      } else {
        this.StrengthSingle = true;
      }
      this.DrugType = this.optionlist[0].value;
      this.DrugDose = this.DrugDoseList[0].replace(" ", "");
      // window.localStorage.setItem("DrugDose", this.DrugDose);
      // window.localStorage.setItem("DrugType", this.DrugType);
      this.selectedValue = this.optionlist[0].value;
    } catch { }
  }
  UpdateDoseByFormTypeSwaping(_doseform) {
    let tempdoseform = "";
    try {
      let _DoseFilter = this.DoseDescriptor_JSON.filter((item) => {
        return item["Descrption"].toLowerCase() == _doseform.toLowerCase();
      });
      if (_DoseFilter.length > 0) {
        tempdoseform = _DoseFilter[0]["Dose"];
      } else {
        tempdoseform = _doseform;
      }
    } catch {
      tempdoseform = _doseform;
    }

    this.DrugType = _doseform;
    window.localStorage.setItem("DrugType", _doseform);
    this.DrugDoseList = [];
    this.selectedValue = _doseform;
    try {
      if (this.ResponseData.length > 0) {
        if (this.DrugCat.toLowerCase() == "generic") {
          let _NameFilter = this.ResponseData[0]["generic"].filter((item) => {
            return (
              item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
            );
          });
          let _it = -1;
          for (let x of _NameFilter[0]["types"]) {
            try {
              _it += 1;
              if (x.dose == tempdoseform) {
                for (let x of _NameFilter[0]["types"][_it]["strength"]) {
                  if (this.ms.IsNullOrEmptyField(x["Strength"]) == false) {
                    this.DrugDoseList.push(x["Strength"]);
                  }
                }
                break;
              }
            } catch { }
          }
        } else {
          let _NameFilter = this.ResponseData[0]["brand"].filter((item) => {
            return (
              item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
            );
          });
          let _it = -1;
          for (let x of _NameFilter[0]["types"]) {
            try {
              _it += 1;
              if (x.dose == tempdoseform) {
                for (let x of _NameFilter[0]["types"][_it]["strength"]) {
                  this.DrugDoseList.push(x["Strength"]);
                }
                break;
              }
            } catch { }
          }
        }
        if (this.DrugDoseList.length > 0) {
          this.DrugDose = this.DrugDoseList[0].replace(" ", "");
        } else {
          this.DrugDose = "";
        }

        window.localStorage.setItem("DrugDose", this.DrugDose);
        window.localStorage.setItem("DrugType", _doseform);
        this.selectedValue = _doseform;
        if (this.DrugDoseList.length <= 1) {
          this.StrengthSingle = true;
        } else {
          this.StrengthSingle = false;
        }
      }
    } catch { }
    this.UpdateDrugQuantityOnTopChange();
  }
  UpdateDrugQuantityOnTopChange() {
    try {
      this.DrugQuantityList = [];
      let _DoseFilter_1 = this.DoseDescriptor_JSON.filter((item) => {
        return item["Descrption"].toLowerCase() == this.DrugType.toLowerCase();
      });
      let DoseForm = _DoseFilter_1[0]["Dose"];
      let APIResponse = this.ResponseData;
      this._NameFilterForQuantity = [];
      if (this.DrugCat.toLowerCase() == "generic") {
        this._NameFilterForQuantity = APIResponse[0]["generic"].filter(
          (item) => {
            return (
              item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
            );
          }
        );
      } else {
        this._NameFilterForQuantity = APIResponse[0]["brand"].filter((item) => {
          return (
            item["sub_drug_name"].toLowerCase() == this.DrugName.toLowerCase()
          );
        });
      }
      if (this._NameFilterForQuantity.length > 0) {
        let _DoseFilter = this._NameFilterForQuantity[0]["types"].filter(
          (item) => {
            return item["dose"].toLowerCase() == DoseForm.toLowerCase();
          }
        );
        if (_DoseFilter.length > 0) {
          let _StrengthFilter = _DoseFilter[0]["strength"].filter((item) => {
            return (
              item["Strength"].toLowerCase().replace(/\s/g, "") ==
              this.DrugDose.toLowerCase().replace(/\s/g, "")
            );
          });
          if (_StrengthFilter.length > 0) {
            for (let xqx of _StrengthFilter[0]["Quantity"]) {
              if (xqx.Quantity != 0) {
                this.DrugQuantityList.push(parseInt(xqx["Quantity"]));
              }
            }
          }
          this.DrugQuantityList.sort(function (a, b) {
            return a - b;
          });
          if (this.DrugQuantityList.length > 0) {
            this.DrugQuant = this.DrugQuantityList[0];
          } else {
            this.DrugQuant = 10;
          }
          window.localStorage.setItem("DrugQuant", this.DrugQuant);
        }
      }
    } catch { }
  }
  DrugQuantityValidate(e: any) {
    this.Quantity_error = "";
    $("#Quantity_Manual").parent().removeClass("error_msg");
    var keyCode = e.which ? e.which : e.keyCode;
    if (keyCode != 8 && keyCode != 0 && (keyCode < 48 || keyCode > 57)) {
      return false;
    }
  }
  SubmitQuantity() {
    if (
      this.Quantity_Manual == null ||
      this.Quantity_Manual == "" ||
      this.Quantity_Manual == undefined
    ) {
      this.Quantity_error = "Please enter a quantity";
      $("#Quantity_Manual").parent().addClass("error_msg");
      return false;
    } else {
      this.googleAnalyticsService.eventEmitter(
        "Drug Quantity Pop Up",
        "Drug Quantity",
        this.DrugQuant + "<-->" + this.Quantity_Manual,
        0
      );
      this.ManualQuantity_box = false;
      this.DrugQuant = this.Quantity_Manual;
      // window.localStorage.setItem('DrugQuant', this.DrugQuant);
    }
  }
  Redirect_LoadPharmacy() {
    this.zipCodeLenth();
    let getLenth = this._UserZip.length;
    if (getLenth < 5) {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
      return false;
    }
    $(".homepage, html").removeAttr("style");
    this.Enter_Zip = false;
    if (this.ms.IsNullOrEmptyField(this._UserZip) == true) {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
    } else {
      this.GetLatLongByZipCode(this._UserZip);
      $("body").removeAttr("style");
    }
  }
  zipCodeLenth() {
    let zipLen = $("#getzipcodeTop").val();
    if (zipLen == "") {
      this.ZipCodeMessage = "Please enter valid zipcode";
      $(".PharmacyNear").addClass("error_msg");
    }
  }
  removileview() {
    $(".searchbox_home").removeClass("mobile_searchbox_home");
  }
  // change icon in password field
  changeIcon(val) {
    var getId = `#status${val}`;
    var getChangeId = $(getId).attr("class");
    if (val == 1 || val == 2 || val == 3 || val == 4 || val == 5) {
      if (getChangeId === "statusA eye_show") {
        $(getId).removeClass("eye_show");
        return false;
      }
      $(getId).addClass("statusA eye_show");
    }
  }

  showPassword() {
    if (!this.show_password) {
      this.show_password_msg = "Hide password"
    } else {
      this.show_password_msg = "Show password"
    }
    this.show_password = !this.show_password;



  }
  showCPassword() {
    if (!this.show_cpassword) {
      this.show_cpassword_msg = "Hide password"
    } if (this.show_cpassword) {
      this.show_cpassword_msg = "Show password"
    }
    this.show_cpassword = !this.show_cpassword;

  }

  showSigninPassword() {
    if (!this.show_signin_password) {
      this.show_signin_password_msg = "Hide password"
    } if (this.show_signin_password) {
      this.show_signin_password_msg = "Show password"
    }
    this.show_signin_password = !this.show_signin_password;
  }

  // go to top click on footer link
  onActivate(event) {
    window.scroll(0, 0);

    // condition for login page
    // if (window.location.pathname === '/enrollmentcenter') {
    //     this.loginPage = false;
    //     $('body').addClass('loginPage');
    // } else {
    //     this.loginPage = true;
    //     $('body').removeClass('loginPage');
    // }
  }
}
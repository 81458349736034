<!-- // caller section \\ -->
<section class="caller-section">
  <div class="comman-width">
    <div class="enrollparent">
      <div class="eccounts-section">
        <div class="eccountsbox">
          Total Enrollments: {{eccounts}}
        </div>
        <div class="eccountsbox">
          Total Paid Claims (#): {{totalPrescription}}
        </div>
        <div class="eccountsbox">
          Total Patient Savings ($): {{totalPatient}}
        </div>
      </div>
      <div class="inner-width">
        <h2><span>GlicRx Enrollment Center</span>Enter customer information here:</h2>

        <div class="formsec">
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="first_name" value="Shane" id="first_name"
              placeholder="First Name" autocomplete="off" (keyup)="removeerror($event)" />
            <span class="colorRed" style="display: none;" id="fnameerror">
              {{
              errormessageFName
              }}
            </span>
          </div>
          <div class="form-group">
            <input type="text" class="form-control" [(ngModel)]="last_name" value="Mike" id="last_name"
              placeholder="Last Name" autocomplete="off" (keyup)="removeerror($event)" />
            <span class="colorRed" style="display: none;" id="lnameerror">
              {{
              errormessageLName
              }}
            </span>
          </div>
          <div class="form-group">
            <input type="email" class="form-control" value="shane.mike@gmail.com" [(ngModel)]="emailmodel" id="email_"
              placeholder="Email Address" autocomplete="off" (keyup)="removeerror($event)" />
            <span class="colorRed" style="display: none;" id="emailerror">
              {{
              errormessageEmail
              }}
            </span>
          </div>
          <!-- <div class="form-group">
            <input type="text" class="form-control" id="phone_" name="mno_p" [(ngModel)]="phone_number"
              placeholder="Phone Number (optional)" (keypress)="removeerror($event)" (focus)="removeerror($event)"
              (keyup)="removeerror($event)" autocomplete="off" maxlength="14" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
            <span class="colorRed" style="display: none;" id="phoneerror">
              {{
              errormessagePhone
              }}
            </span>
          </div> -->
          <span class="colorRed" style="display: none;" id="apierror">
            {{
            errormessageApi
            }}
          </span>
          <div class="btn_desc">
            <a href="javascript:;" class="btnshover" (click)="validation()">Submit</a>
            <div *ngIf="loader" class="load-container">
              <div class="theme-loader"></div>
            </div>
            <a *ngIf="cardsuccess" href="javascript:;" class="btnshover success-btn"><img
                src="../assets/images/success-arrow.png" alt="" />Form submitted</a>
          </div>
        </div>
      </div>
    </div>

    <div class="white-bg second">
      <div class="inner-width">
        <div *ngIf="signatureLoader" class="load-container">
          <div class="theme-loader"></div>
        </div>
        <label class="checkbox-container">
          Include a personalized signature on the enrollment email sent to customers
          <input [checked]="signstatus" name="captcha" type="checkbox" (change)="changesignstatus()"
             />
          <span class="checkmark"></span>
        </label>
        <div class="signature">
          <a href="javascript:;" (click)="ShowSignaturePopup()">Update</a>
          <div class="address-section">
            <span class="name">{{brokername}}</span>
            <span><span>{{companyName}}</span> | {{phone}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="enrollparent past">
      <div class="inner-width">
        <h3>Past Enrollments
          <div *ngIf="resendemailloader" class="btn-container">
            <div class="theme-loader save"></div>
          </div>
        </h3>
        <div class="enroll-content">
          <table>
            <thead>
              <tr class="header">
                <th class="first">Date</th>
                <th class="second">First Name</th>
                <th class="third">Last Name</th>
                <th class="fourth">EmaiL</th>
                <th class="fifth">Phone</th>
                <th class="sixth">Actions</th>
              </tr>
            </thead>
            <tbody>
            <tr *ngFor="let items of historicallength | slice: (curPage * pageSize) - pageSize :curPage * pageSize">
              <td class="first">{{items.createdOn || '-'}}</td>
              <td class="second">{{items.fName || '-'}}</td>
              <td class="third">{{items.lName || '-'}}</td>
              <td class="fourth">{{items.email}}</td>
              <td class="fifth">{{returnphoneno(items.phone)}}</td>
              <td class="sixth">
                <span class="edit-image">
                  <img (click)="ShowPastEnrollPopup(items.fName,items.lName,items.email,items.phone, items.userRowId)" class="img-gap" src="../../assets/images/enroll_edit.svg" alt="edit" />
                  <span>Edit</span>
                </span>
                <span class="mail-image">
                  <img (click)="resendklaviyo(items.email, items.fName, items.lName, '' )" src="../../assets/images/enroll_email.svg" alt="edit" />
                  <span>Resend</span>
                </span>
              </td>
            </tr>
            <tr *ngIf="nodatafound">
              <td colspan="6" class="first">No Records Found</td>
            </tr>
            </tbody>
          </table>  
        </div>

        <div class="pagination" *ngIf="historicallength.length">
          <a href="javascript:void('')" (click)="firstPagin(1)" class="first">First</a>
          <button class="prev" [disabled]="curPage == 1" (click)="prevPage(curPage - 1)">PREV</button>
          <ul>
              <li *ngFor="let count of showPagin"><span *ngIf="count == '...'" href="javascript:void('')">{{count}}</span><a *ngIf="count != '...'" (click)="curPage = count" href="javascript:void('')"><span class="pagin" (click)="activeClass(count)">{{count}}</span></a></li>
          </ul>
          <button class="next" [disabled]="curPage >= historicallength.length/pageSize" (click)="nextPage(curPage + 1)">NEXT</button>
          <a class="last" (click)="lastPagin(historicallength.length/pageSize)" href="javascript:void('')">Last</a>
        </div>        


      </div>
    </div>
    <div class="search_pharmacy_popup signature" *ngIf="ShowSignature">
      <div class="search_pharmacy_select">
        <div class="close_search_pharmacy_popup" (click)="CloseSignaturePopup()"></div>
        <h2>Add your signature</h2>
        <div class="form_sec">
          <div class="changepassword_main">
            <div class="input_box">
              <div class="field">
                <input type="text" id="currentFullName" name="currentFullName" placeholder="Full Name"
                  [(ngModel)]="current_full_name" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                <span class="colorRed" style="display: none;" id="errormessageFullName">
                  {{
                  errormessageFullName
                  }}
                </span>
              </div>
              <div class="field">
                <input type="text" id="currentCompanyName" name="currentCompanyName" placeholder="Company Name"
                  [(ngModel)]="current_company_name" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                <span class="colorRed" style="display: none;" id="errormessageCompany">
                  {{
                  errormessageCompany
                  }}
                </span>
              </div>
              <div class="field">
                <input type="email" id="currentEmail" [(ngModel)]="emailmodell" placeholder="Email Address"
                  autocomplete="off" (keyup)="Keyup_ClearField($event)" />
                <span class="colorRed" style="display: none;" id="errormessageEmail">
                  {{
                  errormessageEmail
                  }}
                </span>
              </div>
              <div class="field">
                <input type="text" id="currentPhoneNumber" name="currentPhoneNumber" placeholder="Phone Number"
                  [(ngModel)]="current_Phone_number" autocomplete="off" (keyup)="Keyup_ClearField($event)" required />
                <span class="colorRed" style="display: none;" id="errormessagePhone">
                  {{
                  errormessagePhone
                  }}
                </span>
              </div>
              <div class="btn-section">
                <a *ngIf="formsuccess" [ngClass]="{'animate': btnLoader === true }" (click)="validateSignature()"
                  href="javascript:;" class="btnshover">Submit</a>
                <div *ngIf="btnLoader" class="btn-container">
                  <div class="theme-loader"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search_pharmacy_popup broker_info" *ngIf="BrokerInfo">
      <div class="search_pharmacy_select">
        <div class="close_search_pharmacy_popup" (click)="CloseInfoPopup()"></div>
        <div class="top-section autoheightinfo">
          <h2>You can now send a personalized signature with your enrollment emails</h2>
          <div>
            <img src="../assets/images/sign-graphic.svg" alt="#" />
          </div>
        </div>
        <div class="form_sec">
          <div class="changepassword_main">
            <div class="input_box">
              <div class="field">
                <label class="checkbox-container">
                  Don't show me again
                  <input [checked]="!BrokerInfo"  name="show" type="checkbox" (change)="markunderstood($event)"
                    />
                  <span class="checkmark"></span>
                </label>      
              </div>
              <div class="btn-section">
                <a *ngIf="formsuccess" (click)="CloseInfoPopup()" href="javascript:;" class="btnshover">Ok, Got it</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search_pharmacy_popup signature past_enroll" *ngIf="ShowPastEnroll">
      <div class="search_pharmacy_select">
        <div class="close_search_pharmacy_popup" (click)="ClosePastEnrollPopup()"></div>
        <h2>Edit Details</h2>
        <div class="form_sec">
          <div class="formsec">
            <div class="form-group full first-row">
              <div class="adjust">
              <input type="text" class="form-control" [(ngModel)]="enroll_first_name" value="Shane" id="enroll_first_name"
                placeholder="First Name" autocomplete="off" (keyup)="removeerror($event)" />
              <span class="colorRed" style="display: none;" id="enroll_fnameerror">
                {{
                errormessageFName
                }}
              </span>
            </div>
            <div class="adjust">
              <input type="text" class="form-control" [(ngModel)]="enroll_last_name" value="Mike" id="enroll_last_name"
                placeholder="Last Name" autocomplete="off" (keyup)="removeerror($event)" />
              <span class="colorRed" style="display: none;" id="enroll_lnameerror">
                {{
                errormessageLName
                }}
              </span>
            </div>
          </div>
          <div class="form-group full">
              <input type="email" class="form-control" value="shane.mike@gmail.com" [(ngModel)]="enroll_emailmodel" id="enroll_email_"
                placeholder="Email Address" autocomplete="off" (keyup)="removeerror($event)" />
              <span class="colorRed" style="display: none;" id="enroll_emailerror">
                {{
                errormessageEmail
                }}
              </span>
            </div>
            <!-- <div class="form-group full">
              <input type="text" class="form-control" id="enroll_phone_" name="mno_p" [(ngModel)]="enroll_phone_number"
                placeholder="Phone Number (optional)" (keypress)="removeerror($event)" (focus)="removeerror($event)"
                (keyup)="removeerror($event)" autocomplete="off" maxlength="15" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
              <span class="colorRed" style="display: none;" id="enroll_phoneerror">
                {{
                errormessagePhone
                }}
              </span>
            </div> -->
            <span class="colorRed" style="display: none;" id="enroll_apierror">
              {{
              errormessageApi
              }}
            </span>
            <div class="btn_desc">
              <a href="javascript:;" [ngClass]="{'animate': saveLoader === true }" class="btnshover" (click)="Save('save')">Save</a>
              <div *ngIf="saveLoader" class="btn-container">
                <div class="theme-loader save"></div>
              </div>
              <a href="javascript:;" [ngClass]="{'animate': resendLoader === true }" class="btnshover resend" (click)="Save('resend')">Save & Resend</a>
              <div *ngIf="resendLoader" class="btn-container">
                <div class="theme-loader resend"></div>
              </div>
              <a *ngIf="saveFormSuccess" href="javascript:;" class="btnshover success-btn"><img
                  src="../assets/images/success-arrow.png" alt="" />{{pastsuccessmessage}}</a>
            </div>
            <span class="note"><span>Please Note:</span> ‘Save & Resend' will send the customer a new enrollment email/sms</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
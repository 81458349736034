<div id="printdiv">
  <div style="padding-top: 20px;font-weight:700;color: #27524B;font-size: 20px;">
  {{medicineName}}
  </div>
  <table width="100%" style="max-width:400px;margin-left:auto;margin-right:auto" align="center" class="printcard">
    <tr>
      <td style="padding-top:23px">
        <table width="400" align="center" cellpadding="0" cellspacing="0"
          style="background: #fff;border-radius: 15px;box-shadow: 0 0 12px #ccc;width: 400px;" class="firsttable">
          <tr>
            <td style="padding-left: 15px;padding-bottom: 15px;padding-top: 15px;border-bottom: 2px solid #25534B;">

              <img
                src="../assets/images/header_logo.svg"
                width="190" height="" style="margin-left: -38px;" alt="logo.png" title="logo.png">
            </td>
            <td align="right" style="font-family: Roboto;font-size: 13px;font-weight: normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: center;
                            color: #25534B;border-bottom: 2px solid #25534B;">
              Member Support<br>
              <span>(800) 984-4031</span>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table cellpadding="0" cellspacing="0" style="width: 100%;">
                <tr>
                  <td style="width:50%;min-width:130px;padding-top: 15px;padding-bottom: 15px;padding-left: 30px;font-family: Roboto;font-size: 13px;font-weight: normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                            color: #FF8B6C;">
                    BIN<br>
                    <span style="font-family: Roboto;font-size: 16px;font-weight: 700;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                                color: #27524B;">{{bin}}</span>
                  </td>
                  <td style="width:152px;min-width:50%;padding-top: 15px;padding-bottom: 15px;padding-left: 8px;font-family: Roboto;font-size: 13px;font-weight: normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                            color: #FF8B6C;">
                    Group # (Access Code)<br>
                    <span style="font-family: Roboto;font-size: 16px;font-weight: 700;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                                color: #27524B;">{{groupno_valid}}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table cellpadding="0" cellspacing="0" style="width: 100%;">
                <tr>
                  <td style="width:50%;min-width:130px;padding-top: 0;padding-bottom: 15px;padding-left:30px;font-family: Roboto;font-size: 13px;font-weight: normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                            color: #FF8B6C;">
                    PCN<br>
                    <span style="font-family: Roboto;font-size: 16px;font-weight: 700;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                                color: #27524B;">{{pcn}}</span>
                  </td>
                  <td style="width:50%;min-width:152px;padding-top: 0;padding-bottom: 15px;padding-left: 8px;font-family: Roboto;font-size: 13px;font-weight: normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                            color: #FF8B6C;">
                    Member ID<br>
                    <span style="font-family: Roboto;font-size: 16px;font-weight: 700;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                                color: #27524B">{{memberid}}</span>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <table cellpadding="0" cellspacing="0"
                style="width: 100%;background: #27524B;border-radius: 0 0 15px 15px;height: 36px;">
                <tr>
                  <td valign="center"
                    style="font-family: Roboto;font-size: 12px;font-weight: normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                        color: #fff;padding-left: 8px;width:70%;min-width:70%;max-width:70%;padding-bottom:5px;padding-top:5px;vertical-align:middle;">

                    <p style="margin-top: 0;font-size: 13px;"><span style="font-weight: bold;">This is not insurance.</span> This card is free</p>
                  </td>
                  <td style="width:30%;min-width:30%;max-width:30%;padding-bottom:5px;padding-top:5px">
                    <table cellpadding="0" cellspacing="0" style="width:100%">
                      <tr>
                        <td style="font-family:Roboto;font-size: 12px;font-weight:normal;font-stretch: normal;font-style: normal;letter-spacing: -0.4px;text-align: left;
                                    color: #fff;">
                          Powered By
                        </td>
                      </tr>
                      <tr>
                        <td border="0" style="background:transparent;">
                          <a href="https://glichealth.com/" target="_blank" style="border:none;text-decoration:none"><img border="0"
                              src="http://cdn.mcauto-images-production.sendgrid.net/436e27025ec79248/a6efda1c-e3b6-4e41-aa75-53795612d46c/268x44.png"
                              width="65"
                              style="width: 65px;background:transparent;max-width:65px;border:none;outline:none;border:0"
                              alt=""></a>
                        </td>
                      </tr>
                    </table>

                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

  <table width="100%" style="max-width:400px;margin-left:auto;margin-right:auto;margin-top:23px" align="center">
    <tr>
      <td>
        <table width="400" align="center" cellpadding="0" cellspacing="0" class="secondtable"
          style="background: #fff;border-radius: 15px;box-shadow: 0 0 12px #ccc;width: 400px;padding-top:10px">

          <tr>
            <td
              style="font-family: Roboto;font-size: 12px;font-weight: normal;padding-top:0;padding-left:15px;padding-bottom:0;min-width:150px;max-width:150px;vertical-align:top;color:#27524B;border-right:1px solid #eee;">

              <p style="padding:0;margin:0;font-size:12px;padding-top: 10px;">Accepted at approx 35,000 pharmacies 
                nationwide</p>
              <p style="padding:0;margin:0;margin-top:5px">
                <img
                  src="http://cdn.mcauto-images-production.sendgrid.net/436e27025ec79248/e6a59532-f2b5-49f2-bea5-b77c14a7836d/130x27.png" alt="">
              </p>
              <p
                style="font-family: Roboto;font-size: 12px;font-weight: normal;padding-top:0;padding-bottom:0;min-width:192px;max-width:192px;vertical-align:top;color:#27524B;border-right:1px solid #eee;">
                For more information, to report
                issues, and to find participating
                pharmacies near you, visit <a href="https://glicrx.com"
                  style="color:#000;font-weight:bold;text-decoration:none;">glicrx.com</a>
                or call <a href="tel:8009844031" style="color:#000;font-weight:bold;text-decoration:none;">(800)
                  984-4031</a>.

              </p>
            </td>
            <td
              style="font-family: Roboto;font-size: 12px;font-weight: normal;padding-top:0;padding-left:15px;padding-bottom:0;min-width:130px;max-width:140px;vertical-align:top;color:#27524B;padding-right:10px;">
              <p style="padding:0;margin:0;font-size: 12px;padding-top: 10px">
                <strong style="color:#000;">Member Instructions</strong><br>
                1. Present card to pharmacist.<br>
                2. Ask for discount on each
                prescription and <strong style="color:#000;">save up to $95!</strong>
              </p>

              <p style="padding:0;margin:0;font-size: 12px;margin-top:8px;">
                <strong style="color:#000;">Pharmacist Instructions</strong><br>
                This card gives holder discounts
                through the BIN, PCN, Group on
                the front. If you need assistance
                call <a href="tel:8778007820" style="color:#000;font-weight:bold;text-decoration:none;">(877)
                  800-7820</a>.

              </p>



            </td>

          </tr>
          <tr>
            <td colspan="2"
              style="font-family: Roboto;font-size: 12px;font-weight: normal;padding-top:0px;padding-left:15px;padding-bottom:10px;min-width:150px;max-width:150px;vertical-align:top;color:#27524B;padding-right:15px;padding-top:10px">
              The GlicRx Program, prices, covered drugs, and pharmacies are
              subject to change. By using this card, you agree to the terms & privacy
              policy found at <a href="https://glicrx.com/terms-and-conditions"
                style="color:#27524B;text-decoration:none;">glicrx.com/terms</a>. Administered by Glic Health LLC,
              One Marina Park Drive, Ste 1410, Boston MA 02210 - (844) 944-4542
              <br><br>
              <p style="font-size:10px">CVS, KROGER, WALMART and WALGREENS logos are trademarks and/or registered
                trademarks of CVS, KROGER, WALMART and WALGREENS, respectively.</p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
</div>
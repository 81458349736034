import { Injectable, EventEmitter, Output } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
// import { analyzeAndValidateNgModules, Type } from "@angular/compiler";
import { Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { get } from "jquery";
import { GeoLocationData } from './geo-location-data'; // Adjust the path as per your project structure

// import {
//   prepareSyntheticListenerFunctionName,
//   prepareSyntheticListenerName,
// } from "@angular/compiler/src/render3/util";

@Injectable({
  providedIn: "root",
})
export class MedicinService {
  private subject = new Subject<any>();
  APIResponse: any;
  BaseURL1: string = "https://mysql-hdrx.23byt.es/api/v1/";
  BaseURL2: string = "https://mysql-hdrx.23byt.es/api/v2/";
  BaseURL3: string = "https://mysql-hdrx.23byt.es/api/v3/";
  GeoBaseURL: string = "https://maps.googleapis.com/maps/api/geocode/json?";
  GeoAPIKey = "AIzaSyC46tn0xWqdGs1bD60z4cFakEh510tR3dw";
  DistanceBaseURL =
    "https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&";
  DistanceAPIKey = "AIzaSyC46tn0xWqdGs1bD60z4cFakEh510tR3dw";

  // https://maps.googleapis.com/maps/api/js?key=AIzaSyC46tn0xWqdGs1bD60z4cFakEh510tR3dw

  showloginbox = false;
  signup_fields = false;
  signin_fields = false;

  constructor(public http1: HttpClient, public http: HttpClient) { }

  // PostDomainPDF(ftext, Pdf64Code) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   var ftextE = encodeURIComponent(ftext);
  //   return this.http.post(
  //     this.BaseURL2 + "Savepdf",
  //     "ftext=" + ftextE + "&pdf64=" + Pdf64Code,
  //     { headers: headers }
  //   );
  // }
  PostDomainPDF(ftext, Pdf64Code): Observable<any> {
    var ftextE = encodeURIComponent(ftext);
    let formData = {"ftext":ftextE, "pdf64":Pdf64Code};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "Savepdf", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // Domain affiliate post data page call
  // PostDomainAffiliate(vender, group, pcn, subText, Image64Code, Desc) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   var venderE = encodeURIComponent(vender);
  //   var groupE = encodeURIComponent(group);
  //   var pcnE = encodeURIComponent(pcn);
  //   var subTextE = encodeURIComponent(subText);
  //   return this.http.post(
  //     this.BaseURL2 + "SaveAffilliate",
  //     "vname=" +
  //     venderE +
  //     "&group=" +
  //     groupE +
  //     "&pcn=" +
  //     pcnE +
  //     "&ftext=" +
  //     subTextE +
  //     "&image64=" +
  //     Image64Code +
  //     "&desc=" +
  //     Desc,
  //     { headers: headers }
  //   );
  // }
  PostDomainAffiliate(vender, group, pcn, subText, Image64Code, Desc): Observable<any> {
    var venderE = encodeURIComponent(vender);
    var groupE = encodeURIComponent(group);
    var pcnE = encodeURIComponent(pcn);
    var subTextE = encodeURIComponent(subText);
    let formData = {"vname":venderE, "group":groupE, "pcn":pcnE, "ftext":subTextE, "Image64Code":Image64Code, "desc":Desc};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "SaveAffilliate", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // Get Pdf name and open in new window

  // OpenPdf(ftext) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   var subTextE = encodeURIComponent(ftext);
  //   return this.http.post(
  //     this.BaseURL2 + "GetPDF",
  //     "&ftext=" + subTextE + "&pdf64=" + "pdf",
  //     { headers: headers }
  //   );
  // }
  OpenPdf(ftext): Observable<any> {
    var subTextE = encodeURIComponent(ftext);
    let formData = {"ftext":subTextE, "pdf64":"pdf"};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "GetPDF", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // Domain affiliate get Data page call
  // GetDomainAffiliate(stext) {
  //   var Gstext = encodeURIComponent(stext);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL2 + "GetAffilliate", "stext=" + Gstext, {
  //     headers: headers,
  //   });
  // }
  GetDomainAffiliate(stext): Observable<any> {
    var Gstext = encodeURIComponent(stext);
    let formData = {"stext":Gstext};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "GetAffilliate", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // Domain affiliate search page call
  // SearchDomainAffiliate(stext) {
  //   var Gstext = encodeURIComponent(stext);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL2 + "GetAffilliate", "stext=" + Gstext, {
  //     headers: headers,
  //   });
  // }
  SearchDomainAffiliate(stext): Observable<any> {
    var Gstext = encodeURIComponent(stext);
    let formData = {"stext":Gstext};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "GetAffilliate", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // FAQ get data
  // GetFaq() {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL2 + "faqs", "DrugName=" + "test", {
  //     headers: headers,
  //   });
  // }
  GetFaq(): Observable<any> {
    let formData = {"DrugName":"test"};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "faqs ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  //Default Card Data
  // GetDefaultCard(AppName) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL2 + "defaultcard",
  //     "AppName=" + AppName + "&AppType=WebApp",
  //     { headers: headers }
  //   );
  // }
  GetDefaultCard(AppName): Observable<any> {
    let formData = {"AppName":AppName, "AppType":"WebApp"};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "defaultcard ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  SaveDeeplink(email: string, fname: string, lname: string, groupnumber?: string, deeplink?: string, ecenter?: string): Observable<any> {
    const key = btoa('glicrx:hj78y64re3kl899078h6');
    const headers = new HttpHeaders({
      'Authorization': 'Basic ' + key,
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
    });

    const body = `Fname=${fname}&Lname=${lname}&Email=${encodeURIComponent(email)}&Gn=${groupnumber}&DeepLink=${deeplink}&UserAgent=Web&Ecenter=${ecenter}`;

    const url = (location.host === 'glicrx.com' || location.host === 'www.glicrx.com') ?
      'https://cors.23byt.es/' + this.BaseURL2 + 'storedl' :
      'https://cors.23byt.es/' + this.BaseURL2 + 'storedl_dev';

    return this.http.post<any>(url, body, { headers });
  }
  //check login status last time
  // lastLoginStatus(getUserId, getUserEmail) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL2 + "VerifyUser",
  //     "UserId=" + getUserId + "&UserEmail=" + getUserEmail,
  //     { headers: headers }
  //   );
  // }
  lastLoginStatus(getUserId, getUserEmail): Observable<any> {
    let formData = {"UserId":getUserId, "UserEmail":getUserEmail};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "VerifyUser ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  brokerLogin(email, password) {
    let urlapi = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'https://cors.23byt.es/https://reporting.glichealth.com/api/v1/BrokerOauth' : 'https://cors.23byt.es/https://dev.glichealth.com/api/v1/BrokerOauth'
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    let body = {
      'email': email,
      'password': password
    }
    return this.http.post(
      urlapi,
      body,
      { headers: headers }
    );
  }
  eccount(email, groupnumberr, uid) {
    let urlapi = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'https://cors.23byt.es/https://reporting.glichealth.com/api/v1/Broker_Enrolls' : 'https://cors.23byt.es/https://dev.glichealth.com/api/v1/Broker_Enrolls'
    //let urlapi ='https://cors.23byt.es/https://dev.glichealth.com/api/v1/Broker_Enrolls'
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    let body = {
      'email': email,
      'groupnumber': groupnumberr,
      'userid': uid
    }
    return this.http.post(
      urlapi,
      body,
      { headers: headers }
    );
  }
  recoverAccesscode(email) {
    let urlapi = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'https://cors.23byt.es/https://reporting.glichealth.com/api/v1/broker_accesscode' : 'https://cors.23byt.es/https://dev.glichealth.com/api/v1/broker_accesscode'
    //let urlapi ='https://cors.23byt.es/https://dev.glichealth.com/api/v1/Broker_Enrolls'
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    let body = {
      'UserEmail': email,
      'RequestURL': window.location.href
    }
    return this.http.post(
      urlapi,
      body,
      { headers: headers }
    );
  }
  changesignstatus(id, email, groupnumberr, pref) {
    
    let urlapi = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'https://cors.23byt.es/https://reporting.glichealth.com/api/v1/brsignsetting ' : 'https://cors.23byt.es/https://dev.glichealth.com/api/v1/brsignsetting '
    //let urlapi ='https://cors.23byt.es/https://dev.glichealth.com/api/v1/Broker_Enrolls'
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    let body = {
      'id': id,
      'email': email,
      'gn': groupnumberr,
      'pref': pref
    }
    return this.http.post(
      urlapi,
      body,
      { headers: headers }
    );
  }
  addsignature(id, email, groupnumberr, sfullname, scompanyname, semail, sphone) {
    
    let urlapi = location.host == 'glicrx.com' || location.host == 'www.glicrx.com' ? 'https://cors.23byt.es/https://reporting.glichealth.com/api/v1/brsigndetail' : 'https://cors.23byt.es/https://dev.glichealth.com/api/v1/brsigndetail'
    //let urlapi ='https://cors.23byt.es/https://dev.glichealth.com/api/v1/Broker_Enrolls'
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    let body = {
      'id': id,
      'email': email,
      'gn': groupnumberr,
      'sfullname': sfullname,
      'scompanyname': scompanyname,
      'semail': semail,
      'sphone': sphone
    }
    return this.http.post(
      urlapi,
      body,
      { headers: headers }
    );
  }
  // SearchDrugList(searchtext) {
  //   var Encode_DrugName = encodeURIComponent(searchtext);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL3 + "searchdrug",
  //     "stext=" + Encode_DrugName,
  //     {
  //       headers: headers,
  //     }
  //   );
  // }
  SearchDrugList(searchtext): Observable<any> {
    var Encode_DrugName = encodeURIComponent(searchtext);
    let formData = {"stext":Encode_DrugName};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL3 + "searchdrug ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // GetPopularDrug() {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL3 + "populardrug", "DrugName=" + "test", {
  //     headers: headers,
  //   });
  // }
  GetPopularDrug(): Observable<any> {
    let formData = {"DrugName":"test"};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL3 + "populardrug ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // GetDrugDetails(DrugName) {
  //   var Encode_DrugName = encodeURIComponent(DrugName);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL3 + "drugcomponents",
  //     "DrugName=" + Encode_DrugName,
  //     { headers: headers }
  //   );
  // }
  // GetDrugDetails(DrugName): Observable<any> {
  //   var Encode_DrugName = encodeURIComponent(DrugName);
  //   let formData = {"DrugName":Encode_DrugName};
  //   const params = new HttpParams({ fromObject: formData });
  //   debugger;
  //   return this.http.post(this.BaseURL3 + "drugcomponents", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  // }
  
  GetDrugDetails(drugName: string): Observable<any> {
    // const encodeDrugName = encodeURIComponent(drugName);
    const params = new HttpParams().set('DrugName', drugName);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
    
    return this.http.post<any>(this.BaseURL3 + 'drugcomponents', params.toString(), { headers });
  }
  GetCoordinatebyzip(ZipCode): Observable<GeoLocationData>  {
    // return this.http.get(
    //   this.GeoBaseURL + "address=" + ZipCode + "&key=" + this.GeoAPIKey
    // );
    const url = `${this.GeoBaseURL}address=${ZipCode}&key=${this.GeoAPIKey}`;
    return this.http.get<GeoLocationData>(url); 
  }
  GetLocationByLatLongWithSensor(Lat, Long) {
    // return this.http.get(
    //   this.GeoBaseURL +
    //   "latlng=" +
    //   Lat +
    //   "," +
    //   Long +
    //   "&sensor=true&key=" +
    //   this.GeoAPIKey
    // );
    const url = `${this.GeoBaseURL}latlng=${Lat},${Long}&sensor=true&key=${this.GeoAPIKey}`;
    return this.http.get<GeoLocationData>(url);
  }
  // GetPharmacyList(
  //   Lat,
  //   Long,
  //   GPId,
  //   Quantity,
  //   Dosage,
  //   Type,
  //   MultiSource,
  //   DrugName,
  //   UserId,
  //   ZipCode,
  //   UserLocation,
  //   ResponseType
  // ) {
  //   var Encode_DrugName = encodeURIComponent(DrugName);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL3 + "pharmacy",
  //     "Lat=" +
  //     Lat +
  //     "&Long=" +
  //     Long +
  //     "&GpId=" +
  //     GPId +
  //     "&Quant=" +
  //     window.localStorage.getItem('DrugQuant') +
  //     "&Dosage=" +
  //     Dosage +
  //     "&Type=" +
  //     Type +
  //     "&MultiSource=" +
  //     MultiSource +
  //     "&DrugName=" +
  //     Encode_DrugName +
  //     "&UserId=" +
  //     UserId +
  //     "&ZipCode=" +
  //     ZipCode +
  //     "&Userlocation=" +
  //     UserLocation +
  //     "&ResponseType=" +
  //     ResponseType +
  //     "&NDCCode=" + window.localStorage.getItem('ndccode') +
  //     "&DisplayQuant=" + window.localStorage.getItem('displayquantity'),
  //     { headers: headers }
  //   );
  // }
  GetPharmacyList(Lat, Long, GPId, Quantity, Dosage, Type, MultiSource, DrugName, UserId, ZipCode, UserLocation, ResponseType, token): Observable<any> {
    var Encode_DrugName = decodeURIComponent(DrugName);
    let formData = {"Lat":Lat, "Long":Long, "GpId":GPId, "Quant":window.localStorage.getItem('DrugQuant'), "Dosage":Dosage, "Type":Type, "MultiSource":MultiSource, "DrugName":Encode_DrugName, "UserId":UserId, "ZipCode":ZipCode, "Userlocation":UserLocation, "ResponseType":ResponseType, "NDCCode":window.localStorage.getItem('ndccode'), "DisplayQuant":window.localStorage.getItem('displayquantity'), "SetCode":token};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL3 + "pharmacy ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // CreateAccount(EmailId, Password, groupnumber) {
  //   try {
  //     let headers = new HttpHeaders();
  //     headers.append(
  //       "Content-Type",
  //       "application/x-www-form-urlencoded; charset=utf-8"
  //     );
  //     return this.http.post(
  //       this.BaseURL1 + "signup",
  //       "email=" +
  //       EmailId +
  //       "&password=" +
  //       Password +
  //       "&groupnumber=" +
  //       groupnumber +
  //       "&platform=web",
  //       { headers: headers }
  //     );
  //   } catch { }
  // }
  CreateAccount(EmailId, Password, groupnumber): Observable<any> {
    let formData = {"email":EmailId, "password":Password, "groupnumber":groupnumber, "platform":"web"};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "signup ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // LoginAccount(EmailId, Password) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL1 + "signin",
  //     "email=" + EmailId + "&password=" + Password,
  //     { headers: headers }
  //   );
  // }
  LoginAccount(EmailId, Password): Observable<any> {
    let formData = {"email":EmailId, "password":Password};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "signin ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // ForgotPassword(EmailId) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL1 + "passwordreset", "email=" + EmailId, {
  //     headers: headers,
  //   });
  // }
  ForgotPassword(EmailId): Observable<any> {
    let formData = {"email":EmailId};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "passwordreset ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // SaveUserCards(
  //   UserId,
  //   UserCharId,
  //   PharmacyName,
  //   PharmacyType,
  //   DrugName,
  //   Price,
  //   Quantity,
  //   Dose,
  //   Form,
  //   PharmacyPhone,
  //   Address1,
  //   Address2,
  //   GpId,
  //   MultiSource,
  //   Bin,
  //   Pcn,
  //   DialNumber,
  //   Plogo
  // ) {
  //   var Encode_DrugName = encodeURIComponent(DrugName);
  //   var Encode_PharmacyName = encodeURIComponent(PharmacyName);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL1 + "card",
  //     "UserId=" +
  //     UserId +
  //     "&UserCharId=" +
  //     UserCharId +
  //     "&PharmacyName=" +
  //     Encode_PharmacyName +
  //     "&PharmacyType=" +
  //     PharmacyType +
  //     "&DrugName=" +
  //     Encode_DrugName +
  //     "&Price=" +
  //     Price +
  //     "&Quantity=" +
  //     Quantity +
  //     "&Dose=" +
  //     Dose +
  //     "&Form=" +
  //     Form +
  //     "&PharmacyPhone=" +
  //     PharmacyPhone +
  //     "&Address1=" +
  //     Address1 +
  //     "&Address2=" +
  //     Address2 +
  //     "&GPId=" +
  //     GpId +
  //     "&Bin=" +
  //     Bin +
  //     "&Pcn=" +
  //     Pcn +
  //     "&Pbmphone=" +
  //     DialNumber +
  //     "&Plogo=" +
  //     Plogo +
  //     "&MultiSource=" +
  //     MultiSource,
  //     { headers: headers }
  //   );
  // }
  SaveUserCards(UserId, UserCharId, PharmacyName, PharmacyType, DrugName, Price, Quantity, Dose, Form, PharmacyPhone, Address1, Address2, GpId, MultiSource, Bin, Pcn, DialNumber, Plogo): Observable<any> {
    var Encode_DrugName = encodeURIComponent(DrugName);
    var Encode_PharmacyName = encodeURIComponent(PharmacyName);
    let formData = {"UserId":UserId, "UserCharId":UserCharId, "PharmacyName":Encode_PharmacyName, "PharmacyType":PharmacyType, "DrugName":Encode_DrugName, "Price":Price, "Quantity":Quantity, "Dose":Dose, "Form":Form, "PharmacyPhone":PharmacyPhone, "Address1":Address1, "Address2":Address2, "GPId":GpId, "Bin":Bin, "Pcn":Pcn, "Pbmphone":DialNumber, "Plogo":Plogo, "MultiSource":MultiSource};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "card ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // GetUserCards(UserId) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL1 + "usercard", "UserId=" + UserId, {
  //     headers: headers,
  //   });
  // }
  GetUserCards(UserId): Observable<any> {
    let formData = {"UserId":UserId};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "usercard ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // RemoveSavedCard(UserId, CardId) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL2 + "RemoveCard",
  //     "UserId=" + UserId + "&cardId=" + CardId,
  //     { headers: headers }
  //   );
  // }
  RemoveSavedCard(UserId, CardId): Observable<any> {
    let formData = {"UserId":UserId, "cardId":CardId};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "RemoveCard ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }

  // SaveUserShareCard(
  //   UserId,
  //   MemeberId,
  //   DrugName,
  //   Quantity,
  //   DrugForm,
  //   DrugDose,
  //   DrugPrice,
  //   PharmacyName,
  //   Address1,
  //   Address2,
  //   PPhone,
  //   Bin,
  //   PCN,
  //   GroupNumber,
  //   UserEmail,
  //   PHelpLine,
  //   Localuser
  // ) {
  //   var Encode_DrugName = encodeURIComponent(DrugName);
  //   var Encode_PharmacyName = encodeURIComponent(PharmacyName);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL2 + "dc",
  //     "UId=" +
  //     UserId +
  //     "&MId=" +
  //     MemeberId +
  //     "&Dname=" +
  //     Encode_DrugName +
  //     "&Q=" +
  //     Quantity +
  //     "&Form=" +
  //     DrugForm +
  //     "&Dose=" +
  //     DrugDose +
  //     "&Price=" +
  //     DrugPrice +
  //     "&Pname=" +
  //     Encode_PharmacyName +
  //     "&Ad1=" +
  //     Address1 +
  //     "&Ad2=" +
  //     Address2 +
  //     "&Phone=" +
  //     PPhone +
  //     "&Bin=" +
  //     Bin +
  //     "&PCN=" +
  //     PCN +
  //     "&GN=" +
  //     GroupNumber +
  //     "&Email=" +
  //     UserEmail +
  //     "&HL=" +
  //     PHelpLine +
  //     "&User=" +
  //     Localuser,
  //     { headers: headers }
  //   );
  // }
  SaveUserShareCard(UserId,MemeberId, DrugName, Quantity, DrugForm, DrugDose, DrugPrice, PharmacyName, Address1, Address2, PPhone, Bin, PCN, GroupNumber, UserEmail, PHelpLine, Localuser ): Observable<any> {
    var Encode_DrugName = decodeURIComponent(DrugName);
    var Encode_PharmacyName = decodeURIComponent(PharmacyName);
    let formData = {"UId":UserId, "MId":MemeberId, "Dname":Encode_DrugName, "Q":Quantity, "Form":DrugForm, "Dose":DrugDose, "Price":DrugPrice, "Pname":Encode_PharmacyName, "Ad1":Address1, "Ad2":Address2, "Phone":PPhone, "Bin":Bin, "PCN":PCN, "GN":GroupNumber, "Email":UserEmail, "HL":PHelpLine, "User":Localuser};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "dc ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // RemoveDiscountCard(
  //   UserId,
  //   MemeberId,
  //   DrugName,
  //   Quantity,
  //   DrugForm,
  //   DrugDose,
  //   DrugPrice,
  //   PharmacyName,
  //   Address1,
  //   Address2,
  //   PPhone,
  //   MultiSource,
  //   PType
  // ) {
  //   var Encode_DrugName = encodeURIComponent(DrugName);
  //   var Encode_PharmacyName = encodeURIComponent(PharmacyName);
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL2 + "RemoveDiscountCard",
  //     "UId=" +
  //     UserId +
  //     "&MId=" +
  //     MemeberId +
  //     "&Dname=" +
  //     Encode_DrugName +
  //     "&Q=" +
  //     Quantity +
  //     "&Form=" +
  //     DrugForm +
  //     "&Dose=" +
  //     DrugDose +
  //     "&Price=" +
  //     DrugPrice +
  //     "&Pname=" +
  //     Encode_PharmacyName +
  //     "&Ad1=" +
  //     Address1 +
  //     "&Ad2=" +
  //     Address2 +
  //     "&Phone=" +
  //     PPhone +
  //     "&Ms=" +
  //     MultiSource +
  //     "&Type=" +
  //     PType,
  //     { headers: headers }
  //   );
  // }
  RemoveDiscountCard(UserId, MemeberId, DrugName, Quantity, DrugForm, DrugDose, DrugPrice, PharmacyName, Address1, Address2, PPhone, MultiSource, PType): Observable<any> {
    var Encode_DrugName = encodeURIComponent(DrugName);
    var Encode_PharmacyName = encodeURIComponent(PharmacyName);
    let formData = {"UId":UserId, "MId":MemeberId, "Dname":Encode_DrugName, "Q":Quantity, "Form":DrugForm, "Dose":DrugDose, "Price":DrugPrice, "Pname":Encode_PharmacyName, "Ad1":Address1, "Ad2":Address2, "Phone":PPhone, "Ms":MultiSource, "Type":PType};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "RemoveDiscountCard ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // GNValidate(groupnumber) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
  //     return this.http.post(
  //       this.BaseURL3 + "checkgnstatus",
  //       "gn=" + groupnumber,
  //       { headers: headers }
  //     );
  //   }
  //   else {
  //     return this.http.post(
  //       this.BaseURL3 + "checkgnstatus_dev",
  //       "gn=" + groupnumber,
  //       { headers: headers }
  //     );
  //   }
  // }
  GNValidate(groupnumber): Observable<any> {
    let formData = {"gn":groupnumber};
    const params = new HttpParams({ fromObject: formData });
    if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
    return this.http.post(this.BaseURL3 + "checkgnstatus ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
    else {
      return this.http.post(this.BaseURL3 + "checkgnstatus_dev ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    }
  }
  gethistorical(distId, emailId, groupNumber) {
    let headers = new HttpHeaders();
    headers.append(
      "Content-Type",
      "application/json"
    );
    let body = {
      "distId":distId,
      "emailId":emailId,
      "groupNumber":groupNumber
    }
    if (location.host == 'glicrx.com' || location.host == 'www.glicrx.com') {
      return this.http.post(
        'https://cors.23byt.es/'+this.BaseURL3 + "enrolls",
        body,
        { headers: headers }
      );
    }
    else {
      return this.http.post(
        'https://cors.23byt.es/'+this.BaseURL3 + "enrolls_dev ",
        body,
        { headers: headers }
      );
    }
  }


  // UpdateGroupNumber(UserId, GroupNumber) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL2 + "updategn",
  //     "UserId=" + UserId + "&UserGN=" + GroupNumber,
  //     { headers: headers }
  //   );
  // }
  UpdateGroupNumber(UserId, GroupNumber): Observable<any> {
    let formData = {"UserId":UserId, "UserGN":GroupNumber};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "updategn ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
    
  }
  PushCardList() {
    try {
      if (
        window.localStorage.getItem("CardListLocally") != null ||
        window.localStorage.getItem("CardListLocally") != undefined
      ) {
        let CardListData = JSON.parse(
          window.localStorage.getItem("CardListLocally")
        );
        for (let x3 of CardListData) {
          this.SaveUserCards(
            window.localStorage.getItem("UserId"),
            window.localStorage.getItem("UserCharId"),
            x3.PharmacyName,
            x3.PharmacyType,
            x3.DrugName,
            x3.Price,
            x3.Quantity,
            x3.Dose,
            x3.Form,
            x3.PharmacyPhone,
            x3.Address1,
            x3.Address2,
            x3.GPId,
            x3.MultiSource,
            x3.BIN,
            x3.PCN,
            x3.PBMPhone,
            x3.LogoLink
          )
            // .pipe(map((res) => res.json()))
            .subscribe((data) => { });
        }
        window.localStorage.setItem("CardListLocally", null);
      }
    } catch { }
  }
  // ResetPassword(userid, cpassword, npassword) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL1 + "passwordupdate",
  //     "UserId=" +
  //     userid +
  //     "&CurrentPassword=" +
  //     cpassword +
  //     "&NewPassword=" +
  //     npassword,
  //     { headers: headers }
  //   );
  // }
  ResetPassword(userid, cpassword, npassword): Observable<any> {
    let formData = {"UserId":userid, "CurrentPassword":cpassword, "NewPassword":npassword};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "passwordupdate ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // RequestOTP_Forgotpassword(EmailId) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL1 + "otprequest",
  //     "useremail=" + EmailId,
  //     { headers: headers }
  //   );
  // }
  RequestOTP_Forgotpassword(EmailId): Observable<any> {
    let formData = {"useremail":EmailId};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "otprequest ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // VerifyOTP_Forgotpassword(OTP, UserId) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL1 + "otpverify",
  //     "OTP=" + OTP + "&UserId=" + UserId,
  //     { headers: headers }
  //   );
  // }
  VerifyOTP_Forgotpassword(OTP, UserId): Observable<any> {
    let formData = {"OTP":OTP, "UserId":UserId};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "otpverify ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // VerifyOTP_Resetpassword(UserId, Password) {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(
  //     this.BaseURL1 + "forgetresetpassword",
  //     "UserId=" + UserId + "&Password=" + Password,
  //     { headers: headers }
  //   );
  // }
  VerifyOTP_Resetpassword(UserId, Password): Observable<any> {
    let formData = {"UserId":UserId, "Password":Password};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL1 + "forgetresetpassword ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  // GetWhiteParams() {
  //   let headers = new HttpHeaders();
  //   headers.append(
  //     "Content-Type",
  //     "application/x-www-form-urlencoded; charset=utf-8"
  //   );
  //   return this.http.post(this.BaseURL2 + "whiteparam", "stext=" + "test", {
  //     headers: headers,
  //   });
  // }
  GetWhiteParams(): Observable<any> {
    let formData = {"stext":"test"};
    const params = new HttpParams({ fromObject: formData });
    return this.http.post(this.BaseURL2 + "whiteparam ", params.toString(), { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } });
  }
  IsNullOrEmptyField(input: string) {
    if (input == "" || input == undefined || input == null) return true;
    else return false;
  }

  Setsignup(box, signup, signin) {
    this.showloginbox = box;
    this.signup_fields = signup;
    this.signin_fields = signin;
  }
  Getsignup() {
    let xyz: any = [];
    return (xyz = [this.showloginbox, this.signup_fields, this.signin_fields]);
  }

  sendMessage(message: string) {
    this.subject.next({ text: message });
  }

  clearMessages() {
    this.subject.next();
  }

  getMessage(): Observable<any> {
    return this.subject.asObservable();
  }
  // get your card
}
